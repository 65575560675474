import React, { useState } from "react";
import './stylejob.css';
import Search from './images/search.png';
import Locations from './images/Location.png';
import Infosys from './images/infosys.png';
 

function Job(){

    const [inputMessage, setInputMessage] = useState('');
  const [displayedMessage, setDisplayedMessage] = useState('Nil');

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleShowMessage = () => {
    setDisplayedMessage(inputMessage);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setDisplayedMessage(inputMessage);
    }
  };

    return(
       <>
        {/* <div style={{ overflowX: 'hidden' }}> */}
            <div className="headingContainer" style={{ overflowX: 'hidden' }}>
            <div className="shape1">
                <div className="shape1box"></div>
            </div>
            <div className="shape2">
                <div className="shape2box"></div>
            </div>
            <div className="shape3">
                <div className="shape3box"></div>
            </div>
            <div className="shape4">
                <div className="shape4box"></div>
            </div>
            <div className="shape5">
                <div className="shape5box"></div>
            </div>
            <div className="shape6">
                <div className="shape6box"></div>
            </div>
            <div className="shape7">
                <div className="shape7box"></div>
            </div>


            <p className="headtext">Job Listing</p>


            <div className="shape12">
                <div className="shape12box"></div>
            </div>
            <div className="shape22">
                <div className="shape22box"></div>
            </div>
            <div className="shape32">
                <div className="shape32box"></div>
            </div>
            <div className="shape42">
                <div className="shape42box"></div>
            </div>
            <div className="shape52">
                <div className="shape52box"></div>
            </div>
            <div className="shape62">
                <div className="shape62box"></div>
            </div>
            <div className="shape72">
                <div className="shape72box"></div>
            </div>
        </div>

        {/* <input className="search" placeholder="Search" type="text" value={inputMessage} onChange={handleInputChange} onKeyPress={handleEnterKey}></input>
        {displayedMessage !== null && (
        <div className="displaybox">
          <p className="displayboxcontent"><img src={Locations} alt=""/> {displayedMessage}</p>
        </div>
      )} */}

      {/* <div className="Content">
            <p>We found 21 Jobs !</p>

            <div className="Container1">
                <img src={Infosys} alt="infosys" className="infosys"></img>
                <p className="CompanyName">Infosys Technologies pvt ltd</p>
                <p className="JobName" >Junior System Engineer</p>
            </div>
      </div> */}
       </>
    );

}

export default Job;