import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './unlockstyle.css';
import brand1 from '../../Components/Fresher/images/brand.png';
import brand2 from '../../Components/Fresher/images/access.png';
import brand3 from '../../Components/Fresher/images/automation.png';
import brand4 from '../../Components/Fresher/images/hire.png';
import Click from '../../Assets/images/clickincore.png';

import Hover1 from "../../Assets/images/hover1.png";
import Hover2 from "../../Assets/images/hover2.png";
import Hover3 from "../../Assets/images/hover3.png";

const Unlockindex = ({ headers ,subheaders }) => {
    const [visibleBox, setVisibleBox] = useState(null);
    const location = useLocation();

    const handleFeatureClick = (feature) => (e) => {
        e.stopPropagation();
        setVisibleBox(feature);
    };

    const handleClickOutside = () => {
        setVisibleBox(null);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className='UnlockContainer'>
                <div className='Feature1' onClick={handleFeatureClick(1)}>
                    <img src={brand1} alt='brand' className='Feature1img' />
                    <span className='Feature1imgbg'></span>
                    <h1 className='Feature1header'>
                        {headers[0]}
                        <img src={Click} alt='Click' className='Feature1Clickimg' />
                    </h1>
                    {visibleBox === 1 && (
                        <div className='Feature1Box'>
                            <div className='F1Section1'>
                                <div className='Feature1BoxHS'>
                                    <h1 className='Feature1Boxheader'>{headers[0]}</h1>
                                    <p className='Feature1BoxSubheader'>{subheaders[0]}</p>
                                </div>
                            </div>
                            <div className='F1Section2'>
                                <div className='F1SubSection1' onClick={handleFeatureClick(4)}>
                                    <img src={Hover1} alt='Hover1' className='F1SubSection1img'/>
                                    <span className='F1SubSection1imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection2' onClick={handleFeatureClick(2)}>
                                <img src={Hover2} alt='Hover2' className='F1SubSection1img'/>
                                    <span className='F1SubSection2imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection3' onClick={handleFeatureClick(3)}>
                                <img src={Hover3} alt='Hover3' className='F1SubSection1img'/>
                                    <span className='F1SubSection3imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className='Feature2' onClick={handleFeatureClick(2)}>
                    <img src={brand2} alt='brand' className='Feature2img' />
                    <span className='Feature2imgbg'></span>
                    <h1 className='Feature2header' style={location.pathname === '/fresher' ? { fontSize: '32px' } : {}}>
                        {headers[1]}
                        <img src={Click} alt='Click' className='Feature1Clickimg' />
                    </h1>
                    {visibleBox === 2 && (<div className='Feature2Box'>
                            <div className='F2Section1'>
                                <div className='Feature1BoxHS'>
                                    <h1 className='Feature1Boxheader'>{headers[1]}</h1>
                                    <p className='Feature1BoxSubheader'>{subheaders[1]}</p>
                                </div>
                            </div>
                            <div className='F1Section2'>
                                <div className='F1SubSection1' onClick={handleFeatureClick(1)}>
                                    <img src={Hover1} alt='Hover1' className='F1SubSection1img'/>
                                    <span className='F2SubSection1imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection2' onClick={handleFeatureClick(4)}>
                                <img src={Hover2} alt='Hover2' className='F1SubSection1img'/>
                                    <span className='F2SubSection2imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection3' onClick={handleFeatureClick(3)}>
                                <img src={Hover3} alt='Hover3' className='F1SubSection1img'/>
                                    <span className='F2SubSection3imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                            </div>
                        </div>)}
                </div>

                <div className='Feature3' onClick={handleFeatureClick(3)}>
                    <img src={brand3} alt='brand' className='Feature3img' />
                    <span className='Feature3imgbg'></span>
                    <h1 className='Feature3header'>
                        {headers[2]}
                        <img src={Click} alt='Click' className='Feature1Clickimg' />
                    </h1>
                    {visibleBox === 3 && (<div className='Feature3Box'>
                            <div className='F3Section1'>
                                <div className='Feature1BoxHS'>
                                    <h1 className='Feature1Boxheader'>{headers[2]}</h1>
                                    <p className='Feature1BoxSubheader'>{subheaders[2]}</p>
                                </div>
                            </div>
                            <div className='F1Section2'>
                                <div className='F1SubSection1' onClick={handleFeatureClick(1)}>
                                    <img src={Hover1} alt='Hover1' className='F1SubSection1img'/>
                                    <span className='F3SubSection1imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection2' onClick={handleFeatureClick(2)}>
                                <img src={Hover2} alt='Hover2' className='F1SubSection1img'/>
                                    <span className='F3SubSection2imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection3' onClick={handleFeatureClick(4)}>
                                <img src={Hover3} alt='Hover3' className='F1SubSection1img'/>
                                    <span className='F3SubSection3imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                            </div>
                        </div>)}
                </div>

                <div className='Feature4' onClick={handleFeatureClick(4)}>
                    <img src={brand4} alt='brand' className='Feature4img' />
                    <span className='Feature4imgbg'></span>
                    <h1
                        className='Feature4header'
                        style={location.pathname === '/college' ? { fontSize: '30px' } : {}}
                    >
                        {headers[3]}
                        <img
                            src={Click}
                            alt='Click'
                            className='Feature4Clickimg'
                            style={location.pathname === '/college' ? { marginLeft: '-30px' } : {}}
                        />
                    </h1>
                    {visibleBox === 4 && (<div className='Feature4Box'>
                            <div className='F4Section1'>
                                <div className='Feature1BoxHS'>
                                    <h1 className='Feature1Boxheader'>{headers[3]}</h1>
                                    <p className='Feature1BoxSubheader'>{subheaders[3]}</p>
                                </div>
                            </div>
                            <div className='F1Section2'>
                                <div className='F1SubSection1' onClick={handleFeatureClick(1)}>
                                    <img src={Hover1} alt='Hover1' className='F1SubSection1img'/>
                                    <span className='F4SubSection1imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection2' onClick={handleFeatureClick(2)}>
                                <img src={Hover2} alt='Hover2' className='F1SubSection1img'/>
                                    <span className='F4SubSection2imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                                <div className='F1SubSection3' onClick={handleFeatureClick(3)}>
                                <img src={Hover3} alt='Hover3' className='F1SubSection1img'/>
                                    <span className='F4SubSection3imgbg'></span>
                                    <img src={Click} alt='Click' className='F1SubSection1Click'/>
                                </div>
                            </div>
                        </div>)}
                </div>
            </div>
        </>
    );
};

export default Unlockindex;
