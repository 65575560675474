import React,{useState} from 'react';
import './styleh.css';
import '../../fonts.css';
import { useRef, useEffect  } from "react";
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {MDBNavbarToggler,MDBIcon} from 'mdb-react-ui-kit'; 
import image from '../Header/images/image1.png';
// import Button from 'react-bootstrap/Button';
import sign from '../Fresher/images/signinbutton.png';
// import Home from './pages/home';
import Pickarrow from '../Header/images/pickarrow.png';
import sign2 from '../Header/images/sign2.png';
import { Bootstrap } from 'bootstrap/dist/css/bootstrap.css';
// import Glyphicon from '../Glyphicon';
// import Icon from 'cdbreact/dist/components/Icon';
import { useNavigate } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {

  const handleScroll = () => {
    if (navRef.current.classList.contains("responsive_nav")) {
      navRef.current.classList.remove("responsive_nav");
    }
  };

  // Added useEffect to set up and clean up the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { REACT_APP_AUTH_URL } = process.env;

  const location = useLocation();

  const isActiveLink = (pathname) => {
    // Check if the current pathname matches the provided pathname
    return location.pathname === pathname;
  };
  
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle(
      "responsive_nav"
    );
  };

  const navstyle = {
    color: '#ff0000', 
    display: 'flex',
    justifyContent:'center',
  };

  const navigate = useNavigate();

  const Toggle={
    // color:'rgb(0,0,255)',
    
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


    const PickYourProfile = "Pick Your Profile";


    const titleStyle = {
       // Apply other styles as needed
      // fontFamily: "'Plus Jakarta Sans', sans-serif", // Use the Plus Jakarta Sans font
    };

    const openLink = () => {
      window.open(`${REACT_APP_AUTH_URL}`, "_blank", "noreferrer");
    };
    

  return (
//     <center>
//       <Navbar collapseOnSelect expand="lg" className='mynavbar mt-0'>
//       <Navbar.Brand className='logo p-5 '><img src={image} className="logotop" alt="pllogo" /></Navbar.Brand>
//       <Navbar.Toggle aria-controls="responsive-navbar-nav &#x2630;" className="custom-toggler-icon &#x2630;" style={Toggle}/>
      
//       <Navbar.Collapse id="responsive-navbar-nav">
//         <Nav className="mx-auto navhead mt-5" style={navstyle}>
//           <Nav.Link href="/" ><p className="nhome">Home</p></Nav.Link>
//           <Nav.Link href="/AboutUs"><p className="nabout" >About</p></Nav.Link>
//           <NavDropdown title={PickYourProfile} className="nprofile" style={titleStyle}>
//           <NavDropdown.Item href="#dropdown-1" className='dropt' onClick={()=>navigate("/fresher")} style={{color:"black"}}>Fresher</NavDropdown.Item>
//             <NavDropdown.Item href="#dropdown-2" className='dropt' onClick={()=>navigate("/experienced")}>Experinced</NavDropdown.Item>
//             <NavDropdown.Item href="#dropdown-3" className='dropt' onClick={()=>navigate("/college")}>College</NavDropdown.Item>
//             <NavDropdown.Item href="#dropdown-4" className='dropt' onClick={()=>navigate("/partner")}>Training Partner</NavDropdown.Item>
//             <NavDropdown.Item href="#dropdown-5" className='dropt' onClick={()=>navigate("/corporate")}>Corporate</NavDropdown.Item>
//           </NavDropdown>
//           <Nav.Link href="/joblisting"><p className="ncontact" onClick={()=>navigate("/joblisting")}>Job listing</p></Nav.Link>
//         </Nav>
//         <Nav>

//         <Nav.Link href="#signin">

//           <h4 className="nsignin1" >SIGN IN<img  className='ms-3' src={sign} alt='sign'/></h4>

//           <h4 className="nsignin2tab" ><p className='signintexttab'>Sign In</p><img  className='arrowsignintab' src={sign2} alt='sign'/></h4>

//         <h4 className="nsignin2 d-sm-none" >
//           <p className='nsignin2t'>SIGN IN<img className='nsignin2img' src={sign2} alt='sign2'/>
//           </p>
//           </h4></Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//     <hr className='lined'></hr>
//  </center>
<>
<div className='overlay-menu'>
<header>
<img src={image} alt='logo' className='navbarimage'/>
<nav ref={navRef}>

  <a href="/" className={isActiveLink('/') ? 'active navbarhome' : 'navbarhome'}>Home</a>
  <a href="/AboutUs" className={isActiveLink('/AboutUs') ? 'active' : ''}>About us</a>

  <li className='navdropdown'>
    
    <div className="navbutton" onClick={toggleDropdown}>

  <a className={isActiveLink('/fresher') || isActiveLink('/experienced') || isActiveLink('/college') || isActiveLink('/partner') || isActiveLink('/corporate') ? 'active navbarpick' : 'navbarpick'}>Pick Your Profile<img src={Pickarrow} alt='arrow' className='pickarrow'/></a>
  {showDropdown && (
    <div className="dropdown-content">
            <a href="/fresher" className={isActiveLink('/fresher') ? 'navdropcontents active' : 'navdropcontents'}>Fresher</a>
            <a href="/experienced" className={isActiveLink('/experienced') ? 'navdropcontents active' : 'navdropcontents'}>Experienced</a>
            <a href="/college" className={isActiveLink('/college') ? 'navdropcontents active' : 'navdropcontents'}>College</a>
            <a href="/partner" className={isActiveLink('/partner') ? 'navdropcontents active' : 'navdropcontents'}>Training Partner</a>
            <a href="/corporate" className={isActiveLink('/corporate') ? 'navdropcontents active' : 'navdropcontents'}>Corporate</a>
  </div>
  )}

  </div>
      </li>


  <a href="/joblisting" className={isActiveLink('/joblisting') ? 'active' : ''}>Job Listing</a>

  <a onClick={openLink} className='navbarsignin'><p className='navbarsignintext'>SIGN IN<img src={sign} alt='lapscreen' className='lapscreensigninimg'/>
  
  <img src={sign2} alt='smallscreen' className='smallscreensigninimg'/></p></a>
</nav>
<button
  className="nav-btn"
  onClick={showNavbar}>
  <FaBars />
</button>
</header>
<center>
<hr className='headerline'></hr>
</center>
</div>
</>
  );
};

export default Navigation;