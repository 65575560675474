import React from 'react'

const CalendarYellow = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="10" fill="#FDEDE2" />
      <mask
        id="mask0_2791_62004"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_2791_62004)">
        <path
          d="M22.5 26C21.8 26 21.2083 25.7583 20.725 25.275C20.2417 24.7917 20 24.2 20 23.5C20 22.8 20.2417 22.2083 20.725 21.725C21.2083 21.2417 21.8 21 22.5 21C23.2 21 23.7917 21.2417 24.275 21.725C24.7583 22.2083 25 22.8 25 23.5C25 24.2 24.7583 24.7917 24.275 25.275C23.7917 25.7583 23.2 26 22.5 26ZM13 30C12.45 30 11.979 29.8043 11.587 29.413C11.1957 29.021 11 28.55 11 28V14C11 13.45 11.1957 12.9793 11.587 12.588C11.979 12.196 12.45 12 13 12H14V10H16V12H24V10H26V12H27C27.55 12 28.021 12.196 28.413 12.588C28.8043 12.9793 29 13.45 29 14V28C29 28.55 28.8043 29.021 28.413 29.413C28.021 29.8043 27.55 30 27 30H13ZM13 28H27V18H13V28ZM13 16H27V14H13V16Z"
          fill="#F3A66E"
        />
      </g>
    </svg>
  )
}

export default CalendarYellow
