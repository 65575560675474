import styled from "styled-components";


import { COLORS } from "../../../../theme/index";
import { Typography, Dropdown } from "antd";

const { Text } = Typography;

export const StyledDropdown = styled(Dropdown)`
 width: 180px;
 height: 3em;
 border-radius: 0.625em;
 margin-left: 1em;
 padding: 0.625em 0.875em 1.25em;
 gap: 0.625em;
 background-color: #FFFFFF;
 border: 1px solid #E6E7EA;
 @media only screen and (max-device-width: 767px) {
    width: 130px;
 }
`;

export const StyledText = styled(Text)`
 width: auto;
 height: 1.5em;
 font-family: 'Plus Jakarta Sans', sans-serif;
 font-weight: 500;
 font-size: 15px;
 color: ${COLORS.dark_gray_color};
 font-style: normal;
 line-height: 1.5em;
`;
