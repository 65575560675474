import React, { useState, useEffect,useCallback } from 'react'
import {
  SectionWrapper,
  OuterDiv,
  TopNav,
  SectionName,
  Address,
  NameAdd,
  Container,
  Name,
  BreadCrumns,
  RowContainer,
  ShareTitile,
  DropRow,
  TopHeader,
  RowListIcon,
  RowListLabel,
  ColListIcon,
  CenteredDiv,
  TopHeading,
  SearchInput,
  SearchLInput
} from './style';

import './stylerole.css';
// import {TopHeading,SearchInput,
//   SearchLInput} from '../Jobs/style';

// import {
  
// } from './style'
import JobDescription from './JobDescription';
import AboutCompany from './aboutcompany';

import { Col, Divider, Row } from 'antd'
import Avatar from '../JobContainer/Avatar'


import Flex from '../JobContainer/Flex'
import StyledTabs from '../JobContainer/TabsPaneStyles/index'
import { Tabs } from 'antd'
import Button from '../JobContainer/Button'

import Share from '../icons/Share'
import CopyAltIcon from '../icons/CopyAltIcon';
import JobListing from '../Joblisting/joblisting';

import SearchIcon from '../icons/SearchIcon'
import CorpIcon from '../icons/CorpIcon'
import CrossIcon from '../icons/CrossIcon'
import { useParams } from 'react-router-dom'
import { Dropdown,Menu } from 'antd'
import axios from "axios";
import AntdInput from '../JobContainer/Form/Input'
import Footer from "../Footer/Footer";
import ReactPlayer from 'react-player'
import { useNavigate } from "react-router-dom";
import { message } from 'antd'
import { X } from 'react-bootstrap-icons';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from "styled-components";

const StyledText = styled.span`
  text-transform: capitalize;
`;
const ErrorMessage = (description) =>
  message.error({
    content: <StyledText>{description}</StyledText>,
    className: "custom-class",
    style: {
      marginTop: "50px",
      color: "#010E30",
      borderLeft: "5px solid red",
      transitionDuration: "5s",
    },
  });

const SuccessMessage = description =>
  message.success({
    content: description,
    className: 'custom-class',
    style: {
      marginTop: '50px',
      color: '#010E30',
      borderLeft: '5px solid red',
      transitionDuration: '5s',
    },
  })

  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }


const { TabPane } = Tabs

const ViewRole = () => {
 
  
  const { REACT_APP_CORPORATE_API_URL } = process.env
  const { REACT_APP_STUDENT_APPLICATION_URL } = process.env
  const { REACT_APP_PIL_WEBSITE_URL } = process.env
  const { REACT_APP_SEARCH_AUTH_KEY } = process.env;
  const navigate = useNavigate();
  let reqInstance = axios.create({
    headers: {
      "auth-key": REACT_APP_SEARCH_AUTH_KEY,
    },
  });
  const params = useParams()
  const [viewRoleDetails, setViewRileDetails] = useState(null)
  const [viewRoleDetailss, setViewRileDetailss] = useState(null)
  const [error, setError] = useState('');

  useEffect(() => {
    reqInstance
      .get(`${REACT_APP_CORPORATE_API_URL}/corporates/${params.CorpID}/jobs/${params.JobID}`)
      .then((response) => {
        setViewRileDetails(response.data.data);
      })
      .catch((error) => {
        ErrorMessage(error?.response?.data?.error?.message || 'No Active Jobs Found');
        setError('No Active Jobs Found');
        console.log(error);
      });
  }, [params.CorpID, params.JobID]);

  const [locationFilter, setLocationFilter] = useState([])
  const [page, setPage] = useState(0)
  const [search, setsearch] = useState('')
  const [shareOption , setShareOption] = useState(false)
  const shareUrl =`${REACT_APP_PIL_WEBSITE_URL}/Job/${params.CorpID}/${params.JobID}`
 
  const showDrawer = param => {
    localStorage.setItem('profile', "");
    window.scrollTo(0, 0);
    navigate(`/Student_Registration`)
  }
  
  const searchValue = value => {
    setPage(0)
    setsearch(value)
   
  }

  const delaySaveToDb = useCallback(
    debounce(val => {
      searchValue(val)
    }, 1000),
    []
  )

  const ShareWork = () =>{
   setShareOption(true)
  }
  const ShareCancel = () =>{
    setShareOption(false)
   }
   const handleChange = e => {
    delaySaveToDb(e.target.value)
  }
   const copyLink = () => {
    let copyText = document.getElementById('socilamedialink')
    copyText.select()
    navigator.clipboard.writeText(copyText.value)
    if (copyText.value.length != 0) {
      SuccessMessage('Link Coppied!')
    }
    setShareOption(false)
  }
  const onLocationChange = location => {
    setLocationFilter(location)
   
  }
  
  const menu  = ( 
    <DropRow>
      <TopHeader row spaceBetween alignCenter>
        <ShareTitile flexStart>
        Share this role
          </ShareTitile>


   
          <Avatar onClick={ShareCancel} size={20} src={<CrossIcon/>} />
         
          </TopHeader>
          <Divider/>
         
        <AntdInput 
             id={'socilamedialink'}
        value={shareUrl}
        disabled={true}
        suffix={
          <Avatar onClick={copyLink} src={<CopyAltIcon/>} />
        }
        
        />
    </DropRow>
  )
  const CheckIcon = logo => { 
    if (logo === null) {
      return  ( 
        
        <Avatar
          background
          size={64}
          IconName={viewRoleDetails?.corporate?.name[0]}
          font={18}
        />
      )
    } else {
      return (
        <Avatar size={64} src={logo}  />
      )
    }
  }

  const ExtraAction = () => {


    return (
      <>
        
        <Container alignEnd>
      
            <Button.Primary
              onClick={showDrawer}
              text={'Register to Apply'}
            />
         
          &nbsp;&nbsp; &nbsp;&nbsp;
          <Dropdown onClick={ShareWork} overlay={menu} visible={shareOption} >
          <Button.Round icon={<Share />} />

          </Dropdown>
          &nbsp;&nbsp;
        </Container>
      </>
    )
  }

  return (
    <div className='viewrolepagemain'>

<JobListing/>
{/* <TopHeading flexStart>
                <SearchInput
                  onChange={handleChange}
                  placeholder="Search Job"
                  prefix={<SearchIcon />}
                />

                <SearchLInput
                  // width={'300px'}
                  onChange={onLocationChange}
                  placeholder="Location"
                  // prefix={<Locations/>}
                />
    </TopHeading> */}


      <SectionWrapper column>
        <OuterDiv>
          {viewRoleDetails &&
          <TopNav>
            <Flex centerVertically>
            <Link to="/joblisting" style={{color:'black',fontSize:'23px',marginLeft:'-30px'}}>
                <ArrowLeftOutlined />
              </Link> &nbsp;&nbsp;&nbsp;
              {CheckIcon(viewRoleDetails?.corporate?.logo)}
              <NameAdd column flexStart>
                <Name>{viewRoleDetails?.corporate?.name}</Name>
                <Address>{viewRoleDetails?.corporate?.industry}</Address>
              </NameAdd>
            </Flex>
          </TopNav>
}
          <RowContainer>
            <StyledTabs
              width={'100%'}
              tabBarExtraContent={ExtraAction()}
              defaultActiveKey="1"
            >
              <TabPane tab="Job Description" key="1">
                {viewRoleDetails &&
                <JobDescription viewRoleData={viewRoleDetails} />
}
              </TabPane>

              <TabPane tab="About Company" key="2">
                {viewRoleDetails &&
                <AboutCompany viewRoleData={viewRoleDetails} />
}
              </TabPane>

            </StyledTabs>
          </RowContainer>
        </OuterDiv>
      </SectionWrapper>

      <br></br>
    </div>
  )
}
export default ViewRole
