import React from "react";
import "./testimonialstyle.css";
// import AOS from 'aos';

export function Testimonialsbox1fun(props){
    return(
        <>
         <div className="testimonial-container1" >
         <img src={props.image} alt={props.name}/>
            <div className="testimonial-box1">
                <p className="testcontent">{props.text}</p>
                <p className="testname">{props.name}, <span className="testdesignation">{props.designation}</span></p>
            </div>
         </div>
        </>
    );
}

export function Testimonialsbox2fun(props,inline){
    return(
        <>
         <div className={`testimonial-container2 ${inline ? 'testimonials-inline2' : ''}`}>
            <div className="testimonial-box2">
                <p className="testcontent">{props.text}</p>
                <p className="testname">{props.name}, <span className="testdesignation">{props.designation}</span></p>
            </div>
            <img src={props.image} alt={props.name}/>
         </div>
        </>
    );
}

export function Testimonialsbox3fun(props){
    return(
        <>
         <div className="testimonial-container3" >
            <div className="testimonial-box3">
                <p className="testcontent">{props.text}</p>
                <p className="testname">{props.name}, <span className="testdesignation">{props.designation}</span></p>
            </div>
            <img src={props.image} alt={props.name}/>
         </div>
        </>
    );
}

export function Testimonialsbox4fun(props){
    return(
        <>
         <div className="testimonial-container4" >
         <img src={props.image} alt={props.name}/>
            <div className="testimonial-box4">
                <p className="testcontent">{props.text}</p>
                <p className="testname">{props.name}, <span className="testdesignation">{props.designation}</span></p>
            </div>
         </div>
        </>
    );
}


export function Testimonialsbox5fun(props,inline){
    return(
        <>
         <div className={`testimonial-container5 ${inline ? 'testimonials-inline5' : ''}`}>
            <div className="testimonial-box5">
                <p className="testcontent">{props.text}</p>
                <p className="testname">{props.name}, <span className="testdesignation">{props.designation}</span></p>
            </div>
            <img src={props.image} alt={props.name}/>
         </div>
        </>
    );
}

// export default Testimonialsbox1fun;
