import React from 'react';
import { Form, Input, Alert } from 'antd';
import './styleemail.css';
import { useSearchParams } from "react-router-dom";

const Email = ({ value, onChange }) => {

let [searchParams, setSearchParams] = useSearchParams();
const email = searchParams.get("email");

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  const IsValidEmail = (email) => {
    const emailRegex = /^\s*[\w.%+-]+@[a-z0-9.-]+\.[a-z]{2,}\s*$/i;

    if (!email || email.length <= 2 || email.indexOf("@") === -1) {
      return false;
    }

    return emailRegex.test(email);
  };

  return (
    <Form.Item
      name={['email']}
      rules={[
        {
          validator(_, value) {
            let isValid = IsValidEmail(value);
            if (!isValid) {
              return Promise.reject(
                new Error("Please Enter a Valid Email")
              );
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        value={value}
        placeholder="Enter Email ID"
        onChange={handleEmailChange}
        className="emailsize"
        disabled={email}
      />

        {email && (
          <div style={{ paddingTop: "10px" }}>
            <Alert
              message="Email Verified SuccessFully"
              type="success"
              showIcon
            />{" "}
          </div>
        )}
      
    </Form.Item>
  );
};

export default Email;