import React ,{useEffect,useState,useRef}from "react";
import { Col, Divider, Row } from "antd";
import { toWords } from 'number-to-words'
import CandidatesIcon from "../icons/CandidatesIcon";
import {
  TitleMedium,
  SubtitleLarge,
  GrayTextSmall,
  InfoText,
  FlexWrap,
  GrayText,
  SubtitleSmall,
  YellowBox,
  SubtitleMedium,
  VerticalDivider,
  VerticalDividerMax,
  FlexContain,
  LeftCol,
  Editor,
  EditorContent,
  PopHeading,
  PopSubHeading,
  Name1,
  Address1,
  SubBold,
  Container1,
  Content,
  ShowMoreText,
  SkillList,
  ExtraPadding
} from "./style";
import Flex from "../JobContainer/Flex";
import JobListing from "../Joblisting/joblisting";

import { Avatar } from "antd";
import { FONT } from "../theme/font";
import Location from "../icons/LocationIcon";
import AlarmOn from "../icons/Alarm_On";

import PaymentIcon from "../icons/PaymentIcon";
import { COLORS } from "../theme";
import CalendarYellow from "../icons/CalendarYellow";

import CustomTag from "../JobContainer/CustomTag";
import Moment from "moment";
import Construction from "../icons/construction.png";

const JobDescription = ({ viewRoleData }) => {
  let {mandatorySkillNames}=viewRoleData

  const checkDate = (date) => {
    return Moment(date).format("MMM DD");
  };
  const formatNumber = (number = 0) => {
    return parseInt(number).toLocaleString("en-US");
  };

  const numericToWords = (number) => {
    // Convert the numeric value to words
    const words = toWords(number)

    // Capitalize the first letter for consistency
    return words.charAt(0).toUpperCase() + words.slice(1)
  }


  const checkJobTypeLevel = (data, YOE) => {
    const transformedData = data?.map((item) => {
      switch (item) {
        case "ENTRY":
          return " Entry Level";
        case "INTERN":
          return " Internship";
        case "EXPERIENCED":
          return YOE?.length > 0 ?` ${YOE[0]} - ${YOE[1]} Yrs Experience ` :` 0 - 1 Yrs Experience `
        default:
          return item;
      }
    });

    return transformedData?.toString();
  };

  const groupedEducations = viewRoleData?.degreeStreamMap.reduce(
    (acc, education) => {
      const degreeShortForm = education?.degree?.shortForm;
      const streamName = education?.stream?.name;
 
      if (!acc.hasOwnProperty(degreeShortForm)) {
        acc[degreeShortForm] = [];
      }
 
      acc[degreeShortForm].push(streamName);
 
      return acc;
   },
    {}
  );
 
  const renderContent = () => {
    let content = [];
 
    if (groupedEducations) {
     Object.entries(groupedEducations).forEach(([degree, streamNames]) => {
        content.push(
          <ExtraPadding key={degree}>
            <SubBold>{degree}</SubBold>
            <br />
            {streamNames.join(", ")}
            <br />
          </ExtraPadding>
        );
      });
    }

    return content;
  };
 
const [expanded, setExpanded] = useState(false);
 
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
 
   const contentHeight = useRef(null);
 
   const [contentHeightValue, setContentHeightValue] = useState(contentHeight.current?.clientHeight || 0);

   useEffect(() => {
    if (contentHeight?.current?.clientHeight && contentHeight.current.clientHeight !== contentHeightValue) {
      setContentHeightValue(contentHeight.current.clientHeight);
    }
  }, [contentHeightValue, contentHeight]);
  

   useEffect(() => {

       if (contentHeightValue > 250) {
          setExpanded(false);
       }
   }, []);

   //CamelCase Functions
 function toCamelCase(str) {
  const words = str?.split(' ');
  const camelCaseWords = words?.map(word => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  });
  return camelCaseWords?.join(' ');
}


  return (
    <>
      <Row>
        <LeftCol span={24} md={16}>
          <TitleMedium>{viewRoleData?.tittle}</TitleMedium>

          <FlexContain centerVertically>
            <Avatar src={<AlarmOn />} />
            &nbsp;
            <Address1>
              {checkJobTypeLevel(
                viewRoleData?.jobTypeLevels,
                viewRoleData?.yearOfExperience
              )}{" "}
              (
              {viewRoleData?.jobType === "FULL_TIME"
                ? "Full Time"
                : "Part Time"}
              )
            </Address1>
            <VerticalDivider type="vertical" />
          <Avatar src={<CandidatesIcon />} />
          &nbsp;
          <Address1>{`${viewRoleData?.minVacancies} - ${viewRoleData?.maxVacancies}`}</Address1>
          <VerticalDivider type="vertical" />
            <Avatar src={<PaymentIcon />} />
            &nbsp;
            <Address1> 
                 {viewRoleData?.minCompensation === viewRoleData?.maxCompensation ? (
                  <span>₹ {formatNumber(Number(viewRoleData?.minCompensation))}</span>
                ) : (
                  <span>
                    ₹ {formatNumber(Number(viewRoleData?.minCompensation))} &nbsp; - &nbsp; 
                    {formatNumber(Number(viewRoleData?.maxCompensation))}
                  </span>
                )} {" "}
                {viewRoleData?.CTCPeriod == "ANNUALLY"
                  ? "Per annum"
                  : viewRoleData?.CTCPeriod == "MONTHLY"
                  ? "Per month"
                  : viewRoleData?.CTCPeriod == "WEEKLY"
                  ? "Per week"
                  : viewRoleData?.CTCPeriod == "DAILY"
                  ? "Per day"
                  : viewRoleData?.CTCPeriod == "HOURLY"
                  ? "Per hour"
                  : ""}
            </Address1>
          </FlexContain>
          <FlexWrap gap="15px" centerVertically>
            <FlexWrap gap="5px" centerVertically>
              <div>
                <GrayTextSmall>Employment Type</GrayTextSmall>
                <FlexWrap wrap gap="5px">
                  <CustomTag
                    message={
                      viewRoleData?.employmentType == "ONROLL"
                        ? "OnRoll"
                        : "Contract"
                    }
                  />
                </FlexWrap>
              </div>
              {viewRoleData?.jobDependency && (
                <>
                  <VerticalDividerMax type="vertical" />

                  <div>
                    <GrayTextSmall>Hiring Timeline</GrayTextSmall>
                    <FlexWrap wrap gap="5px">
                      <CustomTag message={viewRoleData?.jobDependency} />
                    </FlexWrap>
                  </div>
                </>
              )}
            </FlexWrap>
          </FlexWrap>

          <Editor>
            <EditorContent
              dangerouslySetInnerHTML={{
                __html: viewRoleData?.description || "",
              }}
            />
          </Editor>
          <SubtitleLarge color={COLORS.GREY_T_30}>
            Interview Rounds
          </SubtitleLarge>
          {viewRoleData?.interviewWorkFlow?.rounds?.map((item, index) => (
            <div key={index}>
              <GrayTextSmall
                capitalize={true}
                marginbottom="10px"
                color={COLORS.GREY_T_30}
              >
                {`Round ${index + 1} - ${item?.name}`}
              </GrayTextSmall>
              <EditorContent
                dangerouslySetInnerHTML={{
                  __html: item?.description || "",
                }}
              />
            </div>
          ))}
        </LeftCol>
        <Col span={24} md={8}>
          <YellowBox>
            <CalendarYellow />
            <FlexWrap column>
              <GrayText fontweight={FONT.FW_400}>Last date to apply</GrayText>
              <SubtitleSmall fontweight={FONT.FW_600}>
                {checkDate(viewRoleData?.endDate)}
              </SubtitleSmall>
            </FlexWrap>
          </YellowBox>

          <Divider />
          <div>
            <FlexWrap gap="10px" centerVertically>
              <Location />
              <SubtitleMedium>Job Location</SubtitleMedium>
            </FlexWrap>

            <ul>
            {viewRoleData?.isAnyWhere?
                  <InfoText>
                    <li>Any where</li>
                  </InfoText>:
                  viewRoleData?.cities !== undefined &&
                viewRoleData?.cities?.map((item, index) => (
                  <InfoText key={item.id}>
                    <li>{item.name}</li>
                  </InfoText>
                ))}
            </ul>
          </div>
          {viewRoleData?.isRemoteAllowed ? (
            <>
              <SubtitleSmall fontsize={FONT.XS}>
                We are also remote friendly
              </SubtitleSmall>
              <GrayText>Remote Location :</GrayText>
              {viewRoleData?.remotCities !== undefined &&
                viewRoleData?.remotCities.map((item, index) => (
                  <SubtitleSmall> {item.name}</SubtitleSmall>
                ))}
            </>
          ) : null}

          <FlexWrap gap="10px"></FlexWrap>
          <br></br>
          <Flex>
            <Avatar size={20} src={Construction} />
            &nbsp;&nbsp;
            <Name1>Eligibility Criteria</Name1>
          </Flex>
          <div>
          <PopHeading>Mandatory Skills</PopHeading>
          <SkillList>
          <SubtitleSmall fontsize={FONT.XS} padding="0px 8px" fontweight={FONT.FW_600}>
          <li>Primary Mandatory Skills</li>
          </SubtitleSmall>
          <FlexWrap wrap gap="5px">
          {mandatorySkillNames?.primarySkillNames?.skillNames?.map((tag, index) => (
              <><CustomTag message={tag.name} marginright={"3px"} background="#F0F2F4"/>  
              { mandatorySkillNames?.primarySkillNames?.skillNames?.length!=index+1 && 
              <div><InfoText  >
              {mandatorySkillNames.primarySkillNames?.type=="AND"?"&":"OR"}
              </InfoText></div>} 
              </>
            ))}
            </FlexWrap>
          {mandatorySkillNames?.mandatorySkillNames?.length ?
          <SubtitleSmall fontsize={FONT.XS}   padding="3px" fontweight={FONT.FW_600}>
          <li>Secondary Mandatory Skills</li> 
          </SubtitleSmall>:null}
          
          <FlexWrap wrap gap="5px">
          {mandatorySkillNames?.mandatorySkillNames?.map((tag, index) => (
              <><CustomTag message={tag.name} marginright={"3px"} background="#F0F2F4"/>  
              { mandatorySkillNames?.mandatorySkillNames?.length!=index+1 && 
              <div><InfoText>
              ,
              </InfoText></div>} 
              </>
            ))}
            </FlexWrap>
          {mandatorySkillNames?.otherSkillsNames?.length ?
          <SubtitleSmall fontsize={FONT.XS}   padding="3px" fontweight={FONT.FW_600}>
           <li>Other Mandatory Skills </li>
          </SubtitleSmall>:null}
          <SkillList>
          {mandatorySkillNames?.otherSkillsNames?.map((skillSet, index) => (
          <> 
          <SubtitleSmall fontsize={FONT.XS}    padding="3px" fontweight={FONT.FW_500}>
          <li>Skill Set {index+1}</li>
          </SubtitleSmall>
          <FlexWrap wrap gap="5px">
          {skillSet?.skillNames?.map((tag, index2) => (
              <><CustomTag message={tag.name} marginright={"3px"} background="#F0F2F4" />  
              { skillSet?.skillNames?.length!=index2+1 && 
              <div><InfoText   >
              {skillSet?.type=="AND"?"&":"OR"}
              </InfoText></div>} 
              </>
            ))}
          </FlexWrap>
                    </>
            ))}
            </SkillList>
            </SkillList>
        </div>
          {viewRoleData?.optionalSkillsName?.length > 0 ? (
            <>
              <PopHeading>Good to Have Skills</PopHeading>
              <br></br>
              <Flex wrap>
                {viewRoleData?.optionalSkillsName?.map((tag, index) => (
                  <CustomTag message={tag.name} marginright={"3px"} background="#F0F2F4" />
                ))}
              </Flex>
              <Divider />
            </>
          ):null}
      <div>
        <PopHeading>Gender</PopHeading>
        <Content>{toCamelCase(viewRoleData?.publishTo)}</Content>
      </div>
          <PopHeading>Degree & Department</PopHeading>
          <Container1
          expanded={expanded}
          style={{ height: contentHeightValue < 250 ? "auto" : contentHeightValue < 250 && "500px" }}
          >
            <Content ref={contentHeight}>
              {renderContent()}
            </Content>

            </Container1>  
            {contentHeightValue > 250 && (
            <ShowMoreText visible={!expanded} onClick={toggleExpanded}>
              Show More...
            </ShowMoreText>
            )}

            {contentHeightValue > 250 && expanded && (
            <ShowMoreText visible={expanded} onClick={toggleExpanded}>
              Show Less
            </ShowMoreText>
            )}
          
          <PopHeading>Year of passing</PopHeading>

          {viewRoleData?.yearOfPassing !== undefined &&
              <PopSubHeading>
                  {viewRoleData?.yearOfPassing?.join(', ')}
              </PopSubHeading>
          }
          <PopHeading>Arrears / Backlogs</PopHeading>
          {!viewRoleData?.isArrearsAllowed ? (
            <PopSubHeading>No Arrears / Backlogs allowed.</PopSubHeading>
          ) : (
            <PopSubHeading>
              Upto {viewRoleData.arrearCount} Arrears / Backlogs allowed.
            </PopSubHeading>
          )}

          <PopHeading>Scores</PopHeading>
          <PopSubHeading>
            Average mark should be above 60% in all your exams
          </PopSubHeading>
          <PopSubHeading>
            10th - Above {viewRoleData?.tenthScore}%
          </PopSubHeading>
          <PopSubHeading>
            12th or Equivalent - Above {viewRoleData?.twelfthScore}%
          </PopSubHeading>
          <PopSubHeading>UG - Above {viewRoleData?.UGScore}%</PopSubHeading>
          <PopSubHeading>PG - Above {viewRoleData?.PGScore}%</PopSubHeading>
        </Col>
      </Row>
    </>
  );
};

export default JobDescription;
