import React, { useState, useContext, useEffect,useCallback  ,useRef} from 'react'
import Flex from '../JobContainer/Flex';
import { Menu } from 'antd'
import PaddedDropdown from '../JobContainer/UIElements/dropdowns/PaddedDropdown/PaddedDropdown';
import SingleListed from './singleListed'
import { Avatar, Select } from 'antd'
import FilterIcon from '../icons/FilterIcon';
import { Collapse } from 'antd';
import './style.css';
import {
  SectionWrapper,
  TopHeader,
  TopHeading,
  TopRightFlex,
  SmallButtons,
  CardRow,
  ShowNum,
  AnchorText,
  FilterCollapse,
  StyleLeft,
  LocationDiv,
  spacingTop,
  SearchInput,
  SearchSInput,
  SearchLInput,
  SearchsInput,
  StyledCheckbox,
  StyledCheckboxGroup,
  FilterChipPanel,
  StyledTag,
  SpinWrapper,
  PageWrapper,
  ShowNum1
} from './style'
// import LocationIcon from '../icons/LocationIcon';
import Slider from '../JobContainer/Form/Slider';
import { Col, Row, Divider, Tag } from 'antd'
import SearchIcon from '../icons/SearchIcon'
import { Spin } from 'antd'
import axios from "axios";
import Footer from "../Footer/Footer";
import JobListing from "../Joblisting/joblisting";
// import Locations from "../Joblisting/images/Location.png";

const { REACT_APP_SEARCH_AUTH_KEY } = process.env;


const { Panel } = Collapse

const Jobs = () => {

  // <JobListing/>

  let reqInstance = axios.create({
    headers: {
      "auth-key": REACT_APP_SEARCH_AUTH_KEY,
    },
  });
  const [page, setPage] = useState(0)
  const [sortValue, setsortValue] = useState('')
  const [search, setsearch] = useState('')
  const [jobTypeLevel, setjobTypeLevel] = useState([])
  const [jobType, setjobType] = useState([])
  // const [jobTypeindustry, setjobTypeIndustry] = useState([])
  const [locationFilter, setLocationFilter] = useState([])
  const [minCTC, setminCTC] = useState(0)
  const [maxCTC, setmaxCTC] = useState(100)
  const [entry, setEntry] = useState(false)
  const [intern, setIntern] = useState(false)
  const [experience, setExperience] = useState(false)
  const [fullTime, setFullTime] = useState(false)
  const [uniqueCities, setUniqueCities] = useState([]);
  const [uniqueIndustries, setUniqueIndustries] = useState([]);
  const [jobTypeIndustry, setJobTypeIndustry] = useState([]);

  const [technology, setTechnology] = useState(false)
  const [media, setMedia] = useState(false)
  const [finance, setFinance] = useState(false)
  const [health, setHealth] = useState(false)
  const [education, setEducation] = useState(false)
  const [government, setGovernment] = useState(false)
  
  const [partTime, setPartTime] = useState(false)
  const [initialFilter, setinitialFilter] = useState([])
  const [loading, setLoading] = useState(false)
  const [locationOption] = useState([
    'Remote Work',
    'Chennai',
    'Bangalore',
    'Delhi',
    'Mumbai',
    'Chennai',
    'Bangalore',
    'Delhi',
    'Mumbai',
  ])
  const [rolesList ,setRoleList ] =useState(null)
  const { REACT_APP_CORPORATE_API_URL } = process.env
  const [currentTable, setcurrentTable] = useState('Recent')
  const object = useRef()
  const handleMenuClick = e => {
    if (e.key === '1') {
      setcurrentTable('Recent')
      setsortValue('')
    } else if (e.key === '2') {
      setcurrentTable('Closing Date')
      setsortValue('sort=endDate&orderBy=asc')
      
    } else if (e.key === '3') {
      setcurrentTable('Salary: Low to High')
      setsortValue('sort=minCompensation')
      
    } else if (e.key === '4') {
      setcurrentTable('Salary: High to Low')
      setsortValue('sort=maxCompensation')
     
    } 
  }


  useEffect(() => {
    getRolesList({page, sortValue, search, jobTypeLevel, jobType, minCTC, maxCTC, locationFilter, setLoading, jobTypeIndustry})
  }, [page, sortValue, search, jobTypeLevel, jobType, minCTC, maxCTC, locationFilter, jobTypeIndustry])

  useEffect(()=>{
    getFilterData()
  },[])

  const getFilterData = () => {
    try {
      reqInstance.get(`${REACT_APP_CORPORATE_API_URL}/corporates/jobs/all`).then((response) => {
        console.log('Response Data:', response.data);

        const uniqueIndustries = response.data.data.uniqueIndustries || [];
        setUniqueIndustries(uniqueIndustries);
        
        const uniqueCities = response.data.data.uniqueCities || [];
        
        const cityOptions = uniqueCities.map(city => ({
          label: city.name,
          value: city.name,
        }));
        
        setUniqueCities(cityOptions);
      });
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleChange2 = (selectedCities) => {
    setLocationFilter(selectedCities); 
    setPage(0);
  };

  const getRolesList = ({page,sortValue,search,jobTypeLevel,jobType,minCTC,maxCTC,locationFilter,jobTypeIndustry}) =>{
    setLoading(true)
    let orderBy =''
  let pagekey = `&pageNo=${page}` 
  let sortkey = sortValue ? `&${sortValue}` : ''
  if(sortValue === 'sort=minCompensation'){
    sortkey = '&sort=maxCompensation'
    orderBy = '&orderBy=asc'
  }
  else if(sortValue === 'sort=maxCompensation'){
    sortkey = '&sort=maxCompensation'
    orderBy = '&orderBy=desc'
  }
  else{
    sortkey = sortValue ? `&${sortValue}` : ''
  }

  

  let searchKey = search ? `&searchBy=${search}` : ''

  let min = minCTC != 0 ? minCTC* 100000 : 1
  let max = maxCTC * 100000
  let minKey =  min ? `&minCTC=${min}` : ''
  let maxKey = max ? `&maxCTC=${max}` : ''

  let JTValue='';
  let JTLValue= jobTypeLevel?.length > 0 ? `&jobTypeLevels=${transformedData?.toString()}` : ''
  if(jobType.length === 1){
    if(jobType[0] === 'FULL TIME'){
      JTValue = 'jobType=FULL_TIME'
    }
    if(jobType[0] === 'PART TIME'){
      JTValue = 'jobType=PART_TIME'
    }
  }
  

  let JTKey =  JTValue ? `&${JTValue}` : ''
  // let locationKey = locationFilter.length > 0 ? `&jobLocation=${locationFilter}` : ''

  let locationKey = locationFilter?.length > 0 ? `&jobLocation=${locationFilter.join(',')}` : '';

  let industryKey = jobTypeIndustry?.length > 0 ? `&industries=${encodeURIComponent(jobTypeIndustry?.toString())}` : '';

  console.log("industry key ",industryKey);
 
  try {
    reqInstance.get(`${REACT_APP_CORPORATE_API_URL}/corporates/jobs/all?pageLimit=10${pagekey}${orderBy}${sortkey}${searchKey}${JTLValue}${JTKey}${minKey}${maxKey}${locationKey}${industryKey}`).then((response) => {
     console.log(response.data)
       setRoleList(response?.data?.data)
       setLoading(false)
      });
        
  }
  catch(error){
  console.log(error)
  }
  }
  const searchValue = value => {
    setPage(0)
    setsearch(value)
   
  }
  const delaySaveToDb = useCallback(
    debounce(val => {
      searchValue(val)
    }, 1000),
    []
  )

  const handleChange = e => {
    setsearch(e.target.value); 
    delaySaveToDb(e.target.value); 
  };

  const [inputMessage, setInputMessage] = useState('');
  const [displayedMessage, setDisplayedMessage] = useState('Nil');

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleShowMessage = () => {
    setDisplayedMessage(inputMessage);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setDisplayedMessage(inputMessage);
    }
  };


  function debounce(fn, delay) {
    let timer
    return function () {
      let context = this,
        args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
  const onChange = key => {}

  const onClear = value => {
    setEntry("");
    setIntern("");
    setFullTime("");
    setPartTime("");
    setTechnology("");
    setMedia("");
    setFinance("");
    setHealth("");
    setEducation("");
    setGovernment("");
    setsearch("");
    setJobTypeIndustry([]);
    setjobTypeLevel([]);
    setjobType([]);
    setLocationFilter([]);
    setminCTC(0);
    setmaxCTC(100);
    setinitialFilter([]);
    getRolesList({page: 0, sortValue: '', search: '', jobTypeLevel: [], jobType: [], minCTC: 0, maxCTC: 100, locationFilter: []});
  }

  const onPageChange = key => {
    object.current.scrollIntoView({ behavior: 'smooth' })
    setPage(key-1) 
  }

  const onAddJobTypeLevel = (e) => {
    const { name, checked } = e.target;
  
    if (checked) {
      addJobTypeLevel(name);
    } else {
      removeJobTypeLevel(name);
    }
  
    setPage(0);
  };

  const addJobTypeLevel = (name) => {
    if (name === 'ENTRY LEVEL') {
      setEntry(true);
    } else if (name === 'INTERNSHIP') {
      setIntern(true);
    }
  
    setjobTypeLevel((prev) => [...prev, name]);
    setinitialFilter((prev) => [...prev, name]);
  };

  const removeJobTypeLevel = (name) => {
    if (name === 'ENTRY LEVEL') {
      setEntry(false);
    } else if (name === 'INTERNSHIP') {
      setIntern(false);
    }
  
    setjobTypeLevel((prev) => prev.filter((tag) => tag !== name));
    setinitialFilter((prev) => prev.filter((tag) => tag !== name));
  };

  // const onAddJobTypeLevel = (e) => {
  //   if (e.target.name === "ENTRY LEVEL") {
  //     if (e.target.checked === true) { 
  //       setEntry(!entry);
  //       setjobTypeLevel([...jobTypeLevel, e.target.name]);
  //       setinitialFilter([...initialFilter, e.target.name]);
  //     }
  //     if (e.target.checked === false) {
  //       setEntry(!entry);
  //       handleClose(e.target.name);
  //     }
  //   }
  //   if (e.target.name === "INTERNSHIP") {
  //     if (e.target.checked === true) {
  //       setIntern(!intern);
  //       setjobTypeLevel([...jobTypeLevel, e.target.name]);
  //       setinitialFilter([...initialFilter, e.target.name]);
  //     }
  //     if (e.target.checked === false) {
  //       setIntern(!intern);
  //       handleClose(e.target.name);
  //     }
  //   }
  //   if (e.target.name === "EXPERIENCE") {
  //     if (e.target.checked === true) {
  //       setExperience(!experience);
  //       setjobTypeLevel([...jobTypeLevel, e.target.name]);
  //       setinitialFilter([...initialFilter, e.target.name]);
  //     }
  //     if (e.target.checked === false) {
  //       setExperience(!experience);
  //       handleClose(e.target.name);
  //     }
  //   }
  // };

  const onAddJobType = e => {
    setPage(0);
    if (e.target.name === 'FULL TIME') {
      if (e.target.checked === true) {
        setFullTime(!fullTime)
        setjobType([...jobType, e.target.name])
        setinitialFilter([...initialFilter, e.target.name])
       
      }
      if (e.target.checked === false) {
        setFullTime(!fullTime)
        handleClose(e.target.name)
      }
    }
    if (e.target.name === 'PART TIME') {
      if (e.target.checked === true) {
        setPartTime(!partTime)
        setjobType([...jobType, e.target.name])
        setinitialFilter([...initialFilter, e.target.name])
     
      }
      if (e.target.checked === false) {
        setPartTime(!partTime)
        handleClose(e.target.name)
      }
    }
    
  }

  const onAddIndustryType = (e) => {
    let checkedValues = jobTypeIndustry
    if (e.target.checked) {
      checkedValues = [...jobTypeIndustry, e.target.value]
    } else {
      checkedValues = checkedValues.filter(item => item !== e.target.value)
    }
   setJobTypeIndustry(checkedValues)
  };

  const onLocationChange = (e) => {
    setLocationFilter([e.target.value]);
   
  }

  const onCTCchange = value => {
    console.log(value,'chnage')
    setminCTC(value[0])
    setmaxCTC(value[1])
  }

  
  const transformedData = jobTypeLevel?.map(item => {
    switch (item) {
      case 'ENTRY LEVEL':
        return 'ENTRY';
      case 'INTERNSHIP':
        return 'INTERN';
      case 'EXPERIENCE':
        return 'EXPERIENCED';
      default:
        return item;
    }
  });


  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Recent',
          key: '1',
        },
        {
          label: 'Closing Date',
          key: '2',
        },
        {
          label: 'Salary: Low to High',
          key: '3',
        },
        {
          label: 'Salary: High to Low',
          key: '4',
        },
      ]}
    />
  )

  const handleClose = (removedTag) => {
    // if (removedTag === "ENTRY LEVEL") {
    //   var index = jobTypeLevel.indexOf(removedTag);
    //   if (index !== -1) {
    //     jobTypeLevel.splice(index, 1);
    //   }
    //   setEntry(false);
    // }
    // if (removedTag === "INTERNSHIP") {
    //   var index = jobTypeLevel.indexOf(removedTag);
    //   if (index !== -1) {
    //     jobTypeLevel.splice(index, 1);
    //   }
    //   setIntern(false);
    // }
    if (removedTag === "EXPERIENCE") {
      var index = jobTypeLevel.indexOf(removedTag);
      if (index !== -1) {
        jobTypeLevel.splice(index, 1);
      }
      setExperience(false);
    }
    if (removedTag === "FULL TIME") {
      var index = jobType.indexOf(removedTag);
      if (index !== -1) {
        jobType.splice(index, 1);
      }
      setFullTime(false);
    }
    if (removedTag === "PART TIME") {
      var index = jobType.indexOf(removedTag);
      if (index !== -1) {
        jobType.splice(index, 1);
      }
      setPartTime(false);
    }
    const newTags = initialFilter.filter(tag => tag !== removedTag)

    setinitialFilter(newTags)
    getRolesList(
      {
      page,
      sortValue,
      search,
      jobTypeLevel,
      jobType,
      minCTC,
      maxCTC,
      locationFilter,
      setLoading
      }
    )
  }
  const handleCloseLocation = removedTag => {
    const newTags = locationFilter.filter(tag => tag !== removedTag)
    setLocationFilter(newTags)
 
  }
  
  const forLocationMap = tag => {
    const tagElem = (
      <StyledTag
        closable
        onClose={e => {
          e.preventDefault()
          handleCloseLocation(tag)
        }}
      >
        {tag}
      </StyledTag>
    )
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
          paddingBottom: '15px',
        }}
      >
        {tagElem}
      </span>
    )
  }
  const ShowText = () =>{
    const currentPage = page+1
    const currentCount = (currentPage * 10)
    if(rolesList?.count === 0){
      return 'Showing '+ rolesList?.count +' of '+ rolesList?.count
    }
    else if(currentPage === rolesList?.totalPage){
     
      return 'Showing '+ rolesList?.count +' of '+ rolesList?.count
    }
    else{
      
      return 'Showing '+ currentCount +' of '+ rolesList?.count
    }
  }
  const forMap = tag => {
    const tagElem = (
      <StyledTag
        closable
        onClose={e => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </StyledTag>
    )
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    )
  }
  const tagChild = initialFilter.map(forMap)
  const locationFilterTag = locationFilter.map(forLocationMap)


  return (
    <>

    <JobListing/>
      <Row style={{padding:"10px"}} ref={object}>
    
          <Col span={24}  lg={6} className='filtercolm'>
          
              <Flex row spaceBetween>
                <TopHeading flexStart className='filterheading'>
                  <Avatar src={<FilterIcon />} />
                  <span className='filter'>Filter</span>
                </TopHeading>
                <TopHeading flexEnd className='clearheading'>
                  <AnchorText onClick={onClear}>Clear all</AnchorText>
                </TopHeading>
              </Flex>
              <Divider />
              <FilterCollapse
                bordered={false}
                expandIconPosition="end"
                defaultActiveKey={['1']}
                onChange={onChange}
              >
                <Panel
                  header="CTC (LPA)"
                  key="1"
                  accordion={false}
                >
                  <Slider
                    range={true}
                    min={0}
                    max={100}
                    value={[minCTC,maxCTC]}
                    tooltip={{ open: true }}
                    label="LPA"
                    onChange={onCTCchange}
                  />
                </Panel>
                <Panel
                  header="Job Type"
                  key="2"
                 
                >
                  <StyledCheckbox
                    name="ENTRY LEVEL"
                    checked={entry}
                    onChange={onAddJobTypeLevel}
                  >
                    Entry Level
                  </StyledCheckbox>

                  <br></br>
                  <StyledCheckbox
                    name="INTERNSHIP"
                    checked={intern}
                    onChange={onAddJobTypeLevel}
                  >
                    Internship
                  </StyledCheckbox>
                  <br></br>
                </Panel>
                <Panel
                  header="Full/Part Time"
                  key="3"
                
                >
                  <StyledCheckbox
                    name="FULL TIME"
                    checked={fullTime}
                    onChange={onAddJobType}
                  >
                    {' '}
                    Full Time
                  </StyledCheckbox>

                  <br></br>
                  <StyledCheckbox
                    name="PART TIME"
                    checked={partTime}
                    onChange={onAddJobType}
                  >
                    {' '}
                    Part Time
                  </StyledCheckbox>
                </Panel>
                <Panel
                  header="Industry"
                  key="4">
                  {uniqueIndustries.map((industry, index) => (
                    <div key={index}>
                      <StyledCheckbox
                        name={industry}
                        value={industry}
                        checked={jobTypeIndustry.includes(industry)}
                        onChange={e => onAddIndustryType(e)}
                      >
                        {industry}
                      </StyledCheckbox>
                      <br />
                    </div>
                  ))}
                </Panel>
              </FilterCollapse>
          </Col>
         
          <Col span={24}  lg={18} className='remaincolm'>
            <TopHeader row spaceBetween alignCenter>
              <TopHeading flexStart>
                <SearchSInput
                  type="text"
                  value={search}
                  onChange={handleChange}
                  placeholder="Search Job"
                  prefix={<SearchIcon />}
                />

<Select
        mode="multiple"
        placeholder="Select cities"
        onChange={handleChange2}
        options={uniqueCities}
        value={locationFilter}
        className='Locationselect'
      /><br></br>

                <p className='found'>We found <p className='countfound'>{rolesList?.count}</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; jobs! </p>
                
              </TopHeading>

              <TopHeading flexEnd>
                <ShowNum>
                  {ShowText()}
                
                </ShowNum>
                <PaddedDropdown text={currentTable} menu={menu} />
              </TopHeading>
            </TopHeader>
            <ShowNum1>
                  {ShowText()}
                </ShowNum1>
                
            <FilterChipPanel>{tagChild}</FilterChipPanel>
            <FilterChipPanel>{locationFilterTag}</FilterChipPanel>
            {loading ? (
                        <SpinWrapper center>
                          <Spin />
                        </SpinWrapper>
                      ) : (
                        
            <SingleListed
              DataList={rolesList}
              page={page + 1}
              onPageChange={onPageChange}
            />
          
            )}
          </Col>
        </Row>

        <br></br>
      <br></br>

      
      {/* <Footer /> */}
    </>
  )
}
export default Jobs
