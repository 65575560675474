import React from "react";

const ApartmentIcon = ({ width = "34", height = "34" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none">
      <g mask="url(#mask0_921_70572)">
        <path
          d="M4.25 29.75V9.91667H9.91667V4.25H24.0833V15.5833H29.75V29.75H18.4167V24.0833H15.5833V29.75H4.25ZM7.08333 26.9167H9.91667V24.0833H7.08333V26.9167ZM7.08333 21.25H9.91667V18.4167H7.08333V21.25ZM7.08333 15.5833H9.91667V12.75H7.08333V15.5833ZM12.75 21.25H15.5833V18.4167H12.75V21.25ZM12.75 15.5833H15.5833V12.75H12.75V15.5833ZM12.75 9.91667H15.5833V7.08333H12.75V9.91667ZM18.4167 21.25H21.25V18.4167H18.4167V21.25ZM18.4167 15.5833H21.25V12.75H18.4167V15.5833ZM18.4167 9.91667H21.25V7.08333H18.4167V9.91667ZM24.0833 26.9167H26.9167V24.0833H24.0833V26.9167ZM24.0833 21.25H26.9167V18.4167H24.0833V21.25Z"
          fill="#CCCFD6"
        />
      </g>
    </svg>
  );
};

export default ApartmentIcon;
