// last Name.js
import React from 'react';
import { Form, Input } from 'antd';
import './stylelastname.css'

const lastName = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue); 
  };

  const validateSpacesLast = (rule, value, callback) => {
    if (value && value.trim().length === 0) {
      callback("Please enter valid name!");
    } else {
      callback();
    }
  };

  return (
    <Form.Item
      name="lastName"
      rules={[
        {
          required: true,
          message: "Please enter last name!",
        },
        {
          validator: validateSpacesLast,
        },
      ]}
    >
      <Input
        value={value}
        placeholder="Enter last name"
        className="lstnamesize"
        onChange={handleInputChange} 
      />
    </Form.Item>
  );
};

export default lastName;
