import React,{useState,useEffect,useRef} from 'react';
import { Container, Row, Col,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styleco.css';
import corporate from './images/corporates.png';
import play from './images/playbutton.png';
import brand1 from './images/brand.png';
import access1 from './images/access.png';
import automation1 from './images/automation.png';
import hire1 from './images/hire.png';
import adobe from './images/adobe.png';
import adidas from './images/adidas.png';
import burger from './images/burgerking.png';
import fedex from './images/fedex.png';
import kfc from './images/kfc.png';
import uptime from './images/uptime.png';

import tablet from './images/tablet.png';
import arrow from './images/arrow.png';
import pp1 from './images/ppl1.png';
import pp2 from './images/ppl2.png';
import pp3 from './images/ppl4.png';
import whitea from './images/white circle arrow.png';

import Client from '../../Assets/company/client.png';
import Cuemath from '../../Assets/company/cuemath.png';
import Rbl from '../../Assets/company/rblbank.png';
import Rb from '../../Assets/company/relevance_broking_services_logo.png';
import Trigyn from '../../Assets/company/trigyn.png';
import Delhivery from '../../Assets/company/delhivery.png';
import Lic from '../../Assets/company/lic.png';
import Kotak from '../../Assets/company/kotak.png';
import Lloyds from '../../Assets/company/lloyds.png';
import Corpcare from '../../Assets/company/corpcare.png';
import Ak from '../../Assets/company/ak.png';
import Dabur from '../../Assets/company/Dabur-Logo.png';
import Coreintegra from '../../Assets/company/coreintegra.png';
import Lighthouse from '../../Assets/company/lighthouse.png';
import Austin from '../../Assets/company/Austin-Logo-scaled.png';
import Hdfc from '../../Assets/company/hdfc.png';
import Nse from '../../Assets/company/nse.png';
import Indusland from '../../Assets/company/indusland.png';

import Pes from '../../Assets/institution/pesuniversity.jpg';
import Ifim from '../../Assets/institution/ifiminstitution.jpeg';
import Holymary from '../../Assets/institution/holy-mary-.png';
import Ramanujam from '../../Assets/institution/ramanu-logo.96e81bbdf814c655f1ab.png';
import Motilal from '../../Assets/institution/Logo_of_Motilal_Nehru_College.05914d823d3c4c9a1992.png';
import Avcol from '../../Assets/institution/avcol.jpg';
import Joseph from '../../Assets/institution/stjoseph.jpg';
import Vishwa from '../../Assets/institution/vishwa.png';
import Sardar from '../../Assets/institution/sardaepatel.png';
import Guru from '../../Assets/institution/gurunanak.png';
import Vijaybhoomi from '../../Assets/institution/vijaybhoomi.jpg';
// import Jeppiar from '../../Assets/institution/jeppiaar.png';

import Gif from '../../Assets/images/loadinggif.gif';


import Hover1 from '../../Assets/images/hover1.png';
import Hover2 from '../../Assets/images/hover2.png';
import Hover3 from '../../Assets/images/hover3.png';

import Clickcore from '../../Assets/images/clickincore.png';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useNavigate } from 'react-router-dom';
// import Aos from 'aos';
// import 'aos/dist/aos.css';
import { duration } from 'moment';
import Signupfooter from '../SignupFooter/signupfooter';

import TestGorilla from '../../Assets/Assessmentlogo/testgorilla.jpeg';
import Eskill from '../../Assets/Assessmentlogo/eskill_Logo.jpg';

import TrainingContainer from "../Corporate/TrainingpartnerContainer";

import LogoComp from '../LogoComponent/logocomp';
import Section from '../Mainsection/index';
import Unlockindex from "../UnlockComponent/unlockindex";

export default function Corporate(){

  
  // chat animation start 

  const rowRef = useRef(null);

  useEffect(() => {
    const revealRow = () => {
      const row = rowRef.current;
      if (!row) return;

      const windowHeight = window.innerHeight;
      const rowTop = row.getBoundingClientRect().top;
      const revealPoint = 150;

      if (rowTop < windowHeight - revealPoint) {
        row.classList.add('active');
      } else {
        row.classList.remove('active');
      }
    };

    window.addEventListener('scroll', revealRow);

    return () => {
      window.removeEventListener('scroll', revealRow);
    };
  }, []);


  // chat animation end

      // luck animation start 

      const [text] = useTypewriter({
        words: [' workforce', ' fast source', ' engage'],
        loop: {},
        typeSpeed: 150,
        deleteSpeed: 150,
        delaySpeed: 800,
    });
  
  
    // luck animation end 


    const navigate = useNavigate();

  // useEffect(()=> {
  //   Aos.init({duration:800})
  // },[]);
  
  
    const [isInView, setIsInView] = useState(false);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const images = document.querySelectorAll('.patmarimg');

  //     images.forEach(image => {
  //       const top = Math.floor(Math.random() * (window.innerHeight - image.clientHeight));
  //       const left = Math.floor(Math.random() * (window.innerWidth - image.clientWidth));
  //       image.style.top = `${top}px`;
  //       image.style.left = `${left}px`;
  //     });
  //   }, 3000); 

  //   return () => clearInterval(intervalId);
  // }, []);

  // useEffect(() => {
  //   const container = document.querySelector('.imgscrollup');
  //   const images = container.querySelectorAll('.patmarimg');

  //   images.forEach(image => {
  //     const top = Math.floor(Math.random() * (container.clientHeight - image.clientHeight));
  //     const left = Math.floor(Math.random() * (container.clientWidth - image.clientWidth));
  //     image.style.top = `${top}px`;
  //     image.style.left = `${left}px`;
  //   });
  // }, []);



  // animation circle start 
  // const [isScrollingexp, setIsScrollingexp] = useState(false);
  // const containerRefexp = useRef(null);

  // useEffect(() => {
  //     const handleScrollexp = () => {
  //         const containerexp = containerRefexp.current;
  //         const rectexp = containerexp.getBoundingClientRect();
  //         if (rectexp.top >= 0 && rectexp.bottom <= window.innerHeight) {
  //             setIsScrollingexp(true);
  //         } else {
  //             setIsScrollingexp(false);
  //         }
  //     };

  //     window.addEventListener('scroll', handleScrollexp);

  //     return () => {
  //         window.removeEventListener('scroll', handleScrollexp);
  //     };
  // }, []);
  // animation circle end 

  /* box click start */
  const [activeFeature, setActiveFeature] = useState(null);
const containerRefs = [useRef(), useRef(), useRef(), useRef()];

useEffect(() => {
  const handleOutsideClick = (event) => {
    if (activeFeature !== null) {
      const activeContainerRef = containerRefs[activeFeature];
      if (activeContainerRef.current && !activeContainerRef.current.contains(event.target)) {
        setActiveFeature(null);
      }
    }
  };

  document.addEventListener('mousedown', handleOutsideClick);

  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };
}, [activeFeature, containerRefs]);

const toggleDiv = (index) => {
  setActiveFeature((prevActiveFeature) => (index === prevActiveFeature ? null : index));
};

/* box click end */

  // useEffect(() => {
  //   const container2 = document.querySelector('.imgscrollup');
  //   const clone2 = container2.cloneNode(true);
  //   container2.parentNode.appendChild(clone2);

  //   function handleAnimationEndexp() {
  //     container2.parentNode.removeChild(container2);
  //   }

  //   container2.addEventListener('animationend', handleAnimationEndexp);
  //   return () => {
  //     container2.removeEventListener('animationend', handleAnimationEndexp);
  //   };
  // }, []);

  // useEffect(() => {
  //   const container = document.querySelector('.imgscrollup2');
  //   const clone = container.cloneNode(true);
  //   container.parentNode.appendChild(clone);

  //   function handleAnimationEnd() {
  //     container.parentNode.removeChild(container);
  //   }

  //   container.addEventListener('animationend', handleAnimationEnd);
  //   return () => {
  //     container.removeEventListener('animationend', handleAnimationEnd);
  //   };
  // }, []);

  // useEffect(() => {
  //   const container2 = document.querySelector('.imgscrollupmob');
  //   const clone2 = container2.cloneNode(true);
  //   container2.parentNode.appendChild(clone2);

  //   function handleAnimationEndexp() {
  //     container2.parentNode.removeChild(container2);
  //   }

  //   container2.addEventListener('animationend', handleAnimationEndexp);
  //   return () => {
  //     container2.removeEventListener('animationend', handleAnimationEndexp);
  //   };
  // }, []);

  // useEffect(() => {
  //   const container = document.querySelector('.imgscrollupmob2');
  //   const clone = container.cloneNode(true);
  //   container.parentNode.appendChild(clone);

  //   function handleAnimationEnd() {
  //     container.parentNode.removeChild(container);
  //   }

  //   container.addEventListener('animationend', handleAnimationEnd);
  //   return () => {
  //     container.removeEventListener('animationend', handleAnimationEnd);
  //   };
  // }, []);
  
    const Luck = {
        color: 'linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%)',
    };

    // const [luckIndex, setLuckIndex] = useState(0);
    // const luckWords = [' workforce', ' fast source', ' engage'];
  
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setLuckIndex(prevIndex => (prevIndex + 1) % 3);
    //     }, 1500);
  
    //     return () => clearInterval(intervalId);
    //   }, []);


       // Animation boxes start

  
  const [isDancingexp, setIsDancingexp] = useState(false);

  useEffect(() => {
    const handleScrollexp = () => {
      // Check the scroll position
      const scrollPosition = window.scrollY;
      
      // You can adjust the threshold as needed
      if (scrollPosition > 200) {
        setIsDancingexp(true);
      } else {
        setIsDancingexp(false);
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScrollexp);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScrollexp);
    };
  }, []);


  const [isVisibleexp, setIsVisibleexp] = useState(false);

  useEffect(() => {
    // Show the div with delay when the component mounts
    const timer = setTimeout(() => {
      setIsVisibleexp(true);
    }, 500); // Adjust the delay time as needed

    // Clear the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const handleAnimationEnd = () => {
    // Perform shake animation when the ease-in animation ends
    document.querySelector('.dancing-divvexp').classList.add('shakeexp');
  };

    // animation boxes end 

    const paragraphContent = `
      <p class="mobpatwidthc">Find your future workforce,fast Source, engage, and build your brand Streamline your hiring process with PluginLive</p>
    <div class="corpwidth1">Find your future workforce,</div>
    <div class="corpwidth2"> fast Source, engage, and build your brand</div>
    <div class="corpwidth3">Streamline your hiring process with PluginLive</div>
    `;
      
    const words = ['renown', 'refinement', 'reputation'];

    const headers = ['Campus Talent Acquisition', 'Lateral Talent Acquisition', 'Branding and Engagement Events', 'Advanced Tracking Solutions'];

    const subheaders=['Tap into promising young talent through targeted campus recruitment strategies with tailored recruitment and talent management solutions designed to meet the unique needs and objectives of corporates.','Expand your team with experienced professionals tailored to your organizational needs. Access to a vast network of qualified candidates, including both active and passive job seekers, to fill key positions quickly and efficiently. ','Enhance your employer brand with engaging campus and off-campus events, boosting awareness and attracting top talent, whether hiring for a single position or multiple roles across different departments or locations.','Streamlined and efficient hiring processes, leverage cutting-edge lateral ATS technology for efficient candidate evaluation and selection processes.'];

    
    return(
        <>  
        <Container className='corporatepagemain'>
      {/* <Row>
        <Col xs={12} sm={12} md={6}>
          <div className="contentt4">


            <p>Find your future workforce, fast
Source, engage, and build your brand
Streamline your hiring process with PluginLive
</p>

          </div>
          
        </Col><br></br>
        <Col xs={12} sm={12} md={6}>
            <div className="im-container1 d-flex">
                <div className="background-container4"></div> 
                <span className='orgvector4 d-flex justify-content-center align-items-center'>
                 <img src={corporate} alt="fresher" className="corpimgtop" />
                </span>
            </div>

            <div className={`dancing-divvexp ${isVisibleexp ? 'visible' : ''}`} >

          <div className={`dancing-divexp ${isDancingexp ? 'dancing' : ''}`}>
            <div className='corpanimationcontainer'>

          <div className='im-container2 '> 
          <span className='violetbox4 d-flex '><p className='vt1corp'>100+<br/></p><p className='vt2cor'>Corporates</p></span>
          </div>  

            <div className='im-container3 '>
            <div className='linebox4 d-flex'>
              <div className='hlinebox1corp d-flex'>
                <div className='hlinebox2corp d-flex'>
                </div>
              </div>

              <div className='rlinebox1corp d-flex'>
                <div className='rlinebox2corp d-flex'>
                </div>
              </div>
              <p className='lineboxtext'>34k<br/><p className='lineboxtext2'>Colleges</p></p>
            </div>         
            
            </div>
          

          <div className='im-container5 d-flex'>
          <span className='yellowbox4'><p className='yt1'>100k<br/>
              <p className='yt2 mt-0'>Students</p></p></span>
          </div>

          </div>

          </div>
          </div>



          <div className='im-container4 d-flex'>
            <span className='bluec4'><img className='whiteacorp' src={whitea} alt='white arrow'/></span>
          </div>
        </Col>
      </Row>

      <Row>


        <Col xs={6} sm={6} md={6}className='firstsignup4'>
        <button className='watchcorp'>
          <p className='watchs4corp'>
            Request a demo</p></button>
        </Col>

        <Col xs={12} sm={12} md={12} className='firstsignup14'>
        <br></br>
        </Col>

        </Row> */}

        <Section paragraph={<div dangerouslySetInnerHTML={{ __html: paragraphContent }} />} words={words} imageSrc={corporate} bgpartClassName='bgpart5' profileType='corporate'/>
        <br></br>

        
        <Row>
          <Col xs={12} sm={12} md={12} className='sechead4'>

          {/* <span className='title1tagcorp d-flex justify-content-center align-items-center'><span className='ttextcorp'>Explore</span></span> */}

          <h1 className='title2tag2corp d-flex justify-content-center align-items-center'>Unlock Your Corporate Potential</h1>
          <h1 className='title2tag2corp2 d-flex justify-content-center align-items-center'>Our solutions for enhanced talent acquisition</h1>
          </Col>
          
          <Unlockindex headers={headers} subheaders={subheaders}/>

        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} className='corevalues2corp'>
                <div className='brandcorp'>
                <p className='brandhcorp' style={{color:"rgba(255, 255, 255, 1)"}}>Campus Talent Acquisition</p>
                        {/* <!-- <input type="text" class="form-control form-control-lg" placeholder="Text Box 1"> --> */}
                        <p className='brandppcorp'>Tap into promising young talent through targeted campus recruitment strategies with tailored recruitment and talent management solutions designed to meet the unique needs and objectives of corporates.</p> 
                </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevalues2corp'>
            <div className='hirecorp'>
            <p className='hirehcorp' style={{color:"rgba(255, 255, 255, 1)"}}>Advanced Tracking Solutions</p>
                        <p className=' hireppcorp'>Streamlined and efficient hiring processes, leverage cutting-edge lateral ATS technology for efficient candidate evaluation and selection processes.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevalues2corp'>
            <div className='digitalcorp'>
            <p className='digitalhcorp' style={{color:"rgba(255, 255, 255, 1)"}}>Branding and Engagement Events</p>
                        <p className='digitalppcorp'>Enhance your employer brand with engaging campus and off-campus events, boosting awareness and attracting top talent, whether hiring for a single position or multiple roles across different departments or locations.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevalues2corp'>
            <div className='traincorp'>
            <p className='trainhcorp' style={{color:"rgba(255, 255, 255, 1)"}}>Lateral Talent Acquisition</p>
                        <p className='trainppcorp'>Expand your team with experienced professionals tailored to your organizational needs. Access to a vast network of qualified candidates, including both active and passive job seekers, to fill key positions quickly and efficiently. </p>      
            </div>
          </Col>
        </Row>

        {/* <Row className='d-flex justify-content-center'>
        <Col xs={12} sm={12} md={12}>
          <span className='title1tagcorp d-flex justify-content-center align-items-center mt-5'><span className='ttextcorp'>Vetted by Industry Leaders</span></span><br></br>

          <p className='title2tagcorp2g d-flex justify-content-center align-items-center'>Our Partnerships with Seasoned Veterans</p><br/>

          <h1 className='title2tagcorp d-flex justify-content-center align-items-center'>Corporate</h1><br></br>
          </Col>

          <div className='logos'>
          <div className='logo-slide'>
            <img className='client' src={Client} alt='client'/>
            <img className='cuemath' src={Cuemath} alt='Cuemath'/>
            <img className='Rbl' src={Rbl} alt='Rbl'/>
            <img className='Rb' src={Rb} alt='Rb'/>
            <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
            <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
            <img className='Lic' src={Lic} alt='Lic'/>
            <img className='Kotak' src={Kotak} alt='Kotak'/>
            <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
            <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
            <img className='Ak' src={Ak} alt='Ak'/>
            <img className='Dabur' src={Dabur} alt='Dabur'/>
            <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
            <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
            <img className='Austin' src={Austin} alt='Austin'/>
            <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
            <img className='Nse' src={Nse} alt='Nse'/>
            <img className='Indusland' src={Indusland} alt='Indusland'/>
          </div>

          <div className='logo-slide'>
            <img className='client' src={Client} alt='client'/>
            <img className='cuemath' src={Cuemath} alt='Cuemath'/>
            <img className='Rbl' src={Rbl} alt='Rbl'/>
            <img className='Rb' src={Rb} alt='Rb'/>
            <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
            <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
            <img className='Lic' src={Lic} alt='Lic'/>
            <img className='Kotak' src={Kotak} alt='Kotak'/>
            <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
            <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
            <img className='Ak' src={Ak} alt='Ak'/>
            <img className='Dabur' src={Dabur} alt='Dabur'/>
            <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
            <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
            <img className='Austin' src={Austin} alt='Austin'/>
            <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
            <img className='Nse' src={Nse} alt='Nse'/>
            <img className='Indusland' src={Indusland} alt='Indusland'/>
          </div>
        </div>



        <Col xs={12} sm={12} md={12}>
          <h1 className='title2tagcorp d-flex justify-content-center align-items-center mt-5'>Institutes</h1><br></br>
        </Col>

        <div className='logoss'>
          <div className='logos-slide'>
            <img className='instlogo' src={Pes} alt='pes'/>
            <img className='instlogo' src={Ifim} alt='Ifim'/>
            <img className='instlogo' src={Holymary} alt='Holymary'/>
            <img className='instlogo' src={Ramanujam} alt='Ramanujam'/>
            <img className='instlogo' src={Motilal} alt='Motilal'/>
            <img className='instlogo' src={Avcol} alt='Avcol'/>
            <img className='instlogo' src={Joseph} alt='Joseph'/>
            <img className='instlogo' src={Vishwa} alt='Vishwa'/>
            <img className='instlogo' src={Sardar} alt='Sardar'/>
            <img className='instlogo' src={Guru} alt='Guru'/>
            <img className='instlogo' src={Vijaybhoomi} alt='Vijaybhoomi'/>
          </div>

          <div className='logos-slide'>
          <img className='instlogo' src={Pes} alt='pes'/>
            <img className='instlogo' src={Ifim} alt='Ifim'/>
            <img className='instlogo' src={Holymary} alt='Holymary'/>
            <img className='instlogo' src={Ramanujam} alt='Ramanujam'/>
            <img className='instlogo' src={Motilal} alt='Motilal'/>
            <img className='instlogo' src={Avcol} alt='Avcol'/>
            <img className='instlogo' src={Joseph} alt='Joseph'/>
            <img className='instlogo' src={Vishwa} alt='Vishwa'/>
            <img className='instlogo' src={Sardar} alt='Sardar'/>
            <img className='instlogo' src={Guru} alt='Guru'/>
            <img className='instlogo' src={Vijaybhoomi} alt='Vijaybhoomi'/>
          </div>
        </div>

 
        </Row> */}

        <LogoComp/>

         <br></br><br></br><br></br><br></br> 

        {/* <Row className='phonebox4'>
        <Col xs={12} sm={12} md={7}>
        <div className='phonetitles4'>
        <span className='phonetitle1tag4corp d-flex justify-content-center align-items-center'><span className='ttext'>Make your talent acquisition goals a reality</span></span><br></br>  
        <h1 className='phoneheadtitle4'>Step into the future of recruitment!</h1>
          </div>  
        </Col><br></br>

        <Col xs={12} sm={12} md={6}>
        <p className='phonebody4lap'>Bid farewell to outdated hiring methods and welcome a new era of seamless talent acquisition processes. Let's embark on this journey together and elevate your recruitment strategy to new heights.</p>
        </Col>

        <Col xs={12} sm={12} md={5}>
          <img className='arrow4' src={arrow} alt='arrow'/>
        </Col>


        <Col xs={12} sm={12} md={6}>
          <div className="image-phone-container4">

          <div ref={containerRefexp} className={`container ${isScrollingexp ? 'scrolling' : ''}`}>

          <div className='orgcircle4'></div>
            <div className='viocircle4'></div>
          <div className='bluecircle4'></div>
          <div className='yellowcircle4'></div>

          </div>

          <div style={{ position: 'relative' }}>

<img className='phone4' src={tablet} alt='phone'>
  </img>

  <img src={Gif} alt='' className='gifcorp'/>
  </div> 
            


          </div>
        </Col>

        <Col xs={12} sm={12} md={6} className='d-sm-none'>
        <p className='phonebody4'>Bid farewell to outdated hiring methods and welcome a new era of seamless talent acquisition processes. Let's embark on this journey together and elevate your recruitment strategy to new heights.</p>
        </Col>
      </Row> */}

      {/* <Row className='partsbox1'>
          <Col xs={12} sm={12} md={12}>
            <div className='parttitle1'>
            <br></br>
            <h1 className='partheadtitle1'>Training Partners</h1>


            <div className='containercorptrainbox'>
            <div className='corptrainbox'>
               <div className='imgscrollupmob'>


<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>

<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
            </div>
                

            </div>
            </div>


  
              <p className='pa1'>Collaborate with us to elevate your workforce through specialized skill development programs. Equip your team with cutting-edge expertise tailored to your industry's needs, and together, conquer new heights in your field with our customized training solutions.</p>


            </div><br></br><br></br>
          </Col>
      </Row> */}

      {/* <Row className='partsbox2 '>
          <Col xs={12} sm={12} md={12}>
            <div className='parttitle2'>
    
            <br></br>
            <h1 className='partheadtitle2'>Assessment Partners</h1>


            <div className='containercorptrainbox'>
            <div className='corptrainbox'>
               <div className='imgscrollupmob2'>

<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>


<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
            </div>
                

            </div>
            </div>






              <p className='pa3'>Ready to identify top talent efficiently? Access cutting-edge pre-employment assessments and resources to assess candidates effectively and make informed hiring decisions. Let's revolutionize your recruitment process together! </p>

              
            </div><br></br><br></br>
          </Col>

          
      </Row> */}

      <TrainingContainer/>


      
      

      {/* <Row className='chat'>

      <Col xs={12} sm={12} md={12}>
        <div className='chathead'>
        <span className='chatitle1tag d-flex justify-content-start'><span className='ctext d-flex justify-content-start'>Title Header</span></span><br></br>  
        <h1 className='chatheadtitle d-flex justify-content-start'>What are they saying about us?</h1><br></br><br></br>
        </div>
        
        
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox1'>
          <img className='chatimg1' src={pp3} alt='ppl1'/>
          <span className='chatbox11 d-flex'>
            <p className='chatboxtext1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
            </span>
            <span className='chatbox111'></span>          
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox2'>
          <span className='chatbox21 d-flex'>
          <p className='chatboxtext21'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
          </span>
          <span className='chatbox121'></span>
        <img className='chatimg2' src={pp1} alt='burger'/>
        </Col>

        <Col xs={12} sm={12} md={6} className=' d-none d-lg-block'>
          
        <span className='chatbox31 d-flex'>
          <p className='chatboxtext31'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
          </span>
          <span className='chatbox131'></span>
          <img className='chatimg3' src={pp2} alt='burger'/>
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox4'>
        <img className='chatimg4' src={pp2} alt='burger'/>
        <span className='chatbox41 d-flex'>
            <p className='chatboxtext41'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
        </span>
        <span className='chatbox141'></span>
        </Col>

        <Col xs={12} sm={12} md={6} className='chatboxnil'> */}
          {/* <h1>Hellooo</h1> */}
        {/* </Col>

        <Col xs={12} sm={12} md={6} className='chatbox5'>
        <img className='chatimg5' src={pp3} alt='burger'/>
        <span className='chatbox51 d-flex'>
        <p className='chatboxtext51'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
        </span>
        <span className='chatbox151'></span>
        </Col>
      </Row> */}

      <Row className='partnerbox2'>
        <Col xs={12} sm={12} md={12}>

        {/* <div className='patdivcontainer'>


<h1 className='patboxheader'>Training Partners</h1>

<p className='patboxheaderpara'>Collaborate with us to elevate your workforce through specialized skill development programs.<br className='lapbreak'></br><br className='lapbreak'></br>Equip your team with cutting-edge expertise tailored to your industry's needs, and together, conquer new heights in your field with our customized training solutions.</p>



  <div className='imgscrollup'>


<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>

<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>


</div>

</div> */}


        {/* <div className='mainbox2'></div>
        <div className='maintitle2'>
        <span className='maintitletag2 d-flex justify-content-center align-items-center'><span className=''>Title Header</span></span><br></br>
        <h1 className='mainheadtitle2'>Training Partners</h1>
          <p className='paras1'>Collaborate with us to elevate your workforce through specialized skill development programs. Equip your team with cutting-edge expertise tailored to your industry's needs, and together, conquer new heights in your field with our customized training solutions.</p>

          

          <button className='registers3'><p className='registert' style={{color:" rgba(255, 255, 255, 1)",fontFamily:"'Plus Jakarta Sans', sans-serif"}}>Register</p> </button>
        </div> */}
        {/* <div className='mainimg2'>
          <div className='mainfedex2'>
            <span className='msfedex2'></span>
            <img className='mfedex2' src={fedex} alt='fedex'/>
          </div>
          <div className='mainkfcs2'>
            <img className='mkfcs2' src={kfc} alt='kfc'/>
            <span className='mskfcs2'></span>
          </div>
          <div className='mainadidas2'>
            <span className='msadidas2'></span>
            <img className='madidas2' src={adidas} alt='adidas'/>
          </div>
          <div className='mainburger2'>
            <span className='msburger2'></span>
            <img className='mburger2' src={burger} alt='burger'/>
          </div>
          <div className='mainfedex3'>
            <span className='msfedex3'></span>
            <img className='mfedex3' src={fedex} alt='fedex'/>
          </div>
          <div className='mainkfc3'>
            <span className='mskfc3'></span>
            <img className='mkfc3' src={kfc} alt='kfc'/>
          </div>
        </div> */}


        </Col>
      </Row> 

      <Row className='partner2box'>
        <Col xs={12} sm={12} md={12}>


          {/* <div className='main2box'></div>
          <div className='main2title'>
            <span className='main2titletag d-flex justify-content-center align-items-center'><span className=''>Title Header</span></span><br></br>
            <h1 className='main2headtitle'>Assessment Partners</h1>
            <p className='paras3'>Ready to identify top talent efficiently? Access cutting-edge pre-employment assessments and resources to assess candidates effectively and make informed hiring decisions. Let's revolutionize your recruitment process together!  </p>

  

            <button className='registers4'><p className='registert' style={{color:" rgba(255, 255, 255, 1)",fontFamily:"'Plus Jakarta Sans', sans-serif"}}>Register</p> </button>
          </div> */}

          {/* <div className='imgscrollup'>

          <div className='mainfedex1'>
            <img className='patmarimg' src={fedex} alt='fedex'/>
          </div>
          <div className='mainkfc1'>
            <img className='patmarimg' src={kfc} alt='kfc'/>
          </div>
          <div className='mainadidas1'>
            <img className='patmarimg' src={adidas} alt='adidas'/>
          </div>
          <div className='mainburger1'>
            <img className='patmarimg' src={burger} alt='burger'/>
          </div>  
          <div className='mainkfc2'>
            <img className='patmarimg' src={kfc} alt='kfc'/>
          </div>

          
          </div> */}

   {/* assessment assess */}
          {/* <div className='assesscorpdivcontainer'>

        

          <h1 className='assesscorpboxheader'>Assessment Partners</h1>

          <p className='assesscorpboxheaderpara'>Ready to identify top talent efficiently? Access cutting-edge pre-employment assessments and resources to assess candidates effectively and make informed hiring decisions. Let's revolutionize your recruitment process together!</p>


        

          <div className='imgscrollup2'>

          
            


          <div className='mainkfc1'>
            <img className='patmarimg1' src={TestGorilla} alt='kfc'/>
          </div>
          
          <div className='mainadidas1'>
            <img className='patmarimg2' src={Eskill} alt='adidas'/>
          </div>
          

          
          </div>

        </div> */}

        </Col>
      </Row>

      

      <Signupfooter profile="corporate"/>

      {/* <Row>
        <Col xs={12} sm={12} md={10} className='startedcorp'>
          <div className='startedboxcorp d-flex'>
          <h1 className='startheadcorp'>Ready to join the success stories?</h1><br></br></div>
          <p className='startparatabcorp d-sm-none'>Start your journey with us today and unlock endless opportunities for your career growth. 
</p>
          
          <button className='subbuttontabcorp d-flex' onClick={()=>navigate("/Student_Registration")}><p className='regis'>Sign up now</p></button>
            <p className='startparalapcorp'>Start your journey with us today and unlock endless opportunities for your career growth. 
</p>
        </Col>
        
      </Row> */}
      
      
      <br></br><br></br>
      {/* <br className='lapbreak'></br><br></br><br></br><br></br><br></br> */}

    </Container>
        </>
    );
}







function corporatechat(){
  return(
    <>
      <Row className='chatcor'>

<Col xs={12} sm={12} md={12}>
  <div className='chathead'>
  <span className='chatitle1tagcorp d-flex justify-content-start'><span className='ctext d-flex justify-content-center'>Real Voices, Real Success</span></span><br></br>  
  <h1 className='chatheadtitlecorp d-flex justify-content-start'>Here's what our clients have to say</h1><br></br><br></br>
  </div>
  
  
  </Col>


  <Row 
  // ref={rowRef} 
  className='testimonialscorp row-reveal'>

  <Col 
  
  xs={12} sm={12} md={6} className='chatbox1cor'
  
  // xs={isInView ? 24 : 12} 
  // sm={isInView ? 24 : 12}
  // md={isInView ? 24 : 6}
  // className={`chatbox1cor ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"
  
  >
    <img className='chatimg1cor' src={pp3} alt='ppl1'/>
    <span className='chatbox11cor d-flex'>
      <p className='chatboxtext1cor'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br></br><p className='namesmcor mt-2'>Name,<p className='desgsmcor'> Designation</p></p></p>
      </span>
      <span className='chatbox111cor d-flex justify-content-center align-items-center'></span>          
  </Col>

  <Col 
  xs={12} sm={12} md={6} className='chatbox2cor'
  
  // xs={isInView ? 24 : 12} 
  // sm={isInView ? 24 : 12}
  // md={isInView ? 24 : 6}
  // className={`chatbox2cor ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

  >
  
  
    <span className='chatbox21cor d-flex'>
    <p className='chatboxtext21cor'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br></br><p className='namesmcor mt-2'>Name,<p className='desgsmcor'> Designation</p></p></p>
    </span>
    <span className='chatbox121cor d-flex justify-content-center align-items-center'></span>
  <img className='chatimg2cor' src={pp1} alt='burger'/>
  </Col>

  
  
  <Col 
  
  xs={12} sm={12} md={6} className='chatbox3cor d-none d-lg-block'

  // xs={isInView ? 24 : 12} 
  // sm={isInView ? 24 : 12}
  // md={isInView ? 24 : 6}
  // className={`chatbox3cor d-none d-lg-block ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"
  
  
  >
    
  <span className='chatbox31cor d-flex'>
    <p className='chatboxtext31cor'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesmcor'>Name,<p className='desgsmcor'> Designation</p></p></p>
    </span>
    <span className='chatbox131cor d-flex justify-content-center align-items-center'></span>
    <img className='chatimg3cor' src={pp2} alt='burger'/>
  </Col>

  <Col 
  
  xs={12} sm={12} md={6} className='chatbox4cor'
  
  // xs={isInView ? 24 : 12} 
  // sm={isInView ? 24 : 12}
  // md={isInView ? 24 : 6}
  // className={`chatbox4cor ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"
  
  >
  
  <img className='chatimg4cor' src={pp2} alt='burger'/>
  <span className='chatbox41cor d-flex'>
      <p className='chatboxtext41cor'>Lorem ipsum dolor sit amet, consectetur adipiscing 
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesmcor'>Name,<p className='desgsmcor'> Designation</p></p></p>
  </span>
  <span className='chatbox141cor d-flex justify-content-center align-items-center'></span>
  </Col>

  <Col xs={12} sm={12} md={6} className='chatboxnil'>
    {/* <h1>Hellooo</h1> */}
  </Col>

  <Col 
  
  xs={12} sm={12} md={6} className='chatbox5cor'
  
  // xs={isInView ? 24 : 12} 
  // sm={isInView ? 24 : 12}
  // md={isInView ? 24 : 6}
  // className={`chatbox5cor ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"
  
  >
  <img className='chatimg5cor' src={pp3} alt='burger'/>
  <span className='chatbox51cor d-flex'>
  <p className='chatboxtext51cor'>Lorem ipsum dolor sit amet, consectetur adipiscing 
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesmcor'>Name,<p className='desgsmcor'> Designation</p></p></p>
  </span>
  <span className='chatbox151cor d-flex justify-content-center align-items-center'></span>
  </Col>

  </Row>
</Row>
    </>
  );
}