import styled from "styled-components";
import Flex from "../JobContainer/Flex";
import { COLORS } from "../theme";
import Button from "../JobContainer/Button/index";
import { Checkbox, Collapse, Divider, Input, Tag } from "antd";
import image from "../Joblisting/images/search.png";



export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;

  @media only screen and (max-device-width: 767px) {
    padding: 10px 0px;

  }
`;
export const TopHeading = styled(Flex)`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.GREY_P_100};
  @media only screen and (max-device-width: 767px) {
    padding-top: 20px
  }
`;
export const ShowNum = styled(Flex)`
  font-weight: 700;
  padding-top: 10px;
  padding-right: 15px;
  font-size: 13px;
  line-height: 18px;
  color: ${COLORS.GREY_T_30};
  @media only screen and (max-device-width: 767px) {
    display:none;
  }
`;

export const ShowNum1 = styled(Flex)`
  font-weight: 700;
  padding-top: 10px;
  padding-right: 15px;
  font-size: 13px;
  line-height: 18px;
  color: ${COLORS.GREY_T_30};
  padding-left: 12px;
  display:none;
  @media only screen and (max-device-width: 767px) {
    display:block;
  }
`;
export const AnchorText = styled.a`
  font-style: normal;
  font-weight: 500;
  padding-top: 10px;
  padding-right: 25px;
  font-size: 13px;
  line-height: 18px;
  color: ${COLORS.PRIMARY};
`;

export const TopRightFlex = styled(Flex)`
  gap: 15px;
`;
export const SmallButtons = styled(Button.Default)`
  background: ${COLORS.WHITE};
  padding: 9px;
  > div {
    margin-left: 0;
  }
`;

export const CardRow = styled(Flex)`
  padding: 0 25px 25px 25px;
  gap: 20px;
`;

export const FilterCollapse = styled(Collapse)`
  background-color: #fafafb !important;
  margin-right: 5px;

  .ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px !important;
    font-weight: 600;
    border-radius: 10px !important;
    border-radius: 5px;
    color: ${COLORS.GREY_T_60} !important;
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }
`;

export const StyleLeft = styled.div`
  border-left: 1px solid #e6e7ea;
  padding-left: 10px;
`;

export const LocationDiv = styled.div`
  height: 200px;
  overflow-y: scroll;
`;

export const VerticalDivider = styled(Divider)`
  height: 30px;
  @media only screen and (max-device-width: 767px) {
    display: none;
  }
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px; /* Add some space between rows if needed */
`;

export const OutlineDiv = styled.div`
  height: 70px;
  padding-top: 10px;
`;

export const RightDiv = styled.div`
  position: absolute;
  right: 0%;
  padding-right: 70px;

 

  @media only screen and (max-device-width: 767px) {
    padding-right: 25px;
  }



`;



export const ContentCenterDiv = styled.div`
  text-align: center;
`;
export const CenterButton = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;
export const PaddedLeftFlex = styled(Flex)`
  padding-left: 50px;

  @media screen and (min-device-width: 767px) and (max-device-width: 1026px){
    margin-bottom: 85px;
    }


  @media only screen and (max-device-width: 767px) {
    padding-left: 0px;
    display: flex;
    flex-direction:column;
  }
`;

export const StyledFlex = styled.div`
  position: static;
  padding: 5px 48px 5px 20px;
  width: "100%";
  height: 160px;
  border-radius: 10px;
  background-color:  rgb(250,250,250,1);
  border: 1px solid #e6e7ea;
  margin: 20px;
  margin-top: -1%;
  box-shadow: 0px 2px 8px rgba(201, 201, 201, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: rgb(255,255,255,1);
      // Add any other styles you want to apply on hover
    }

    @media screen and (min-device-width: 767px) and (max-device-width: 1026px){
    height: 190px;
    }


    @media only screen and (max-device-width: 767px) {
      margin: 10px;
      padding: 5px 15px 5px 10px;
      height: 260px;
    }  
`;



export const Name = styled.div`
  font-weight: 700;
  font-size: 20px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #525356;
  @media only screen and (max-device-width: 767px) {
    font-size: 12px;
  }
`;

export const Apply = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  opacity: 0.48;
  @media only screen and (max-device-width: 767px) {
    font-size: 10px;
  width: 9rem;
  text-align: right;

    
  }
  @media only screen and (min-width: 768px )  and  (max-width : 1280px){
    font-size: 10px;
    line-height: 18px;
    width: 8rem;
    text-align: left;
  }
`;

export const Address = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  opacity: 0.48;
  margin-top:-8px;
  @media only screen and (max-device-width: 767px) {
    font-size: 10px;
  width: 9rem;
  text-align: left;
  
    
  }
  @media only screen and (min-width: 768px )  and  (max-width : 1280px){
    font-size: 10px;
    line-height: 18px;
    width: 8rem;
    text-align: left;
  }
`;




export const Company = styled.div`
  font-weight: 500px;
  font-size: 15px;
  color: #525356;
  @media only screen and (max-device-width: 767px) {
    font-size: 10px;
  }
`;

export const BoldText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  @media only screen and (max-device-width: 767px) {
    font-size: 9px;
  }
`;

export const NameAdd = styled(Flex)`
  /* font-weight: 700px;
  font-size: 18px; */
  gap: 3px;
  padding-left: 15px;
  @media only screen and (max-device-width: 767px) {
    gap: 0px;
    padding-left: 5px;
  }

`;

export const FooterFlex = styled(Flex)`
  gap: 20px;
`;
export const Name1 = styled.div`
  font-weight: 600;
  font-size: 16px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  color: #343e59;

`;
export const PopHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding-top: 15px;
`;
export const PopSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808697;
  padding-top: 5px;
`;

export const SearchLInput = styled(Input)
` 
  ::placeholder{

    width: 151px;
height: 24px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: rgba(13, 13, 13, 1);
}
  
  width: 360px;
  height: 67px;
  border-radius: 0 15px 15px 0;
  border: 2px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(226, 226, 226, 1);
  margin-top: -8%;
  margin-left: 29%;
  position:absolute;
    font-size: 16px;
    // clip-path: polygon(87% 0%, 0% 0%, 0% 100%, 87% 100%);
    background-image: url('../Joblisting/images/search.png'); 
    background-position: 15px 24px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;



  @media only screen and (max-device-width: 768px) {
    width: 290px !important;
    height: 42px;
    margin-top: -55%;
    margin-left: -36.5%;
    position: relative;
    border-radius: 15px 15px 15px 15px;


    ::placeholder{
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 12px;
font-weight: 400;
line-height: 13px;
letter-spacing: 0em;
text-align: left;
// width: 41px;
// height: 23px;

    }
  

  `
  
;

export const SearchInput = styled(Input)
// `
  // ::placeholder {
  //   font-size: 16px;
  //   width: 51px;
  //   height: 16px;
  //   // font-family: Plus Jakarta Sans;
  //   font-size: 15px;
  //   font-weight: 400;
  //   line-height: 16px;
  //   letter-spacing: 0em;
  //   text-align: left;
  
  // }
  // width: ${(props) => (props.width ? props.width : "250px")} !important;
  // height: ${(props) => (props.height ? props.height : "40px")};
  // border-radius: 8px;
  // box-shadow: none;
  // border-color: ${(props) => (props.error ? "red" : "#e9e9e9")};
  // :focus {
  //   border-color: #e9e9e9;
  //   box-shadow: none;
  // }
  // :hover {
  //   border-color: #e9e9e9;
  // }
  // :not(.ant-input-affix-wrapper-disabled):hover {
  //   border-color: #e9e9e9 !important;
  // }
  // .ant-input-affix-wrapper-focused {
  //   box-shadow: none;
  //   border-right-width: 0px !important;
  // }
  // @media only screen and (max-device-width: 767px) {
  //   width: 200px !important;
  // }`

  ` 
  ::placeholder{

    width: 51px;
height: 16px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: rgba(13, 13, 13, 1);
}
  
  width: 360px;
  height: 67px;
  border-radius: 15px 0 0 15px;
  border: 2px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(226, 226, 226, 1);
  margin-top: -8%;
  position: absolute;
  margin-left: -3.11%;
    font-size: 16px;
    // clip-path: polygon(87% 0%, 0% 0%, 0% 100%, 87% 100%);
    background-image: url('../Joblisting/images/search.png'); 
    background-position: 15px 24px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;

  @media only screen and (max-device-width: 768px) {
    width: 290px !important;
    height: 42px;
    margin-top: -62%;
    margin-left: 7%;
    position: relative;
    border-radius: 15px 15px 15px 15px;


    ::placeholder{
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 12px;
font-weight: 400;
line-height: 13px;
letter-spacing: 0em;
text-align: left;
// position:relative;
// width: 41px;
// height: 23px;

    }

  }

  `
  
;

export const SearchSInput = styled(Input)

  ` 
  ::placeholder{

    width: 51px;
height: 16px;
font-family: 'Plus Jakarta Sans', sans-serif;
font-size: 15px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: rgba(13, 13, 13, 1);
}
  
  width: 35%;
  height: 67px;
  border-radius: 15px 0 0 15px;
  border: 2px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(226, 226, 226, 1);
  margin-top: -8%;
  position: absolute;
  margin-left: -3.11%;
    font-size: 16px;
    // clip-path: polygon(87% 0%, 0% 0%, 0% 100%, 87% 100%);
    background-image: url('../Joblisting/images/search.png'); 
    background-position: 15px 24px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;

  @media only screen and (max-device-width: 767px) {
    width: 290px !important;
    height: 42px;
    margin-top: -10%;
    margin-left: 7%;
    position: relative;
    left: -20px;
    border-radius: 15px 15px 15px 15px;


    ::placeholder{
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 12px;
font-weight: 400;
line-height: 13px;
letter-spacing: 0em;
text-align: left;
// position:relative;
// width: 41px;
// height: 23px;

    }

  }

  `
  
;

export const SearchsInput = styled(Input)
`
  ::placeholder {
    font-size: 16px;
    width: 51px;
    height: 16px;
    // font-family: Plus Jakarta Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  
  }
  width: ${(props) => (props.width ? props.width : "250px")} !important;
  height: ${(props) => (props.height ? props.height : "40px")};
  border-radius: 8px;
  box-shadow: none;
  border-color: ${(props) => (props.error ? "red" : "#e9e9e9")};
  :focus {
    border-color: #e9e9e9;
    box-shadow: none;
  }
  :hover {
    border-color: #e9e9e9;
  }
  :not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e9e9e9 !important;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-right-width: 0px !important;
  }
  @media only screen and (max-device-width: 767px) {
    width: 200px !important;
  }`


  
;


export const StyledCheckbox = styled(Checkbox)`
  padding-top: 10px;
  .ant-checkbox + span {
    padding-left: 12px;
  }
  .ant-checkbox-group-item {
    width: 100%;
    padding-top: 10px;
  }
  .ant-checkbox-wrapper {
    padding: 5px !important;
  }
`;
export const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: block;
  .ant-checkbox-group-item {
    width: 90%;
    padding-top: 15px;
  }
`;
export const FilterChipPanel = styled.div`
  padding: 5px 0px 10px 30px;
`;

export const StyledTag = styled(Tag)`
  line-height: 30px;
  background: #f2f3f5;
  border: 1px solid #f2f3f5;
  font-weight: 600;
`;

export const RecentHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  padding: 10px 25px;
`;

export const SpinWrapper = styled(Flex)`
  padding-top: 25px;
`;

export const ViewContent = styled(Flex)`
@media only screen and (max-device-width: 767px) {
display:block;

}
`
