import React from "react";
import { Form, Input, Select } from "antd";
import "./stylephone.css";

const PhoneNum = ({ value, onChange }) => {
  const handlePhoneChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    onChange(inputValue);
  };

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.reject("Please input your phone number!");
    }
    if (value.length !== 10) {
      return Promise.reject(
        "Your phone number should contain exactly 10 numbers"
      );
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      name="phone"
      validateFirst
      rules={[
        {
          validator: validatePhoneNumber,
        },
      ]}
    >
      <Input
        addonBefore={
          <Form.Item name="prefix" noStyle>
            <Select className="phoselc" defaultValue="91">
              <Select.Option value="91">+91</Select.Option>
            </Select>
          </Form.Item>
        }
        value={value}
        placeholder="Enter phone number"
        onChange={handlePhoneChange}
        onKeyDown={(e) => {
          if (
            !(e.key === "Backspace" || e.key === "Delete") &&
            isNaN(Number(e.key))
          ) {
            e.preventDefault();
          }
        }}
        className="phonselc"
      />
    </Form.Item>
  );
};

export default PhoneNum;
