import React from 'react';
import { Form, Input } from 'antd';
import './stylelocation.css'

const Location = ({ value, onChange }) => {
  const handleLocationChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  return (
      <Form.Item
        name={["location"]}
        rules={[
          {
            required: true,
            message: 'Please enter the location',
          },
        ]}
      >
        <Input
          value={value}
          placeholder="Enter location"
          onChange={handleLocationChange}
          className="locsize"
        />
      </Form.Item>
  );
};

export default Location;
