import React from 'react';
import { Container, Row, Col,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylepas.css';
import security from '../../Assets/images/privacysecuritybg.png';
import Securitymob from '../../Assets/images/privacysecuritybgmob.png';
import { useNavigate } from 'react-router-dom';
import Signupfooter from '../SignupFooter/signupfooter';

import Innovation from '../../Assets/images/innovationicon.png';
import Collaboration from '../../Assets/images/collaborationicon.png';
import Diversity from '../../Assets/images/diversityicon.png';
import Analytics from '../../Assets/images/analytics.png';
import Rightperson from '../../Assets/images/rightpersonjobicon.png';

const PrivacyandSecurity = () =>{

  const navigate = useNavigate();

    return(
        <>  
        <Container className='privacysecuritypagemain'>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <div className="prisec d-flex">
            <img src={security} alt="terms" className="privecimg"/>
            <img src={Securitymob} alt='Privacy and Security' className='privecimgmob'/>
            <span className='prisecimg d-flex justify-content-center align-items-center'></span>
          </div>
        </Col>

        <Col xs={12} sm={12} md={12}>
            <h1 className='prisectext1  d-flex justify-content-center align-items-center'>Privacy & Security</h1>
            <p className='prisectext2'>At PluginLive, we prioritize the protection of your personal information and data security. Learn more about our commitment to safeguarding your privacy and maintaining the highest standards of security across our platform. <br></br><br></br>Explore our privacy policies, security measures, and protocols to understand how we keep your information safe and secure.</p>
            {/* <p className='prisectextm2   d-sm-none'>At PluginLive, we prioritize the protection of your personal information and data security. Learn more about our commitment to safeguarding your privacy and maintaining the highest standards of security across our platform. <br></br><br></br> Explore our privacy policies, security measures, and protocols to understand how we keep your information safe and secure.</p> */}
        </Col>

        <Col xs={12} sm={12} md={12}>
          <h1 className='datatitlepas'>Data privacy</h1>
          <p className='dataparapas'>On the path to define the best talent acquisition platform, the proposition is built to ensure & respect the privacy of every user and all data on the platform. We maintain global standards with respect to Data Privacy guidelines protecting our users.</p>
        </Col>

        <Col xs={12} sm={12} md={12}>
          <h1 className='datatitlepas'>Security protocols</h1>
          <p className='dataparapas'>We ensure all information shared with PluginLive is stored in such a way to make the data encrypted, controlled and inaccessible to any external unauthorized user. Using the advanced security protocols, we ensure PluginLive is a secure platform to find talent for business growth and expansion.</p>
        </Col>

        <Col xs={12} sm={12} md={12}>
          <h1 className='datatitlepas'>Cybersecurity</h1>
          <p className='dataparapas'>PluginLive provides regulations linked to cybersecurity and provides Data Privacy by Design, that sets the pace to build and maintain an efficient and legally-compliant digital infrastructure.</p>
        </Col>
        
        <Col xs={12} sm={12} md={12}>
          <h1 className='datatitlepas'>Underlying data access control </h1>
          <p className='dataparapas'>Permissions on locations in the Cloud. Data access permissions enable principals to read and write data to underlying cloud locations. Data location permissions enable principals to create metadata databases and tables that point to specific cloud locations.
</p>
        </Col>




        <Col xs={12} sm={12} md={12}>
          <span className='prisectitle1 d-flex justify-content-center align-items-center'><span className='sectext'>Discover What Drives Us</span></span><br></br>
          <h1 className='prisectitle2 d-flex justify-content-center align-items-center'>Our Core Values</h1><br></br>
          <p className='prisectitle3'>Explore the principles that guide us in everything we do. From integrity to innovation, discover how our core values shape our organization's culture and drive our success.</p>
          {/* <p className='prisectitlem3   d-sm-none'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p> */}
        </Col>


       

        

        <Col xs={12} sm={12} md={12}>
        <span className='prisecval1 d-flex justify-content-left align-items-center'>

          <span className='valcirc d-flex justify-content-center align-items-center'>
              <img src={Innovation} alt='Innovation' className='iconimg'/>
          </span>
            </span><br></br>
          <h1 className='prisecvalu1 d-flex justify-content-center align-items-center'>Innovation</h1><br></br>
          <p className='prisecvalue1 '>Embracing innovation to revolutionize the (campus + lateral ) hiring process, leveraging automation and technology to create efficient and effective solutions.</p>
          {/* <p className='prisecvaluem1  d-sm-none'>Embracing innovation to revolutionize the (campus + lateral ) hiring process, leveraging automation and technology to create efficient and effective solutions.</p> */}
        </Col>

        <Col xs={12} sm={12} md={12}>
        <span className='prisecval1 d-flex justify-content-left align-items-center'>
          <span className='valcirc d-flex justify-content-center align-items-center'>
              <img src={Collaboration} alt='Collaboration' className='iconimg'/>  
          </span></span><br></br>
          <h1 className='prisecvalu1 d-flex justify-content-center align-items-center'>Collaboration</h1><br></br>
          <p className='prisecvalue1'>Fostering collaboration among universities, employers, and students, recognizing that teamwork and partnership are essential for success in campus hiring.</p>
          {/* <p className='prisecvaluem1  d-sm-none'>Fostering collaboration among universities, employers, and students, recognizing that teamwork and partnership are essential for success in campus hiring.</p> */}
        </Col>

        <Col xs={12} sm={12} md={12}>
        <span className='prisecval1 d-flex justify-content-left align-items-center'>
          <span className='valcirc d-flex justify-content-center align-items-center'>
              <img src={Diversity} alt='Diversity & Inclusion' className='iconimg'/>  
          </span></span><br></br>
          <h1 className='prisecvalu1 d-flex justify-content-center align-items-center'>Diversity and Inclusion</h1><br></br>
          <p className='prisecvalue1'>Championing diversity and inclusion in campus recruitment, striving to create equal opportunities for all students and promoting a culture of belonging.</p>
          {/* <p className='prisecvaluem1  d-sm-none'>Championing diversity and inclusion in campus recruitment, striving to create equal opportunities for all students and promoting a culture of belonging.</p> */}
        </Col>

        <Col xs={12} sm={12} md={12}>
        <span className='prisecval1 d-flex justify-content-left align-items-center'>
          <span className='valcirc d-flex justify-content-center align-items-center'>
              <img src={Analytics} alt='Analytics' className='iconimg'/>  
          </span></span><br></br>
          <h1 className='prisecvalu1 d-flex justify-content-center align-items-center'>Analytics</h1><br></br>
          <p className='prisecvalue1'>Harnessing data insights to optimize hiring processes and decision-making, driving efficiency and effectiveness in talent acquisition strategies.</p>
          {/* <p className='prisecvaluem1  d-sm-none'>Harnessing data insights to optimize hiring processes and decision-making, driving efficiency and effectiveness in talent acquisition strategies.</p> */}
        </Col>

        <Col xs={12} sm={12} md={12}>
        <span className='prisecval1 d-flex justify-content-left align-items-center'>
          <span className='valcirc d-flex justify-content-center align-items-center'>
              <img src={Rightperson} alt="Right person in the right job" className='iconimg'/>  
          </span></span><br></br>
          <h1 className='prisecvalu1 d-flex justify-content-center align-items-center'>Right person in the right job</h1><br></br>
          <p className='prisecvalue1'>Ensuring alignment between individuals' skills, competencies, and job roles to maximize job satisfaction, productivity, and organizational success.</p>
          {/* <p className='prisecvaluem1  d-sm-none'>Ensuring alignment between individuals' skills, competencies, and job roles to maximize job satisfaction, productivity, and organizational success.</p> */}
        </Col>

        {/* <Col xs={12} sm={12} md={12}>
        <span className='prisecval2 d-flex justify-content-left align-items-center'><span className='valucirc'></span></span><br></br>
          <h1 className='prisecvalu2 d-flex justify-content-center align-items-center'>Value 02</h1><br></br>
          <p className='prisecvalue2 d-none d-lg-block'>Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.</p>
          <p className='prisecvaluem2  d-sm-none'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>
        </Col>

        <Col xs={12} sm={12} md={12}>
        <span className='prisecval3 d-flex justify-content-left align-items-center'><span className='valuecirc'></span></span><br></br>
          <h1 className='prisecvalu3 d-flex justify-content-center align-items-center'>Value 03</h1><br></br>
          <p className='prisecvalue3 d-none d-lg-block'>Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.</p><br></br><br></br><br></br>
          <p className='prisecvaluem3  d-sm-none'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>
        </Col> */}

        <Signupfooter/>

        {/* <Col xs={12} sm={12} md={10} className='termstarted  d-none d-lg-block'>
          <div className='termstartedbox d-flex'>
          <h1 className='termstarthead'>Ready to join the success stories?</h1><br></br></div>
          
          <button className='termsubbutton d-flex' onClick={()=>navigate("/Student_Registration")}><p className='termregis'>Sign up now </p></button>
            <p className='termstartpara d-none d-lg-block'>Start your journey with us today and unlock endless opportunities for your career growth. </p>
        </Col>

        <Col xs={12} sm={12} md={10} className='prisecstartedm  d-sm-none'>
            <div className='prisecstartedboxm d-flex'>
              <h1 className='prisecstartheadm'>Ready to join the success stories?</h1><br></br></div>
            <button className='prisecsubbuttonm d-flex' onClick={()=>navigate("/Student_Registration")}><p className='prisecregism'>Sign up now </p></button>
            <p className='prisecstartparam'>Start your journey with us today and unlock endless opportunities for your career growth. </p>
          </Col> */}
      
      
      
      </Row>
      <br></br><br></br><br></br><br></br><br className='lapbreak'></br>
    </Container>
        </>
    );
}

export default PrivacyandSecurity