import React,{ useRef, useState, useEffect } from 'react';
import { Container, Row, Col,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylep.css';
import partners from './images/partners.png';
import play from './images/playbutton.png';
import brand1 from './images/brand.png';
import access1 from './images/access.png';
import automation1 from './images/automation.png';
import hire1 from './images/hire.png';
import adobe from './images/adobe.png';
import adidas from './images/adidas.png';
import burger from './images/burgerking.png';
import fedex from './images/fedex.png';
import kfc from './images/kfc.png';
import uptime from './images/uptime.png';
// import tablet from './images/tablet.png';
// import arrow from './images/arrow.png';
import pp1 from './images/ppl1.png';
import pp2 from './images/ppl2.png';
import pp3 from './images/ppl4.png';
import whitea from './images/white circle arrow.png';


import Client from '../../Assets/company/client.png';
import Cuemath from '../../Assets/company/cuemath.png';
import Rbl from '../../Assets/company/rblbank.png';
import Rb from '../../Assets/company/relevance_broking_services_logo.png';
import Trigyn from '../../Assets/company/trigyn.png';
import Delhivery from '../../Assets/company/delhivery.png';
import Lic from '../../Assets/company/lic.png';
import Kotak from '../../Assets/company/kotak.png';
import Lloyds from '../../Assets/company/lloyds.png';
import Corpcare from '../../Assets/company/corpcare.png';
import Ak from '../../Assets/company/ak.png';
import Dabur from '../../Assets/company/Dabur-Logo.png';
import Coreintegra from '../../Assets/company/coreintegra.png';
import Lighthouse from '../../Assets/company/lighthouse.png';
import Austin from '../../Assets/company/Austin-Logo-scaled.png';
import Hdfc from '../../Assets/company/hdfc.png';
import Nse from '../../Assets/company/nse.png';
import Indusland from '../../Assets/company/indusland.png';


import Pes from '../../Assets/institution/pesuniversity.jpg';
import Ifim from '../../Assets/institution/ifiminstitution.jpeg';
import Holymary from '../../Assets/institution/holy-mary-.png';
import Ramanujam from '../../Assets/institution/ramanu-logo.96e81bbdf814c655f1ab.png';
import Motilal from '../../Assets/institution/Logo_of_Motilal_Nehru_College.05914d823d3c4c9a1992.png';
import Avcol from '../../Assets/institution/avcol.jpg';
import Joseph from '../../Assets/institution/stjoseph.jpg';
import Vishwa from '../../Assets/institution/vishwa.png';
import Sardar from '../../Assets/institution/sardaepatel.png';
import Guru from '../../Assets/institution/gurunanak.png';
import Vijaybhoomi from '../../Assets/institution/vijaybhoomi.jpg';
import Jeppiar from '../../Assets/institution/jeppiaar.png';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useNavigate } from 'react-router-dom';
import Hover1 from '../../Assets/images/hover1.png';
import Hover2 from '../../Assets/images/hover2.png';
import Hover3 from '../../Assets/images/hover3.png';
import Clickcore from '../../Assets/images/clickincore.png';
// import Aos from 'aos';
// import 'aos/dist/aos.css';
import Signupfooter from '../SignupFooter/signupfooter';
import { useLocation } from 'react-router-dom';
import LogoComp from '../LogoComponent/logocomp';

import TrainingpartnerContainer from '../Corporate/TrainingpartnerContainer';
import Section from '../Mainsection/index';
import Unlockindex from "../UnlockComponent/unlockindex";

export default function Partner(){

  const logoCompRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const { state } = location;
    const scrollToLogo = state && state.scrollToLogo;
    console.log("useEffect triggered with scrollToLogo:", scrollToLogo);

    if (scrollToLogo) {
      console.log('Scrolling to logoComp');
      if (logoCompRef.current) {
        logoCompRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const container2 = document.querySelector('.imgscrollupmob');
  //   const clone2 = container2.cloneNode(true);
  //   container2.parentNode.appendChild(clone2);

  //   function handleAnimationEndexp() {
  //     container2.parentNode.removeChild(container2);
  //   }

  //   container2.addEventListener('animationend', handleAnimationEndexp);
  //   return () => {
  //     container2.removeEventListener('animationend', handleAnimationEndexp);
  //   };
  // }, []);

  // useEffect(() => {
  //   const container2 = document.querySelector('.imgscrolluppat');
  //   const clone2 = container2.cloneNode(true);
  //   container2.parentNode.appendChild(clone2);

  //   function handleAnimationEndexp() {
  //     container2.parentNode.removeChild(container2);
  //   }

  //   container2.addEventListener('animationend', handleAnimationEndexp);
  //   return () => {
  //     container2.removeEventListener('animationend', handleAnimationEndexp);
  //   };
  // }, []);


  const [text] = useTypewriter({
    words: ['Embark on a transformative journey', 'Automation of campus placements', 'Trusted by campuses nationwide'],
    loop: {},
    typeSpeed: 150,
    deleteSpeed: 150,
    delaySpeed: 800,
});

const [isDancing, setIsDancing] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    // Check the scroll position
    const scrollPosition = window.scrollY;
    
    // You can adjust the threshold as needed
    if (scrollPosition > 200) {
      setIsDancing(true);
    } else {
      setIsDancing(false);
    }
  };

  // Add event listener for scroll
  window.addEventListener('scroll', handleScroll);

  // Remove event listener on component unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Show the div with delay when the component mounts
  const timer = setTimeout(() => {
    setIsVisible(true);
  }, 500); // Adjust the delay time as needed

  // Clear the timer on component unmount
  return () => clearTimeout(timer);
}, []);

const handleAnimationEnd = () => {
  // Perform shake animation when the ease-in animation ends
  document.querySelector('.dancecol-divv').classList.add('shake');
};

/* box click start */
const [activeFeature, setActiveFeature] = useState(null);
const containerRefs = [useRef(), useRef(), useRef(), useRef()];

useEffect(() => {
  const handleOutsideClick = (event) => {
    if (activeFeature !== null) {
      const activeContainerRef = containerRefs[activeFeature];
      if (activeContainerRef.current && !activeContainerRef.current.contains(event.target)) {
        setActiveFeature(null);
      }
    }
  };

  document.addEventListener('mousedown', handleOutsideClick);

  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };
}, [activeFeature, containerRefs]);

const toggleDiv = (index) => {
  setActiveFeature((prevActiveFeature) => (index === prevActiveFeature ? null : index));
};

/* box click end */

const handleClick = () => {
  window.location.href = '/Student_Registration';
  window.scrollTo(0, 0);
};


    const [isScrolling, setIsScrolling] = useState(false);
  const containerRef = useRef(null);

  const paragraphContent = `
  <p class="mobpatwidthc">Aspiring Talent,Embark on a transformative journey. Unleash your untapped potential.Beyond mere reputation - it's your unique capabilities that matter.</p>
  <div class="prtwidth1">Aspiring Talent,Embark on a transformative journey.</div>
  <div class="prtwidth2">Unleash your untapped potential.</div>
  <div class="prtwidth3">Beyond mere reputation - it's your unique capabilities that matter.</div>
`;

const headers = ['Industry Connections', 'Job Opportunities ', 'Collaborate on Curated Programs', 'Extend Your Reach'];

const subheaders=['Access insights and opportunities through our extensive industry network. Gain mentorship and career guidance directly from professionals in your field.','Discover tailored career options from internships to executive roles in our comprehensive job database.','Partner with us to develop tailored workshops and skill-building sessions, preparing individuals for success in the job market.','Broaden your career horizons, whether you are a student seeking internships or an experienced professional looking for new challenges.'];

    const words = ['renown', 'refinement', 'reputation'];
    return(
        <>  
        <Container className='partnerpagemain'>
      {/* <Row>
        <Col xs={12} sm={12} md={6}>
        <div className="contentt3">
          

            <p>Aspiring Talent,<br className='lapbreak'></br>
Embark on a transformative journey.
Unleash your untapped potential.
Beyond mere reputation - it's your unique capabilities that matter.
</p>  

          </div>
          
        </Col><br></br>
        <Col xs={12} sm={12} md={6} className='partimgmov'>
            <div className="ima-container1 d-flex">
                <div className="background-container3"></div> 
                <span className='orgvector3 d-flex justify-content-center align-items-center'>
                 <img src={partners} alt="fresher" className="img-fluid" />
                </span>
            </div>

            <div className={`danceprt-divv ${isVisible ? 'visible' : ''}`} >

            <div className={`danceprt-div ${isDancing ? 'dancing' : ''}`}>        

          <div className='ima-container2'> 
          <span className='violetbox3 d-flex '><p className='vt1pat mt-4 ms-3'>100+<br/></p><p className='vt2pat'>Corporates</p></span>
          </div>  

            <div className='ima-container3'>
            <div className='linebox3 d-flex'>
              <div className='hlinebox1pat d-flex'>
                <div className='hlinebox2pat d-flex'>
                </div>
              </div>

              <div className='rlinebox1pat d-flex'>
                <div className='rlinebox2pat d-flex'>
                </div>
              </div>
              <p className='lineboxtext'>34k<br/><p className='lineboxtext2'>Colleges</p></p>
            </div>         
            
            </div>

          <div className='ima-container5 d-flex'>
          <span className='yellowbox3pat'><p className='yt1pat'>100k<br/></p>
              <p className='yt2pat'>Students</p></span>
          </div>
          
          </div>
          </div>

          <div className='ima-container4 d-flex'>
            <span className='bluec3'><img className='whiteatp ms-3 mt-2' src={whitea} alt='white arrow'/></span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={6} md={6} className='firstsignup3'>
        <button className='signinpatcol' onClick={handleClick}><p className='siginspat mt-2'>Sign up now! </p></button>
         </Col>

        <Col xs={6} sm={6} md={6}className='firstsignup3'>
        <Button variant="outline-primary" className='watchpat'><p className='watchs3'>Know more</p></Button>
        </Col>

        <Col xs={12} sm={12} md={12} className='firstsignup13'>
        <p className='linetextprt mt-5'>Moving away from the complexities of talent acquisition,<br className='lapbreak'></br> let's embrace personalized solutions. Stay seamlessly connected, streamline applications, and effortlessly discover exceptional talent.<br/></p><br></br>
        <p className='linetxtprt mt-5'>Together, let's realize your training aspirations and transform them into remarkable achievements!</p>
        </Col>

        </Row> */}

        <Section paragraph={<div dangerouslySetInnerHTML={{ __html: paragraphContent }} />} words={words} imageSrc={partners} bgpartClassName='bgpart4' profileType='partner'/>

        <div className="Fmaincontent">
        Moving away from the complexities of talent acquisition,
let's embrace personalized solutions. Stay seamlessly connected, streamline applications, and effortlessly discover exceptional talent.

<br></br><br></br>
Together, let's realize your training aspirations and transform them into remarkable achievements!
        </div> 

        
        {/* <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br> */}
        <Row>
          <Col xs={12} sm={12} md={12} className='sechead3'>

          {/* <span className='title1tagpat d-flex justify-content-center align-items-center'><span className='ttextpat'>Explore</span></span> */}
          <h1 className='title2tagpat d-flex justify-content-center align-items-center'>Unlock Your Potential</h1>
          <p className='title2tagpat2 d-flex justify-content-center align-items-center'>Explore our solutions for your enhanced job search experience</p><br/>
          </Col>
          
          <Unlockindex headers={headers} subheaders={subheaders}/>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12}  className='corevalues'>
                <div className='brandpat'>
                <p className='brandhpat' style={{color:"rgba(255, 255, 255, 1)"}}>Industry Connections</p>
                        {/* <!-- <input type="text" class="form-control form-control-lg" placeholder="Text Box 1"> --> */}
                        <p className='brandpppat'> Access insights and opportunities through our extensive industry network. Gain mentorship and career guidance directly from professionals in your field.</p> 
                </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevalues'>
            <div className='hirepat'>
            <p className='hirehpat' style={{color:"rgba(255, 255, 255, 1)"}}>Extend Your Reach</p>
                        <p className=' hirepppat'>Broaden your career horizons, whether you're a student seeking internships or an experienced professional looking for new challenges.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevalues'>
            <div className='digitalpat'>
            <p className='digitalhpat' style={{color:"rgba(255, 255, 255, 1)"}}>Collaborate on Curated Programs</p>
                        <p className='digitalpppat'>Partner with us to develop tailored workshops and skill-building sessions, preparing individuals for success in the job market.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevalues'>
            <div className='trainpat'>
            <p className='trainhpat' style={{color:"rgba(255, 255, 255, 1)"}}>Job Opportunities</p>
                        <p className='trainpppat'>Discover tailored career options from internships to executive roles in our comprehensive job database.</p>      
            </div>
          </Col>
        </Row>

        {/* <Row className='d-flex justify-content-center'>
        <Col xs={12} sm={12} md={12}>
          <span className='title1tagpatn d-flex justify-content-center align-items-center mt-5'><span className='ttextpat'>Vetted by Industry Leaders</span></span><br></br>
          <p className='title2tagpat2g d-flex justify-content-center align-items-center'>Our Partnerships with Seasoned Veterans</p><br/>
          <h1 className='title2tagpat d-flex justify-content-center align-items-center'>Corporate</h1><br></br>
          </Col>

          <div className='logosprt'>
          <div className='logo-slidetp'>
            <img className='client' src={Client} alt='client'/>
            <img className='cuemath' src={Cuemath} alt='Cuemath'/>
            <img className='Rbl' src={Rbl} alt='Rbl'/>
            <img className='Rb' src={Rb} alt='Rb'/>
            <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
            <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
            <img className='Lic' src={Lic} alt='Lic'/>
            <img className='Kotak' src={Kotak} alt='Kotak'/>
            <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
            <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
            <img className='Ak' src={Ak} alt='Ak'/>
            <img className='Dabur' src={Dabur} alt='Dabur'/>
            <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
            <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
            <img className='Austin' src={Austin} alt='Austin'/>
            <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
            <img className='Nse' src={Nse} alt='Nse'/>
            <img className='Indusland' src={Indusland} alt='Indusland'/>
          </div>

          <div className='logo-slidetp'>
            <img className='client' src={Client} alt='client'/>
            <img className='cuemath' src={Cuemath} alt='Cuemath'/>
            <img className='Rbl' src={Rbl} alt='Rbl'/>
            <img className='Rb' src={Rb} alt='Rb'/>
            <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
            <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
            <img className='Lic' src={Lic} alt='Lic'/>
            <img className='Kotak' src={Kotak} alt='Kotak'/>
            <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
            <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
            <img className='Ak' src={Ak} alt='Ak'/>
            <img className='Dabur' src={Dabur} alt='Dabur'/>
            <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
            <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
            <img className='Austin' src={Austin} alt='Austin'/>
            <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
            <img className='Nse' src={Nse} alt='Nse'/>
            <img className='Indusland' src={Indusland} alt='Indusland'/>
          </div>
        </div>


        <Col xs={12} sm={12} md={12}>
          <h1 className='title2tagpat d-flex justify-content-center align-items-center mt-5'>Institutes</h1><br></br>
        </Col>


        <div className='logossprt'>
          <div className='logos-slidept'>
            <img className='instlogo' src={Pes} alt='pes'/>
            <img className='instlogo' src={Ifim} alt='Ifim'/>
            <img className='instlogo' src={Holymary} alt='Holymary'/>
            <img className='instlogo' src={Ramanujam} alt='Ramanujam'/>
            <img className='instlogo' src={Motilal} alt='Motilal'/>
            <img className='instlogo' src={Avcol} alt='Avcol'/>
            <img className='instlogo' src={Joseph} alt='Joseph'/>
            <img className='instlogo' src={Vishwa} alt='Vishwa'/>
            <img className='instlogo' src={Sardar} alt='Sardar'/>
            <img className='instlogo' src={Guru} alt='Guru'/>
            <img className='instlogo' src={Vijaybhoomi} alt='Vijaybhoomi'/>
          </div>

          <div className='logos-slidept'>
          <img className='instlogo' src={Pes} alt='pes'/>
            <img className='instlogo' src={Ifim} alt='Ifim'/>
            <img className='instlogo' src={Holymary} alt='Holymary'/>
            <img className='instlogo' src={Ramanujam} alt='Ramanujam'/>
            <img className='instlogo' src={Motilal} alt='Motilal'/>
            <img className='instlogo' src={Avcol} alt='Avcol'/>
            <img className='instlogo' src={Joseph} alt='Joseph'/>
            <img className='instlogo' src={Vishwa} alt='Vishwa'/>
            <img className='instlogo' src={Sardar} alt='Sardar'/>
            <img className='instlogo' src={Guru} alt='Guru'/>
            <img className='instlogo' src={Vijaybhoomi} alt='Vijaybhoomi'/>
          </div>
        </div>

    
        </Row> */}

<div ref={logoCompRef}>
        <LogoComp />
        </div>
        <br></br><br></br><br></br>

        <Row className='partsbox '>
          {/* <Col xs={12} sm={12} md={12}>
            <div className='parttitle'>
            <h1 className='partheadtitle'>Training Partners</h1>



            <div className='containercorptrainbox'>
            <div className='corptrainbox'>
               <div className='imgscrollupmob'>


<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>


<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
            </div>
                

            </div>
            </div>


              <p className='par1'>Gear up for success with exclusive skill development programs. Elevate your expertise and get ready to conquer the professional world with us by your side! </p>


              <button className='reg'><p className='registert' style={{color:" rgba(255, 255, 255, 1)",fontFamily:"'Plus Jakarta Sans', sans-serif"}}>Contact</p> </button>
            </div>
          </Col> */}
        </Row>


        {/* <Row className='phonebox'>
        <Col xs={12} sm={12} md={7}>
        <div className='phonetitles'>
        <span className='phonetitle1tag d-flex justify-content-center align-items-center'><span className='ttext'>Title Header</span></span><br></br>  
        <h1 className='phoneheadtitle'>Title comes here!</h1>
          </div>  
        </Col><br></br>

        <Col xs={12} sm={12} md={6} className=' d-none d-lg-block'>
        <p className='phonebody'>Building your career entails
strategically developing personal
and professional skills through
intentional actions and continuous
learning for growth and success.</p>
        </Col>

        <Col xs={12} sm={12} md={5} className=' d-none d-lg-block'>
          <img className='arrow' src={arrow} alt='arrow'/>
        </Col>


        <Col xs={12} sm={12} md={6}>
          <div className="image-phone-container">


            <div className='orgcircle'></div>
            <div className='viocircle'></div>

            
            
          <img className='phone' src={tablet} alt='phone'/>
          <div className='bluecircle'></div>
          <div className='yellowcircle'></div>
          </div>
        </Col>




        <Col xs={12} sm={12} md={6} className='d-sm-none'>
        <p className='phonebody'>Building your career entails
strategically developing personal
and professional skills through
intentional actions and continuous
learning for growth and success.</p>
        </Col>
      </Row>


      <Row>
        <Col xs={12} sm={12} md={6}>
        <div className='traincircle'></div>
        <div className='trainingtitles'>
        <span className='traintitle1tag d-flex justify-content-center align-items-center'><span className=''>Title Header</span></span><br></br>  
        <h1 className='trainheadtitle'>Training Partners</h1>
          </div>
          
          <p class="head12">Building your career is about strategically developing your personal and professional skills through deliberate actions and continuous learning.</p>

          <p class="head13">It's a journey of intentional choices that contribute to your growth and success in the professional realm.</p>
                
          <button className='register'><p className='register1t' style={{color:" rgba(255, 255, 255, 1)",fontFamily:"'Plus Jakarta Sans', sans-serif"}}>Register</p></button>
        </Col>

        <Col xs={12} sm={12} md={7} className=''>
        <div className='assesscircle'></div>
        <div className='assesstitles'>
        <span className='assesstitle1tag d-flex justify-content-center align-items-center'><span className=''>Title Header</span></span><br></br>  
        <h1 className='assesheadtitle'>Assessment Partners</h1>
          </div>
          
          <p class="assesshead1">Building your career is about strategically developing your personal and professional skills through deliberate actions and continuous learning.</p>

          <p class="assesshead2">It's a journey of intentional choices that contribute to your growth and success in the professional realm.</p>
                
          <button className='register2'><p className='register1t' style={{color:" rgba(255, 255, 255, 1)",fontFamily:"'Plus Jakarta Sans', sans-serif"}}>Register</p></button>
        </Col>
      </Row> */}

      
      <TrainingpartnerContainer/>

      {/* <Row className='chat'>

      <Col xs={12} sm={12} md={12}>
        <div className='chathead'>
        <span className='chatitle1tagtp d-flex justify-content-start'><span className='ctexttp d-flex justify-content-center'>Real Voices, Real Success</span></span><br></br>  
        <h1 className='chatheadtitle d-flex justify-content-start'>What are they saying about us?</h1><br></br><br></br>
        </div>
        
        
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox1'>
          <img className='chatimg1' src={pp3} alt='ppl1'/>
          <span className='chatbox11pat d-flex'>
            <p className='chatboxtext1pat'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
            </span>
            <span className='chatbox111pat'></span>          
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox2'>
          <span className='chatbox21pat d-flex'>
          <p className='chatboxtext21pat'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
          </span>
          <span className='chatbox121pat'></span>
        <img className='chatimg2pat' src={pp1} alt='burger'/>
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox3'>
          
        <span className='chatbox331 d-flex'>
          <p className='chatboxtext31'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
          </span>
          <span className='chatbox1331'></span>
          <img className='chatimg33' src={pp2} alt='burger'/>
        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox4'>
        <img className='chatimg4pat' src={pp2} alt='burger'/>
        <span className='chatbox41pat d-flex'>
            <p className='chatboxtext41pat'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
        </span>
        <span className='chatbox141pat'></span>
        </Col>

        <Col xs={12} sm={12} md={6} className='chatboxnil'>

        </Col>

        <Col xs={12} sm={12} md={6} className='chatbox5'>
        <img className='chatimg5pat' src={pp3} alt='burger'/>
        <span className='chatbox51pat d-flex'>
        <p className='chatboxtext51pat'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
        </span>
        <span className='chatbox151pat'></span>
        </Col>
      </Row> */}

      <Row className='partnerbox1'>
        {/* <Col xs={12} sm={12} md={12}>
        <div className='mainbox1'></div>
        <div className='maintitle1'>
        <span className='maintitletag1 d-flex justify-content-center align-items-center'><span className=''>Title Header</span></span><br></br>
        <h1 className='mainheadtitle1'>Training Partners</h1>
          <p className='para1'>Gear up for success with exclusive skill development programs. Elevate your expertise and get ready to conquer the professional world with us by your side! </p>

          <p className='para2'>Building your career is about strategically developing your personal and professional skills through deliberate actions and continuous learning.</p>

          <button className='register3'><p className='registert mt-2' style={{color:" rgba(255, 255, 255, 1)",fontFamily:"'Plus Jakarta Sans', sans-serif"}}>Register</p> </button>
        </div>

        <div className='imgscrollup'>

<div className='mainfedex1'>
  <img className='patmarimg' src={fedex} alt='fedex'/>
</div>
<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>

<div className='mainfedex1'>
  <img className='patmarimg' src={fedex} alt='fedex'/>
</div>
<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>


</div>

        <div className='marquee-containerpat'>
        <div className='mainimg1'>
          <div className='mainfedex1'>
            <span className='msfedex1'></span>
            <img className='mfedex1' src={fedex} alt='fedex'/>
          </div>
          <div className='mainkfc1'>
            <span className='mskfc1'></span>
            <img className='mkfc1' src={kfc} alt='kfc'/>
          </div>
          <div className='mainadidas1'>
            <span className='msadidas1'></span>
            <img className='madidas1' src={adidas} alt='adidas'/>
          </div>
          <div className='mainburger1'>
            <span className='msburger1'></span>
            <img className='mburger1' src={burger} alt='burger'/>
          </div>
          <div className='mainfedex2'>
            <span className='msfedex2'></span>
            <img className='mfedex2' src={fedex} alt='fedex'/>
          </div>
          <div className='mainkfc2'>
            <span className='mskfc2'></span>
            <img className='mkfc2' src={kfc} alt='kfc'/>
          </div>
          
        
        </div>
        </div>


        </Col> */}

        {/* <Col xs={12} sm={12} md={12}>
        <div className='patdivcontainer'>



<h1 className='patboxheader'>Training Partners</h1>

<p className='patboxheaderpara'>Collaborate with us to elevate your workforce through specialized skill development programs.<br></br>Equip your team with cutting-edge expertise tailored to your industry's needs, and together, conquer new heights in your field with our customized training solutions.</p>

<button className='patboxheaderbutton'>Contact</button>

  <div className='imgscrolluppat'>

<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>


<div className='mainkfc1'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>
<div className='mainadidas1'>
  <img className='patmarimg' src={adidas} alt='adidas'/>
</div>
<div className='mainburger1'>
  <img className='patmarimg' src={burger} alt='burger'/>
</div>  
<div className='mainkfc2'>
  <img className='patmarimg' src={kfc} alt='kfc'/>
</div>


</div>

</div>
        </Col> */}
      </Row> 

      <Signupfooter profile="training partner"/>
      
      {/* <Row>
        <Col xs={12} sm={12} md={10} className='startedpat'>
          <div className='startedboxprt d-flex'>
          <h1 className='startheadprt'>Ready to join the success stories?</h1><br></br></div>
          <p className='startparapat d-sm-none'>Start your journey with us today and unlock endless opportunities for your career growth.</p>
          
          <button className='subbuttonpat d-flex' onClick={()=>navigate("/Student_Registration")}><p className='regispt'>Sign up now</p></button>
            <p className='startparapattab'>Start your journey with us today and unlock endless opportunities for your career growth.</p>
        </Col>
        
      </Row> */}
      
      <br/><br/><br className='breakhideinlap'></br>
      {/* <br/><br/><br className='breakhideinlap'></br><br/><br/><br className='breakhideinlap'></br><br/><br/><br className='breakhideinlap'></br><br className='breakhideinlap'></br><br className='breakhideinlap'/><br className='breakhideinlap'></br><br className='breakhideinlap'/><br/><br className='breakhideinlap'></br> */}

    </Container>
        </>
    );
}
