import React, { useState } from 'react';
import './footerpagestyle.css';
import './stylef.css';
import image from '../Footer/images/plfoot.png';
import logowhite from './images/plfoot.png';
import axios from 'axios'; 
import { message } from 'antd';
import styled from "styled-components";


const StyledText = styled.span`
  text-transform: capitalize;
`;
const ErrorMessage = (description) =>
  message.error({
    content: <StyledText>{description}</StyledText>,
    className: "custom-class",
    style: {
      marginTop: "50px",
      color: "#010E30",
      borderLeft: "5px solid red",
      transitionDuration: "5s",
    },
  });

function Footerpagemain(){

    const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsValidEmail(isValidEmailFormat(value));
  };

  const isValidEmailFormat = (email) => {
    const emailRegex = /^\s*[\w.%+-]+@[a-z0-9.-]+\.[a-z]{2,}\s*$/i;
    return emailRegex.test(email);
  };

  const handleContactClick = () => {
    if (isValidEmailFormat(email)) {
      setEmail('');
      setIsValidEmail(true);
      sendEmailToCustomAddress(email);
    } else {
      setIsValidEmail(false);
    }
  };

  const sendEmail = (mailData) => {
    axios.post('https://api-auth.dev.pluginlive.com/staticnotification/email', mailData)
      .then(response => {
        console.log('Email sent successfully:', response.data);
        message.success('Thank you for your request. We will be in touch with you shortly.', 5);
      })
      .catch(error => {
        ErrorMessage(error?.response?.data?.error?.message);
        console.log('Error details:', error);
      });
  };

  const sendEmailToCustomAddress = (email) => {
    const emailData = {
      to: 'mandate@pluginlive.com',
      subject: `Trying to Contact - ${email}`,
      htmlBody: `<b>Email:</b> ${email}<br/>`,
    };
    sendEmail(emailData);
  };

    return(
        <>
            <div className='FooterContainer'>
                <div className='Footercontent'>
                    <h1 className='knowmoreclass'>Know more from us</h1>
                    <div className='Emailcontactbox'>
                        <input type='text' placeholder='Enter Email' className='Entermailinput' value={email}
                  onChange={handleInputChange}/>
                        <button className='mailbutton' onClick={handleContactClick}>Contact</button>
                        {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email</p>}
                    </div>
                    <img src={logowhite} alt='PluginLive' className='Logowhite'/>
                    <div className='Footernavs'>
                    <div className='section section1'>
                        <div className='left-half'>
                            <a href="/" className='Footernavhead'>Home</a>
                            <a href="/AboutUs" className='Footernavhead'>About us</a>
                            <a href="/joblisting" className='Footernavhead'>Job Listing</a>
                            <a href="/privacyandsecurity" className='Footernavhead'>Security</a>
                        </div>
                        <div className='right-half'>
                            <a href="/fresher" className='Footernavhead'>Fresher</a>
                            <a href="/experienced" className='Footernavhead'>Experienced</a>
                            <a href="/college" className='Footernavhead'>College</a>
                            <a href="/partner" className='Footernavhead'>Training Partner</a>
                            <a href="/corporate" className='Footernavhead'>Corporate</a>
                        </div>
                    </div>
                    <div className='section section2'>
                        {/* <a href="/faqs" className='Footernavhead'>FAQs</a> */}
                        <a href="/Terms_And_Conditions" className='Footernavhead'>Terms and Conditions</a>
                        <a href="/privacypolicy" className='Footernavhead'>Privacy Policy</a>
                    </div>
                </div>
                
                </div>
            </div>
            <div className='Footercopy'>
                Copyright © 2024 PluginLive Technologies Private Limited
            </div>
        </>
    );
}

export default Footerpagemain;