import React from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col,Card } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styleabout2.css';
// import '../AboutUs/styleabout.css';
import terms from '../../Assets/images/aboutusbg.png';
import termsmob from "../../Assets/images/aboutusbgmob.png";
import Aboutimg1 from '../../Assets/images/himanshu.png';
import Aboutimg2 from '../../Assets/images/harsha.jpg';
import Aboutimg3 from '../../Assets/images/prakash.png';
import Signin from '../AboutUs/images/signinbutton.png';
import Linkedin from '../AboutUs/images/linkedin.png';
import  imageblank from '../AboutUs//images/blank.png';
import imageblacktab from '../AboutUs/images/tabimg.png';
import { useNavigate } from 'react-router-dom';


import Innovation from '../../Assets/images/innovationicon.png';
import Collaboration from '../../Assets/images/collaborationicon.png';
import Diversity from '../../Assets/images/diversityicon.png';
import Analytics from '../../Assets/images/analytics.png';
import Rightperson from '../../Assets/images/rightpersonjobicon.png';


import Signupfooter from '../SignupFooter/signupfooter';

const Aboutparttwo = () => {

  const navigate = useNavigate();


  const data = [
    {
      // title: 'Card 1',
      content: 'Building your career entails strategically developing.',
      content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
    },
    {
      content: 'Building your career entails strategically developing.',
      content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
    },
    {
      content: 'Building your career entails strategically developing.',
      content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
    },
  ];

    return (
      <>
        <Container className='Aboutuspagemain'>
          <Row>
          <div className="auscon">
                <img src={terms} alt="terms" className="auimg" />
                <img src={termsmob} alt='terms' className='auimgmob'/>
                <span className='auconimg'></span>
              </div>


              <div className='AboutUsTitleHeader'>
                  <h1 className='Aboutusheader'>About Us</h1>
                  <p className='Aboutushedcontent'>Dive into our story and uncover the driving force behind our organization. Learn about our core values as we strive to make a meaningful impact on the physical campus and lateral placement process in our industry and beyond.</p>
              </div>

              {/* <div className='aboutustitlecont d-flex'>
                <p className='aboutustitlehead'>About Us</p>
                <p className='aboutustitleheader'>Dive into our story and uncover the driving force behind our organization. Learn about our core values as we strive to make a meaningful impact on the physical campus and lateral placement process in our industry and beyond.</p>
                
                <p className='aboutustitleheadermob'>Dive into our story and uncover the driving force behind our organization. Learn about our core values as we strive to make a meaningful impact on the physical campus and lateral placement process in our industry and beyond.</p>

                </div> */}

            {/* <Col xs={12} sm={12} md={12}>
                <div className='aboutustitlecont d-flex'>
                <p className='aboutustitlehead'>About Us</p>
                <p className='aboutustitleheader'>Dive into our story and uncover the driving force behind our organization. Learn about our core values as we strive to make a meaningful impact on the physical campus and lateral placement process in our industry and beyond.</p>
                
                <p className='aboutustitleheadermob'>Dive into our story and uncover the driving force behind our organization. Learn about our core values as we strive to make a meaningful impact on the physical campus and lateral placement process in our industry and beyond.</p>

                </div>
            </Col> */}
            </Row>

        <Row>
        {/* <Col xs={12} sm={12} md={12}>
                <span className='aboutacoreheader d-flex justify-content-center align-items-center'>Discover What Drives Us</span>
                <span className='aboutcoreheadertitle d-flex justify-content-center align-items-center'>Our Core Values</span>
                <span className='aboutcoreheaderpara d-flex justify-content-center align-items-center'>Explore the principles that guide us in everything we do. From integrity to innovation, discover how our core values shape our organization's culture and drive our success.</span>

                <span className='aboutcoreheaderparamob d-flex justify-content-center align-items-center'>Explore the principles that guide us in everything we do. From integrity to innovation, discover how our core values shape our organization's culture and drive our success.</span>

        
        </Col> */}

            

        </Row>

        <div className='Aboutuscorevaluessection'>
              <h1 className='Aboutuscorevalueheader'>Discover What Drives Us</h1>
              <h1 className='Aboutuscorevaluesubheader'>Our Core Values</h1>
              <p className='Aboutuscorevaluepara'>Explore the principles that guide us in everything we do. From integrity to innovation, discover how our core values shape our organization's culture and drive our success.</p>
            </div>

        <Row gutter={[16, 16]} className='corevaluesaboutus'>
          <Col xs={12} sm={12} md={12} lg={8}>
            <span className='aboutus2corecircle d-flex justify-content-center align-items-center'>
              <img src={Innovation} alt='Innovation' className='iconimg'/>
            </span>
            <p className='aboutus2corecirclepara d-flex justify-content-center align-items-center'>Innovation</p>

          </Col>

          <Col xs={12} sm={12} md={12} lg={8}>
            <span className='aboutus2corecircle d-flex justify-content-center align-items-center'>
              <img src={Collaboration} alt='Collaboration' className='iconimg'/>
            </span>
            <p className='aboutus2corecirclepara d-flex justify-content-center align-items-center'>Collaboration</p>
          
          </Col>

          <Col xs={12} sm={12} md={12} lg={8}>
            <span className='aboutus2corecircle d-flex justify-content-center align-items-center'>
              <img src={Diversity} alt='Diversity & Inclusion' className='iconimg'/>
            </span>
            <p className='aboutus2corecirclepara d-flex justify-content-center align-items-center'>Diversity and Inclusion</p>
          
          </Col>

          <Col xs={12} sm={12} md={12} lg={8}>
            <span className='aboutus2corecircle d-flex justify-content-center align-items-center'>
              <img src={Analytics} alt='Analytics' className='iconimg'/>
            </span>
            <p className='aboutus2corecirclepara d-flex justify-content-center align-items-center'>Analytics</p>
          
          </Col>

          <Col xs={12} sm={12} md={12} lg={8}>
            <span className='aboutus2corecircle d-flex justify-content-center align-items-center'>
              <img src={Rightperson} alt="Right person in the right job" className='iconimg'/>
            </span>
            <p className='aboutus2corecirclepara d-flex justify-content-center align-items-center'>Right person in the right job</p>
          
          </Col>

          
        </Row>

        <Row>
            <Col>
              <div className='about2pinkcircle'></div>

              {/* <span className='about2circlehead d-flex justify-content-center align-items-center'>Meet the Faces Behind Our Mission</span> */}

              <span className='about2circleheader d-flex justify-content-center align-items-center'>Our Team</span>
              <span className='about2circleheadpara d-flex justify-content-center align-items-center'>Get to know the passionate individuals who make up our team. Learn about their expertise, dedication, and commitment to delivering excellence in all aspects of our work.</span>
            </Col>
        </Row>


        <Row gutter={[8, 16]} className='aboutus2profile'>

                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <div className='aboutimage'>
            <a href='https://www.linkedin.com/in/himanshu-bhagat-9b1110b9/' target="_blank">
              <img src={Aboutimg1} alt='' className='about2imagebox1'/>

          <div className='insideabout2image1'>
              <img src={Linkedin} alt='linkedin' className='linkediniconabout21' />
              <h1 className='himanshuabout21'>Himanshu Bhagat</h1>
              
               <p className='designationabout21'>Promoter</p>
                                
          </div>
              <img src={Signin} alt='' className='signinabout21'/>
             </a> 
           </div>

                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <div className='aboutimage'>
            <a href='https://www.linkedin.com/in/harsha-saggi-46979221/' target="_blank">
              <img src={Aboutimg2} alt='' className='about2imagebox1'/>

          <div className='insideabout2image1'>
              <img src={Linkedin} alt='linkedin' className='linkediniconabout21' />
              <h1 className='himanshuabout21'>Harsha Saggi</h1>
              
               <p className='designationabout21'>Co-Founder and CEO</p>
                                
          </div>
              <img src={Signin} alt='' className='signinabout21'/>
            </a>
                    </div>

                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <div className='aboutimage'>
            <a href='https://www.linkedin.com/in/prakash-chinnadurai-6417227/' target="_blank">
              <img src={Aboutimg3} alt='' className='about2imagebox1'/>

          <div className='insideabout2image1'>
              <img src={Linkedin} alt='linkedin' className='linkediniconabout21' />
              <h1 className='himanshuabout21'>Prakash Chinnadurai</h1>
              
               <p className='designationabout21'>Co-Founder and CTO</p>
                                
          </div>
              <img src={Signin} alt='' className='signinabout21'/>
            </a>
                    </div>

                </Col>
                
                {/* <Col xs={24} sm={24} md={12} lg={8}>
                <div className='aboutimage2'>
                        <img src={Aboutimg2} alt='' className='about2imagebox2'/>
                      <div className='insideabout2image2'>

              <a href='https://www.linkedin.com/in/harsha-saggi-46979221/' target="_blank">
                <img src={Linkedin} alt='' className='linkediniconabout22'/>
                </a>

                            <h1 className='harshaabout22'>Harsha Saggi</h1>
                                <p className='designationabout22'>Co-Founder and CEO</p>
                            </div>

                  <a href='https://www.linkedin.com/in/harsha-saggi-46979221/' target="_blank">
                            <img src={Signin} alt='' className='signinabout22'/>
                            </a>

                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                <div className='aboutimage3'>
                        <img src={Aboutimg3} alt='' className='about2imagebox3'/>
                            <div className='insideabout2image3'>

              <a href='https://www.linkedin.com/in/prakash-chinnadurai-6417227/' target="_blank">
                <img src={Linkedin} alt='' className='linkediniconabout23'/>
                </a>

                            <h1 className='prakashabout23'>Prakash Chinnadurai</h1>
                                <p className='designationabout23'>Co-Founder and CTO</p>
                            </div>

                            
                  <a href='https://www.linkedin.com/in/prakash-chinnadurai-6417227/' target="_blank">
                <img src={Signin} alt='' className='signinabout23'/>
                </a>

                    </div>
                </Col> */}
            </Row>
    {/* <Row>
            <Col xs={12} sm={12} md={12} className='Aboutusblogcardlap'>
  <div className="about-card-container d-flex justify-content-between align-items-center">
    <div className="about-card-content">
      <span className='aboutstite1 d-flex justify-content-center align-items-center'>
        <span className='abouttite'>Title Header</span>
      </span>
      <h1 className='aboutparas1 d-flex justify-content-center align-items-center'>Our Blog</h1>
      <Card
        style={{
          width: 536,
          height: 371,
          borderRadius: 35,
          marginBottom: 40,
          marginLeft: 150,
          position: 'relative',
        }}
        cover={<img alt="example" src={imageblank} className="aboutingblog"/>}
      >
        <span className='aboutlat d-flex justify-content-center align-items-center'><span className='aboutlate'>Latest</span></span>
        <div className='aboutlcblog'></div>
        <p className='aboutauthblog'>Blog author</p>
      </Card>
    </div>
    <div className="aboutmaiblog">
      <p className='aboutbuilblog'>Building your career entails strategically developing.</p>
      <p className='aboutbuildblog'>Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.</p>
      <button className='aboutreadmore'><a href='/BlogLanding' className='blogbuttonaboutus'>Read more</a></button> 
    </div>
  </div>
</Col>
  </Row>

  <Row className='aboutusheaderforphone'>
        <span className='aboutblogheader d-flex justify-content-center align-items-center'>Title Header</span>
        <h1 className='aboutblogheader2 '>Our Blog</h1>
        <p className='aboutblogheader3'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>

  </Row>

  <Row >

    <Col>
    
    </Col>


    <Col className='hideintab'>
    <div style={{ display: 'flex' }}>

      {data.map((item, index) => (
        <div key={index} style={{ marginRight: '20px',marginLeft:'40px' }}>
          <Card
            title={item.title}
            cover={<img alt={item.image} src={imageblank} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />}
            style={{ width: 343, borderRadius: 8 }}
            className='abtcardbldimensions'
          >
            <p className='abtbltitle'>{item.content}</p>
            <p className='abtbltitlepara'>{item.content2}</p>
            <hr className='abthr2blogl'></hr> <br></br>
            <div className='abtbloglc'></div>
            <h1 className='abtbloglctext'>Blog Author</h1>
          </Card>
        </div>
      ))}
    </div>
    </Col>



    <div className='hideinlap'>

      {data.map((item, index) => (
        <div key={index} style={{ marginRight: '20px',marginLeft:'40px' }}>
          <Card
            title={item.title}
            cover={<img alt={item.image} src={imageblacktab}  style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} className='imagebacktabdimen'/>}
            // style={{ width: 343, borderRadius: 8 }}
            className='abtcardbldimensions'
          >
            <p className='abtbltitle'>{item.content}</p>
            <p className='abtbltitlepara'>{item.content2}</p>
            <hr className='abthr2blogl'></hr> <br></br>
            <div className='abtbloglc'></div>
            <h1 className='abtbloglctext'>Blog Author</h1>
          </Card>
        </div>
      ))}
    </div>

    <div className='thisisformob'>

{data.map((item, index) => (
  <div key={index} style={{ marginRight: '20px',marginLeft:'40px' }}>
    <Card
      title={item.title}
      cover={<img alt={item.image} src={imageblank}  style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} className='imagebacktabdimen'/>}
      // style={{ width: 343, borderRadius: 8 }}
      className='abtcardbldimensions'
    >
      <p className='abtbltitle'>{item.content}</p>
      <p className='abtbltitlepara'>{item.content2}</p>
      <hr className='abthr2blogl'></hr> <br></br>
      <div className='abtbloglc'></div>
      <h1 className='abtbloglctext'>Blog Author</h1>
    </Card>
  </div>
))}
</div>

  </Row> */}

  <Signupfooter/>

    {/* <Row>
      <Col>
      <div className='aboutusstartedbox d-flex'>
              <h1 className='Aboutustartedhead'>Ready to join the success stories?</h1>
            <p className='Aboutustartedpara'>Start your journey with us today and unlock endless opportunities for your career growth.</p>
            <button className='Aboutustartedbutton' onClick={()=>navigate("/Student_Registration")}>Sign up now</button> 
            </div>
      </Col>
    </Row> */}
    <br></br><br></br><br className='lapbreak'></br>

      </Container>
    </>
  );
}

export default Aboutparttwo;