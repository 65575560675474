import React from 'react'

const FilterIcon = ({ color = '#CCCFD6' }) => {
  return (
<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00003 16C7.7167 16 7.47936 15.904 7.28803 15.712C7.09603 15.5207 7.00003 15.2833 7.00003 15V9L1.20003 1.6C0.95003 1.26667 0.912696 0.916667 1.08803 0.55C1.2627 0.183334 1.5667 0 2.00003 0H16C16.4334 0 16.7377 0.183334 16.913 0.55C17.0877 0.916667 17.05 1.26667 16.8 1.6L11 9V15C11 15.2833 10.9044 15.5207 10.713 15.712C10.521 15.904 10.2834 16 10 16H8.00003Z" fill={color}/>
</svg>


  )
}

export default FilterIcon
