
import React, { useState, useEffect } from 'react';
import Mlanding from '../LandingPG/landingpg';
import './mainlogolandingstyle.css';

const VideoLanding = () => {
  const [showLanding, setShowLanding] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLanding(true);
    }, 3000); // Change 5000 to the duration of your GIF in milliseconds

    return () => clearTimeout(timer);
  }, []);

  if (showLanding) {
    return <Mlanding />;
  }

  return (
    <div className='mainlogorendering'>
      <img 
        className='intro-video' 
        src={`${process.env.PUBLIC_URL}/video/PluginLiveLogoAnimation2.gif`} 
        alt='Intro animation'
        onError={(e) => console.error('GIF loading error:', e)}
        onLoad={() => console.log('GIF loaded successfully')}
      />
    </div>
  );
};

export default VideoLanding;