import React,{ useEffect,useRef } from "react";
import "./testimonialstyle.css";
import { Testimonialsbox1fun, Testimonialsbox2fun,Testimonialsbox3fun,Testimonialsbox4fun,Testimonialsbox5fun } from './testimonialsboxes';
import Expimg1 from '../../Assets/exptestimg/img1.jpg';
import Expimg2 from '../../Assets/exptestimg/img2.jpg';
import Expimg3 from '../../Assets/exptestimg/img31.jpg';
import Expimg4 from '../../Assets/exptestimg/img4.jpg';
import Expimg5 from '../../Assets/exptestimg/img5.jpg';


function Testimonialsexp(){
    const containerRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('testimonials-visible');
            } else {
              entry.target.classList.remove('testimonials-visible');
            }
          });
        },
        { threshold: 0.1 }
      );
  
      const items = containerRef.current.querySelectorAll('.testimonial-container1, .testimonial-container2, .testimonial-container3, .testimonial-container4, .testimonial-container5');
      items.forEach((item) => {
        item.classList.add('testimonials-hidden');
        observer.observe(item);
      });
  
      return () => {
        items.forEach((item) => observer.unobserve(item));
      };
    }, []);

    return(
        <>
        <div className="testmainheaderfre">Real Voices, Real Success</div>
            <h1 className="testmainsubheaderfre">Testimonials from our Experienced Professionals</h1>
        <div className="testionialsContainer" ref={containerRef}>
            
            <Testimonialsbox1fun 
        image={Expimg1} 
        text=" I'm grateful to PluginLive, from the very first interaction to the final stages of the process, your team's dedication and professionalism truly stood out. Throughout the process, I felt valued as a candidate, and your team's personalized approach made all the difference. Each communication was not just informative but also carried a sense of genuine care and attention to detail. It's evident that you all prioritize creating meaningful connections with candidates, and I genuinely appreciate that. Thank you for your services." 
        name="Sri Krishna Bhati"
        designation="Associate Institutional Fixed-Income Sales"
      />
      <Testimonialsbox2fun 
        image={Expimg2}
        text="I wanted to share my experience with the PluginLive Team during my recent interview process. The team's communication was amazing, keeping me updated on time and ensuring clarity. The recruitment process ran smoothly, showing their efficiency and professionalism,  making everything easier for the candidates. I appreciate their efforts and would definitely recommend them to others looking for recruitment support." 
        name="Shruti Vasani"
        designation="Research & Strategy Analyst"
      />

      <Testimonialsbox3fun 
        image={Expimg4}
        text="I am writing to express my heartfelt thanks for the exceptional support I received from PluginLive team during my recent job placement. The entire process was not only seamless but also a pleasure, thanks to the people in the team and the kindness everyone showed.Thank you once again for your commitment to excellence. I will not hesitate to recommend your agency to others seeking professional guidance in their job search." 
        name="Rishika  Agarwal"
        designation="Lead Analyst - Client Relations"
      />

      <Testimonialsbox4fun 
        image={Expimg5}
        text="I wanted to express my appreciation for the excellent support provided by the PluginLive team. Their expertise, professionalism, and dedication greatly contributed to my career. Their insights were invaluable, and their approach was both strategic and practical." 
        name="Sahil Shah"
        designation="Research Analyst"
      />

      <Testimonialsbox5fun 
        image={Expimg3}
        text="Thanks a lot PluginLive for providing candidates a platform where they can use their potential to grow and earn what they deserve. My interview experience with PluginLive has been amazing. Kudos to the team." 
        name="Sarthak Shah"
        designation="Business Continuity Analyst"
      />
        </div>
        </>
    );
}

export default Testimonialsexp;