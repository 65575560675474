import styled from 'styled-components'
import Flex from '../JobContainer/Flex'
// import { COLORS } from '../../theme'
// import { FONT } from '../../theme/font'
import { Form ,DatePicker} from 'antd'
import { Radio as AntdRadio } from 'antd'
import AntdInput from '../JobContainer/Input/Input'
// import Radio from '../../JobContainer/Form/RadioButton'
import { Form as AntdForm } from 'antd'

export const PaddedOuter = styled.div`
margin: 30px 300px 20px 300px;
@media only screen and (max-device-width: 767px) {
  margin: 10px;
}
@media only screen and (min-width: 768px )  and  (max-width : 1200px){
  margin: 10px;
}
` 

export const TopHeader = styled(Flex)`
  padding: 20px 25px;
  position: relative;
  width: 100%;
  font-size: 2em;
  @media only screen and (max-device-width: 767px) {
    padding: 15px 10px;
  }
  @media only screen and (min-width: 768px )  and  (max-width : 1200px){
    padding: 15px 10px;
  }
`
export const TopHeading = styled(Flex)`
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 28px;
color: #010E30;
@media only screen and (max-device-width: 767px) {
  font-size: 15px;
}
.span{
  color:#3249D7;
  }
`
export const TopHeading1 = styled(Flex)`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #343E59;
.span{
color:#3249D7;
}
`

// export const SectionWrapper = styled(Flex)`
//   width: 100%;
//   border: 1px solid ${COLORS.PAGE_BORDER};
//   box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
//   border-radius: 10px;
//   background: ${COLORS.WHITE};
// padding:40px;
// `
export const FormWrapper = styled(Form)`
  width: 100%;
`


export const StyledInput = styled(AntdInput)`
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
`

export const StyledSelectDiv = styled(Flex)`
 
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0;
  }
`

export const StyledInputDiv = styled(Flex)`
  margin-bottom: 20px !important;
  align-items: baseline;
  width: 100%;
  border-radius: 8px;
  .ant-inputselect:not(.ant-select-customize-input) .ant-select-selector {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`
export const RadioGroup = styled(AntdRadio.Group)`
width:auto;
  display: flex;
  gap: 15px;
  margin-top: 8px;
  ${props => props?.spaceBetween && 'justify-content: space-between;'}
  `

//   export const Radios = styled(Radio)`
//   border: none !important;
//   font-weight: ${FONT.FW_600} !important;
//   font-size: ${FONT.F_SM} !important;
//   color: ${COLORS.GREY_T_20} !important;
//   padding: 15px 0 15px 0;
//   margin: 0;
// `



export const FormItem = styled(AntdForm.Item)`
  margin-top: 0px;
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  width: 100%;
  .ant-form-item-explain-error {
    padding-left: ${props => (props.padding ? '15%' : '0')};
  }
  .ant-form-item-control-input {
    min-height: 0px;
  }
`

export const StyledDatePicker = styled(DatePicker)`
  border-radius: 10px;
  width: 100%;
  height: 48px;
  border-color: #e9e9e9;
  color: '#010E30';
  font-weight: 500;
  box-shadow: none;
  .ant-picker-focused {
    border-color: #e9e9e9 !important;
    box-shadow: none;
  }

  &:focus {
    color: '#010E30';
    background: #fafafb;
    border-color: #e9e9e9 !important;
    box-shadow: none;
  }
  &:hover {
    border-color: #e9e9e9;
  }
  .ant-picker-input > input {
    font-size: 14px;
    font-weight: 500;
  }
  background-color: ${props =>
    !props?.disabled
      ? !props.defaultValue
        ? '#fafafb'
        : '#ffffff'
      : ''} !important;
`
const LabelWrapper = styled.div`
  margin-top: 2px;
  padding-bottom: 8px;
`

export const Container = styled(Flex)`
  height: 90vh;
  width: 100vw;
  background-color: #fafafb;
  gap: 40px;
`
export const Wrapper = styled.div`
  width: 48%;
  padding-top: 125px;
`

export const Content = styled(Flex)`
  height: auto;
  width: 100%;
  background-color: white;
  border: 1px solid #e6e7ea;
  box-shadow: 0px 6px 10px rgba(86, 94, 106, 0.1);
  padding: 93px 160px 137px 160px;
  gap: 10px;
  border-radius: 10px;
`

export const Heading = styled.div`
  padding-top: 10px;
  padding-left : 25px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #010e30;
  text-align: center;
`
export const Para = styled.div`
  padding-top: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #676e83;
  text-align: center;
`