import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import './App.css';
import Navigation from './Layouts';
import Home  from './pages/home';
import About from './pages/about';
import Profile from './pages/profile';
import Job from './pages/job';
import Signin from './pages/signin';


function App() {
  return (

      <Router>
         <div>
        <Navigation/>
          <Routes>
          <Route path="/home"  element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path='/profile' element={<Profile />} />
          <Route path="/job" element={<Job />} />
          <Route path="/signin" element={<Signin />} />        
          </Routes>
          </div>
      </Router>
    
   );
}

export default App;
