import React from 'react';
import './TPContstyle.css';
import { useLocation,useNavigate } from 'react-router-dom';
import NIIT from "../../Assets/traininglogo/niit2.png";
import Aptech from "../../Assets/traininglogo/aptechlogo4.png";
import Kodnest from "../../Assets/traininglogo/kodnest.png";
import Techpaathshala from "../../Assets/traininglogo/techpaathshala.png";

import Testgorilla from "../../Assets/Assessmentlogo/testgorilla.png";
import Eskill from "../../Assets/Assessmentlogo/eskill_Logo.jpg";

const TrainingpartnerContainer = () => {

    const location = useLocation();

    const navigate = useNavigate();

  const navigateToRegistration = () => {
    // const url = '/Student_Registration?profile=fresher';
    const url = '/fresher';
    navigate(url);
  };

  const handleNavigation = () => {
    const profileType = 'training partner';
    localStorage.setItem('profile', profileType);
    const url = '/Student_Registration';
    window.location.href = url;
    window.scrollTo(0, 0);
  };

  return (

    <>
        <div>
            <div className='TrainingPartContainer'>
            <h1 className='TrainingPartContainerHeaderSm'>Training Partners</h1>
            <div className="centered-container">
            <div className="left-section">
                <h1 className='TrainingPartContainerHeader'>Training Partners</h1>
                <p className='TrainingPartContainerPara'>Collaborate with us to elevate your workforce through specialized skill development programs.</p>
                <p className='TrainingPartContainerPara'>Equip your team with cutting-edge expertise tailored to your industry's needs, and together, conquer new heights in your field with our customized training solutions.</p>
                {location.pathname === '/partner' && <button className='TrainingPartContainerButton' onClick={handleNavigation}>Contact</button>}
            </div>
            <div className="right-section">
                <span className='Logo1bg'><img src={Aptech} alt="Logo 1" className="logo1" /></span>
                <span className='Logo2bg'><img src={NIIT} alt="Logo 2" className="logo2" /></span>
                <span className='Logo3bg'><img src={Kodnest} alt="Logo 3" className="logo3" /></span>
                <span className='Logo4bg'><img src={Techpaathshala} alt="Logo 4" className="logo4" /></span>
            </div>
            </div>
            <p className='TrainingPartContainerParaSm'>Collaborate with us to elevate your workforce through specialized skill development programs.Equip your team with cutting-edge expertise tailored to your industry's needs, and together, conquer new heights in your field with our customized training solutions.</p>
            {location.pathname === '/partner' && <button className='TrainingPartContainerButtonSm' onClick={handleNavigation} >Contact</button>}
            </div>
            {location.pathname === '/corporate' && <AssessmentContainer />}
        </div>
    </>
    



  );
};


function AssessmentContainer(){
    return(
        <>
            <div>
            <div className='AssessPartContainer'>
            <h1 className='AssessPartContainerHeaderSm'>Assessment Partners</h1>
            <div className="Assesscentered-container">
            <div className="right-section">
                <span className='alogo1bg'><img src={Testgorilla} alt="Logo 1" className="alogo1" /></span>
                <span className='alogo2bg'><img src={Eskill} alt="Logo 2" className="alogo2" /></span>
                
            </div>
            <div className="left-section">
                <h1 className='AssessPartContainerHeader'>Assessment Partners</h1>
                <p className='AssessPartContainerPara'>Ready to identify top talent efficiently? Access cutting-edge pre-employment assessments and resources to assess candidates effectively and make informed hiring decisions. Let's revolutionize your recruitment process together! </p> 
            </div>
            </div>
            <p className='AssessPartContainerParaSm'>Ready to identify top talent efficiently? Access cutting-edge pre-employment assessments and resources to assess candidates effectively and make informed hiring decisions. Let's revolutionize your recruitment process together! </p>
            </div>
            </div>
        </>
    );
}

export default TrainingpartnerContainer;