import { Container,  Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
// import 'antd/dist/antd.css';
import { List, Space,Card ,Row, Col, } from 'antd';
import terms from '../TermsandConditions/images/termsandconditions.png';
import '../BlogLandingpage/styleBlanding.css';
// import Card from 'react-bootstrap/Card';
import imageblank from '../../Assets/blank.png';
import ListGroup from 'react-bootstrap/ListGroup';


function BlogLanding(){

    const data = [
        {
          // title: 'Card 1',
          content: 'Building your career entails strategically developing.',
          content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
        },
        {
          content: 'Building your career entails strategically developing.',
          content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
        },
        {
          content: 'Building your career entails strategically developing.',
          content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
        },
        {
          content: 'Building your career entails strategically developing.',
          content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
        },
        {
          content: 'Building your career entails strategically developing.',
          content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
        },
        {
          content: 'Building your career entails strategically developing.',
          content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
        },
      ];

    return(
        <>
      {/* <Container>
        <Row>
          <Col xs={12} sm={12} md={12}>
          <div className="prisec d-flex">
            <img src={terms} alt="terms" className="privecimg"/>
            <span className='prisecimg d-flex justify-content-center align-items-center'></span>
            <span className='blogc1'></span>
            <h1 className='blogc1text'>Blog Author</h1>
          </div>
          </Col>

          <Col xs={12} sm={12} md={12}>
            <div className='bloglhead'>
                    <h1 className='bloglhead1'>Building your career entails strategically eveloping.</h1>
                    <p className='bloglheadpara1 d-none d-lg-block'>Building your career entails strategically developing personal and professional skills through  tentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through  tentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through  tentional actions and continuous learning for growth and success. </p>

                    <p className='bloglheadpara1 d-sm-none'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>
                    <button className='readmorebutton'>Read more</button> 
                    <hr className='hr1blogl'></hr> 
            </div>
          </Col>

          </Row>
        </Container> */}

        <Row>
            <Col>
            <div className="prisecblanding d-flex">
            <img src={terms} alt="terms" className="privecimg"/>
            <span className='prisecimg d-flex justify-content-center align-items-center'></span>
            <span className='blogc1'></span>
            <h1 className='blogc1text'>Blog Author</h1>
          </div>
            </Col>
            <div className='bloglhead'>
                    <h1 className='bloglhead1'>Building your career entails strategically eveloping.</h1>
                    <p className='bloglheadpara1 d-none d-lg-block'>Building your career entails strategically developing personal and professional skills through  tentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through  tentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through  tentional actions and continuous learning for growth and success. </p>

                    <p className='bloglheadpara1 d-sm-none'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>
                    <button className='readmorebutton'>Read more</button> 
                    <hr className='hr1blogl'></hr> 
            </div>
        </Row>


          <Row gutter={[16, 16]}>
    {data.map((item, index) => (
      <Col key={index} xs={24} sm={12} md={8} lg={12} xl={7}>

        <Card title={item.title} cover={<img alt={item.image} src={imageblank} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}/>}
         style={{ borderRadius: 8 }} className='cardbldimensions'>
            <p className='bltitle'>{item.content}</p>
            <p className='bltitlepara'>{item.content2}</p>  
            <hr className='hr2blogl d-none d-lg-block'></hr>  <br></br>
            <div className='bloglc'></div>
            <h1 className='bloglctext'>Blog Author</h1>   
        </Card>
      </Col>
    ))}
  </Row>

  <button className='showmorebutton'>Show more</button> 

        <Row>
            <Col>
            <div className='Blogstartedbox d-flex'>
              <h1 className='Blogstartedhead'>Let's get started</h1>
            <p className='Blogstartedpara'>Building your career entails strategically developing personal and professional skills.</p>
            <button className='Blogstartedbutton'>Register</button> 
            </div>
            </Col>
        </Row>
        
        <br></br><br></br><br></br><br></br>
        </>
    );
}

export default BlogLanding;