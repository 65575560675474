// Name.js
import React from 'react';
import { Form, Input } from 'antd';
import './stylename.css'

const firstName = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue); 
  };

  const validateSpaces = (rule, value, callback) => {
    if (value && value?.length > 2 && value.trim().length === 0) {
      callback("Please enter valid name!");
    } else {
      callback();
    }
  };

  return (
    <Form.Item
      name="firstName"
      rules={[
        {
          required: true,
          min: 3,
          max: 120,
          message:
            "Please enter first name! Minimum 3 characters , Maximum 120 characters",
        },
        {
          validator: validateSpaces,
        },
      ]}
    >
      <Input
        value={value}
        placeholder="Enter first name"
        className="namesize"
        onChange={handleInputChange} 
      />
    </Form.Item>
  );
};

export default firstName;
