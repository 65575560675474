import {Link} from 'react-router-dom';
import React, { useState } from 'react';



export default function Profile(){
    
    const [showProfileDropdown , setShowProfileDropdown] = useState(false);

    const ContentFunction = () => {
        // This content will be displayed only when the function is called
        return (
          <div>
            <p>This content is displayed when the function is called.</p>
          </div>
        );
      };

    const hideMenu = () => {
        setShowProfileDropdown(false);
      }
    return(

        
        <div className={`dropdown-content ${showProfileDropdown ? 'show' : ''}`}>
              <Link to="/profile">View Profile</Link>
              <Link to="/settings">Settings</Link>
              <Link to="/logout">Logout</Link>
              <ContentFunction /> {/* Conditionally render the content function */}
            </div>
    );
}