import React from "react";
import './signupfooterstyle.css';

function SignUp({ profile }) {
  const { REACT_APP_AUTH_URL } = process.env;
  const handleClick = () => {
    if(profile === "fresher")
      {
        localStorage.setItem('profile', '');
        window.open(`${REACT_APP_AUTH_URL}`, "_blank", "noreferrer");
      }
    if (profile && profile != "fresher") {
      localStorage.setItem('profile', profile);
      window.location.href = '/Student_Registration';
      window.scrollTo(0, 0);
    } 
    else {
      if(profile != "fresher")
      {
      localStorage.setItem('profile', '');
      window.location.href = '/Student_Registration';
      window.scrollTo(0, 0);
      }
    }

  }

  return (
    <>
      <div className="SignupContainer">
        <div className="Signupbox">
          <h1 className="Signupboxhead">Ready to join the success stories?</h1>
          <p className="Signupboxpara">Start your journey with us today and unlock endless opportunities for your career growth.</p>
          <button className="Signupboxbutton" onClick={handleClick}> {profile === 'fresher' ? 'Login' : 'Sign up now'}</button>
        </div>
      </div>
    </>
  );
}

export default SignUp;