import React from 'react';
import { Collapse } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'; // Import CaretDownOutlined
import './stylefq.css';

const { Panel } = Collapse;

const CustomCollapse = ({ expandIcon, items, className, size }) => {
  return (
    <Collapse
      size={size}
      expandIcon={({ isActive }) => (
        expandIcon ? expandIcon({ isActive }) : <CaretDownOutlined style={{ transform: isActive ? "rotate(180deg)" : "" }} className='arrowheadaboutus' />
      )}
      expandIconPosition="right"
      className={className}
    >
      {items.map(item => (
        <Panel key={item.key} header={item.label} >
          {item.children}
        </Panel>
      ))}
    </Collapse>
  );
};

export default CustomCollapse;
