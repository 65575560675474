import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import './stylecolgform.css';
import Profile from '../Forms/profile.js';
import Name from '../Forms/name.js';
import Email from '../Forms/email.js';
import PhoneNum from '../Forms/phonumb.js';
import Organization from '../Forms/organization.js';
import Location from '../Forms/location.js';
import { Button, Form } from 'antd';
import College from './images/collegeform.png'

const CollegeForm = () => {
  // const [formData, setFormData] = useState({
  //   profile: '',
  //   name: '',
  //   email: '',
  //   phone: '',
  //   organization: '',
  //   location: ''
  // });

  // const handleChange = (field, value) => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [field]: value
  //   }));
  //   console.log(field, value, formData, 'data');
  // };

  // const handleSubmit = (values) => {
  //   const formDataToSubmit = {
  //     profile: values.profile,
  //     name: values.name,
  //     email: values.email,
  //     phone: values.phone,
  //     organization: values.organization,
  //     location: values.location
  //   };

  //   console.log('Received values:', formDataToSubmit);
  // };

  return (
    <>
      <Container>
        <div className='colgregisform'>
          {/* <div className='colgbluformbox'> */}
          {/* <div className="colggreyformbox"> */}
        <span className='colgbackimg d-flex justify-content-center align-items-center'></span>
        <img src={College} alt='fresher' className='formcolgimg'/>
      </div>
      <div className="formcolgext">
        <h2 className='colformtit'>College</h2>
        <p className='colgformpara'>Simplify the campus placement process for your students, providing them with comprehensive support and resources to secure promising career opportunities effortlessly.</p>
        {/* <span className='titfrmcol d-flex justify-content-center align-items-center'><span className='frmcoltxt'>Request a Demo</span></span> */}
      </div>
              {/* <Form layout="vertical" onFinish={handleSubmit}>
                <div className="colglogcontent">
                  <Form.Item label="Pick your profile *" name="profile" style={{ marginBottom: '0px', display: 'flex' }}>
                    <Profile value={formData.profile} onChange={(value) => handleChange('profile', value)} />
                  </Form.Item>
                  <Form.Item label="Your name *" name="name" style={{  marginBottom: '0px', display: 'flex' }}>
                    <Name value={formData.name} onChange={(value) => handleChange('name', value)} />
                  </Form.Item>
                  <Form.Item label="Contact *" style={{  marginBottom: '-20px' }}>
                    <div style={{ display: 'flex' }}>
                      <Form.Item name="email" style={{ marginRight: 10 }}>
                        <Email value={formData.email} onChange={(value) => handleChange('email', value)} />
                      </Form.Item>
                      <Form.Item name="phone">
                        <PhoneNum value={formData.phone} onChange={(value) => handleChange('phone', value)} />
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Form.Item label="Other details" style={{ marginBottom: '-10px' }}>
                    <div style={{ display: 'flex' }}>
                      <Form.Item name="organization" style={{ marginRight: 10 }}>
                        <Organization value={formData.organization} onChange={(value) => handleChange('organization', value)} />
                      </Form.Item>
                      <Form.Item name="location">
                        <Location value={formData.location} onChange={(value) => handleChange('location', value)} />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>
                <div className="colglogsbutton">
                  <Form.Item>
                    <Button
                      type="primary"
                      size='large'
                      htmlType="submit"
                      style={{
                        width: '173px',
                        height: '60px',
                        borderRadius: '37.5px',
                        opacity: '0px',
                        backgroundColor: '#073CA5',
                        fontFamily: 'Plus Jakarta Sans, sans-serif',
                        fontSize: '20px',
                        textAlign: 'center',
                        lineHeight: '24.8px',
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div> */}
      </Container>
    </>
  );
};

export default CollegeForm;
