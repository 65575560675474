import React from 'react'

const SelectArrowIcon = ({ color = '#CCCFD6' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_776_18137)">
        <path
          d="M11.2998 14.3L8.69977 11.7C8.3831 11.3833 8.31243 11.021 8.48777 10.613C8.66243 10.2043 8.97477 10 9.42477 10H14.5748C15.0248 10 15.3371 10.2043 15.5118 10.613C15.6871 11.021 15.6164 11.3833 15.2998 11.7L12.6998 14.3C12.5998 14.4 12.4914 14.475 12.3748 14.525C12.2581 14.575 12.1331 14.6 11.9998 14.6C11.8664 14.6 11.7414 14.575 11.6248 14.525C11.5081 14.475 11.3998 14.4 11.2998 14.3Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default SelectArrowIcon
