import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import './studentformstyle.css';
import Profile from '../Forms/profile.js';
import FirstName from '../Forms/name.js';
import LastName from '../Forms/lastname.js'
import Email from '../Forms/email.js';
import PhoneNum from '../Forms/phonumb.js';
import Organization from '../Forms/organization.js';
import Location from '../Forms/location.js';
import { Button, message, Form, Avatar, Alert } from 'antd';
import FresherForm from '../FresherForm/fresherform.js'; 
import ExperiencedForm from '../ExperiencedForm/experienceform.js'; 
import CollegesFrom from '../CollegesForm/collegeform.js'
import PartnersForm from '../PartnersForm/partnerform.js'
import CorporateFrom from '../CorporateForm/corporateform.js'
import axios from "axios"; 
import Control from "../JobContainer/Control/Control.js";
import AntdSelect from "../JobContainer/Form/Select.js";
import AntdSelectKey from "../JobContainer/Form/Selectkey.js";
import AntdInput from "../JobContainer/Form/Input.js";
import { searchConfig } from "./config.js";
import { educationDetails } from "./constant";
import GoogleIcon from '../../Assets/Icons/google 1.svg';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FormItem } from "./styles";
import { MonthList } from "./constant";
import moment from "moment";

const CustomLabel = ({ children }) => (
  <label className="custom-label">{children}</label>
);

const StyledText = styled.span`
  text-transform: capitalize;
`;
const ErrorMessage = (description) =>
  message.error({
    content: <StyledText>{description}</StyledText>,
    className: "custom-class",
    style: {
      marginTop: "50px",
      color: "#010E30",
      borderLeft: "5px solid red",
      transitionDuration: "5s",
    },
  });

const StudentForm = () => {

  const [cityName, setCityName] = useState('')
  const [stateName, setStateName] = useState('')
  const [stateId, setStateId] = useState('')
  const [startMonth, setStartMonth] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [degreeName, setDegreeName] = useState("");
  const [degreeSearch, setDegreeSeach] = useState("");
  const [degreeList, setDegreeList] = useState([]);
  const [crudDegreeList, setCrudDegreeList] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [specialisationName, setSpecialisationName] = useState("");
  const [collegeID, setCollegeID] = useState("");
  const [courseId, setCourseId] = useState(null);
  const [clgName, setClgName] = useState("");
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedStateName, setSelectedStateName] = useState(null);
  const [selectedCityName, setSelectedCityName] = useState(null);
  const [listOfState, setListOfState] = useState([]);
  const [stateSearch, setStateSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [cityList, setCityList] = useState([])
  const [listOfCity, setListOfCity] = useState([]);
  const [collegeName, setCollegeName] = useState("");
  const [searchCollege, setSearchCollege] = useState("");
  const [listOfCollege, setListOfCollege] = useState("");
  const [degreeSearchList, setDegreeSearchList] = useState([]);
  const [departmentSearch, setDepartmentSeach] = useState("");
  const [specialisationSearch, setSpecialisationSeach] = useState("");
  const [crudDepartmentList, setCrudDepartmentList] = useState([]);
  const [crudSpecialisationList, setCrudSpecialisationList] = useState([]);
  const [dateErrorMsg, setDateErrorMsg] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { REACT_APP_GOOGLE_AUTH_URL } =  process.env;
  const { REACT_APP_GOOGLE_CLIENT_ID } =  process.env;
  const { REACT_APP_AUTH_PAGE_URL } = process.env;
  const {REACT_APP_AUTH_API} =  process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_SEARCH_API_URL } = process.env;
  const { REACT_APP_STATIC_PAGE_URL } =  process.env;
  const { REACT_APP_STD_API_URL } = process.env;
  const { REACT_APP_SEARCH_API_KEY } = process.env;
  const { REACT_APP_SEARCH_AUTH_KEY } = process.env;
  const { REACT_APP_COLLEGE_API_URL } = process.env;
  const { REACT_APP_STD_URL } = process.env;

  const [collegeSearchList, setCollegeSearchList] = useState([]);
  const [showSpecialization, setShowSpecialization] = useState(false);
  const [educationLevelName, setEducationLevelName] = useState(null)
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentSearchList, setDepartmentSearchList] = useState([]);
  const [specialisationList, setSpecialisationList] = useState([]);
  const [specialisationSearchList, setSpecialisationSearchList] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  let [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get("email");
  const firstname = searchParams.get("firstname");
  const lastname = searchParams.get("lastname");

  const location = useLocation();
  const searchParamsCode = new URLSearchParams(location.search);
  const code = searchParamsCode.get("code");
  const initialProfile = localStorage.getItem('profile') || new URLSearchParams(location.search).get('profile');

  useEffect(() => {
    const getCodeFromURL = async () => {
console.log(code,'code')
      if (code) {
        try {
          let response = await axios.post(`${REACT_APP_AUTH_API}/user/auth`, {
            code: code,
            isStaticSite: true,
          });
          let data = response.data;
          let status = response.status;
        
          if (status >= 200 && status < 400) {
            let url = data?.data?.reDirectUrl;
            window.location.href = url;
          }
        } catch (error) {
          ErrorMessage(error?.response?.data?.message);
          setTimeout(function () {
            window.location.href = `${REACT_APP_AUTH_PAGE_URL}/Student_Registration`;
          }, 2000);
       
        }
      } else {
        console.log("Code parameter not found in the URL");
      }
    };

    getCodeFromURL();
  }, [location?.search,code,REACT_APP_AUTH_PAGE_URL,REACT_APP_STATIC_PAGE_URL]);
useEffect(() =>{
  form.setFieldsValue({email: email})
  form.setFieldsValue({firstName: firstname})
  form.setFieldsValue({lastName: lastname})
  if (initialProfile) {
    setSelectedProfile(initialProfile); 
    form.setFieldsValue({ profile: initialProfile });
  }
},[searchParams, initialProfile, form]);
  
  const handleGoogleSignInClick = () => {
    localStorage.setItem('profile', selectedProfile);
    const authorizationUrl =
      REACT_APP_GOOGLE_AUTH_URL +
      `?client_id=${REACT_APP_GOOGLE_CLIENT_ID}` +
      `&redirect_uri=${REACT_APP_AUTH_PAGE_URL}/Student_Registration` +
      "&response_type=code" +
      "&scope=openid%20email%20profile%20phone";

    window.location.href = authorizationUrl;
  };

  let reqInstance = axios.create({
    headers: {
      "auth-key": REACT_APP_SEARCH_AUTH_KEY,
      "api-key": REACT_APP_SEARCH_API_KEY
    },
  });
  
  const submitHandler = async (data) => {
    console.log(data,'data')
    let reqData = {
      admin: {
        email: data?.email?.trim() || '',
        phoneNumber: data?.phone || '',
        firstName: data?.firstName?.trim() || '',
        lastName: data?.lastName?.trim() || '',
        countryCode: "+91",
      },
      student:
        selectedProfile === 'fresher'
          ? {
              instituteCampusId: collegeID,
              courseId: data?.degreeStreamMapId ? courseId : collegeID,
              instituteCampusName: clgName,
              isExpCandidate: false,
            }
          : {
              isExpCandidate: true,
            },
      studentPersonalProfile: {
        contactNumber: data?.phone,
      },
      currentCourse:
        selectedProfile === 'fresher'
          ? {
              state:data?.state,
              city:data?.city,
              stateId:data?.stateId,
              cityId:data?.cityId,
              domainId: data?.domainId ?? null,
              domain: data?.domain ?? null,
              degree: data?.degree ? data?.degree : null,
              department: data?.department ? data?.department : null,
              educationLevel: data?.educationLevel
                ? data?.educationLevel?.toLowerCase()
                : null,
              specialisation: data?.specialisation
                ? data?.specialisation
                : null,
              startedOn: data.startedOn
                ? moment(data.startedOn, "MM/YYYY").format("x")
                : null,
              endedOn: data.endedOn
                ? moment(data.endedOn, "MM/YYYY").format("x")
                : null,
              degreeStreamMapId: data?.degreeStreamMapId ?? null,
              specialisationId: data?.specialisationId
                ? data?.specialisationId
                : null,
            }
          : {},
      education: [],
      resume: {},
      degreeStreamMap:
        selectedProfile === 'fresher'
          ? {
              degreeId: data?.degreeId,
              streamId: data?.streamId,
            }
        : {},
      };
  
      createStudent(reqData);
  };
  
  const createStudent = (payload) => {
    reqInstance
      .post(`${REACT_APP_STD_API_URL}/students`, payload)
      .then((response) => {
        console.log(response);
        if (email) {
          let url = `${REACT_APP_STD_URL}/onboarding/activate/${response?.data?.data?.id}`;
          window.open(url, "_blank");
        }
        message.success('Thank you for Registering with us, Check Your Email.', 5);
        
        // Reset other fields upon successful submission
        const currentProfile = form.getFieldValue().profile;
        form.resetFields();
        form.setFieldsValue({ profile: currentProfile });
        getListOfState('6364ed8ae0338a14ae729651',"")
        delayToFetch('','','')
        setShowOtherInputDegree(false);
        setShowOtherInputDepartment(false);
        setShowOtherInputSpecialisation(false);
        setShowSpecialization("");
        setStateName("");
        setCityName("");
        setStartMonth("");
        setStartYear("");
        setEndYear("");
        setEndMonth("");
        setDegreeName("");
        setDepartmentName("");
        setCollegeName("");
        setStartDate("");
        setEndDate("");
      })
      .catch((error) => {
        ErrorMessage(error?.response?.data?.error?.message);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const sendEmail = (mailData) => {
    reqInstance
      .post(`${REACT_APP_COLLEGE_API_URL}/staticnotification/email`, mailData)
      .then(response => {
        console.log('Email sent successfully:', response.data);
        message.success('Thank you for your request. We will be in touch with you shortly.', 5);
        
        // Reset other fields upon successful submission
        const currentProfile = form.getFieldValue().profile;
        form.resetFields();
        form.setFieldsValue({ profile: currentProfile });
      })
      .catch((error) => {
        ErrorMessage(error?.response?.data?.error?.message);
        console.log(error);
      });
  };

  const [formData, setFormData] = useState({
    profile: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    organization: '',
    location: '',
  });

  const [selectedProfile, setSelectedProfile] = useState('');
  const formRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 577);
      setIsTab(width >= 577 && width < 830);
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isTab, setIsTab] = useState(false);

  const handleChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
    console.log(field, value, formData, 'data');
  };
 
  const handleSubmit = (values) => {
    const data = form.getFieldValue()
    console.log(data,'data')
    const formDataToSubmit = {
      profile: values.profile,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      organization: values.organization,
      location: values.location,
    };
  
    console.log('Received values:', formDataToSubmit);
    console.log('Selected Profile:', formDataToSubmit.profile);
  
    const selectedProfile = formDataToSubmit.profile;

    if (['college', 'training partner', 'corporate'].includes(selectedProfile)) {
    
      const capitalize = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
      };
    
      const emailData = {
        to: 'mandate@pluginlive.com',
        subject: `Requesting for a demo - ${capitalize(selectedProfile)}`,
        htmlBody: `
          <b>Profile:</b> ${capitalize(selectedProfile)}<br/>
          <b>Name:</b> ${capitalize(formData.firstName)} ${capitalize(formData.lastName)}<br/>
          <b>Email:</b> ${formData.email}<br/>
          <b>Phone:</b> ${formData.phone}<br/>
          <b>Organization:</b> ${capitalize(formData.organization)}<br/>
          <b>Location:</b> ${capitalize(formData.location)}<br/>
        `
      };
    
      console.log('Email Data:', emailData);
    
      sendEmail(emailData);
    } else {
      submitHandler(formDataToSubmit);
    }
  };  

  const completeCrudDegreeList = degreeSearchList
  ? [...degreeSearchList, { name: "Other", id: "OTHERS" }]
  : [];

const completeCrudDepartmentList = departmentSearchList
  ? [...departmentSearchList, { name: "Other", id: "OTHERS" }]
  : [];

const completeCrudSpecialisationList = specialisationSearchList
  ? [...specialisationSearchList, { name: "Other", id: "OTHERS" }]
  : [];

  const currentYear = moment().year();
const years = [];
for (let year = currentYear; year >= 1923; year--) {
  years.push({
    value: year,
    name: year,
  });
}

const endYears = [];
for (let year = 2033; year >= 1923; year--) {
  endYears.push({
    value: year,
    name: year,
  });
}

const fetchStateCity = async (stateName = "", cityName = "") => {
  let stateId = form.getFieldValue("stateId");
  let state = stateName;
  let cityId = form.getFieldValue("cityId");
  let city = cityName;
  const results = await getListOfState("6364ed8ae0338a14ae729651", state);

  if (results && results?.length && state) {
    let stateObj = results[0];
    if (
      stateObj?.name?.toLowerCase() == state?.toLowerCase() ||
      results?.length == 1
    ) {
      if (stateObj) {
        form.setFieldsValue({ stateId: stateObj?._id });
        form.setFieldsValue({ state: stateObj?.name });
        stateId = stateObj?._id;
        state = stateObj?.name;
        setStateId(stateId);
      }
      await getCityData(stateId, city, cityId);
    } else {
      if (state && !stateId) {
        if (!results?.length) {
          await getListOfState("6364ed8ae0338a14ae729651", "");
        }
        form.setFieldsValue({ city: "" });
        form.setFieldsValue({ state: "" });
      }
    }
  } else {
    if (state && !stateId) {
      if (!results?.length) {
        await getListOfState("6364ed8ae0338a14ae729651", "");
      }
      form.setFieldsValue({ city: "" });
      form.setFieldsValue({ state: "" });
    }
  }
};

  const [showOtherInputDegree, setShowOtherInputDegree] = useState(
    form.getFieldValue([`degreeId`]) === "OTHERS" ? true : false
  );
  const [showOtherInputDepartment, setShowOtherInputDepartment] = useState(
    form.getFieldValue([`streamId`]) === "OTHERS" ? true : false
  );
  const [showOtherInputSpecialisation, setShowOtherInputSpecialisation] =
    useState(
      form.getFieldValue([`specialisationId`]) === "OTHERS" ? true : false
    );

  useEffect(() => {
    getListOfState('6364ed8ae0338a14ae729651', stateSearch)
  }, [stateSearch]);

  useEffect(() => {
    searchAPI(searchConfig?.institutesCampuses?.name, searchCollege, searchConfig?.institutesCampuses)
    .then(results => setCollegeSearchList(results))
    .catch(error => console.error("Error searching colleges:", error));
  }, [searchCollege]);

  useEffect(() => {
    searchAPI(searchConfig?.degrees?.name, degreeSearch, searchConfig?.degrees)
      .then(results => setDegreeSearchList(results))
      .catch(error => console.error("Error searching degrees:", error));
  }, [degreeSearch]);
  
  useEffect(() => {
    searchAPI(searchConfig?.specialisations?.name, specialisationSearch, searchConfig?.specialisations)
      .then(results => setSpecialisationSearchList(results))
      .catch(error => console.error("Error searching specialisations:", error));
  }, [specialisationSearch]);
  
  useEffect(() => {
    searchAPI(searchConfig?.streams?.name, departmentSearch, searchConfig?.streams)
      .then(results => setDepartmentSearchList(results))
      .catch(error => console.error("Error searching departments:", error));
  }, [departmentSearch]);

  const getListOfState =  async (countyId, search) =>  {
    let county = countyId ? `&countryId=${countyId}` : ''
    let searchValue = search ? `&search=${search}` : ''
    try {
      const response = await reqInstance.get(
        `${REACT_APP_API_URL}/search/states?pageLimit=50${county}${searchValue}`
    );
    setListOfState(response?.data?.data?.result)
    return response?.data?.data?.result;
        
   
      } catch (error) {
        console.log('Error in while getting States', error)
      }
    };

  const searchAPI = (type,search='',payload) =>{
      return new Promise(async resolve => {
     
         try {
           const result = await reqInstance.post(`${REACT_APP_SEARCH_API_URL}/search/${type}`, { ...payload, name: undefined, q: search });
           setSearchList(result?.data?.data?.hits?.hits?.map(item => item?._source))
           resolve(result?.data?.data?.hits?.hits?.map(item => item?._source))
         } catch (error) {
           console.log(`Error in getting list of ${type} =>`, error?.message)
           ErrorMessage(error?.message)
           setSearchList([])
         }
       })
     }

     const getCityData = async (stateId, search) => {
      if (stateId) {
        let response = await getListOfCity('', stateId, search)
        if (response?.length && search) {
          let cityObj = response[0]
          if (
            cityObj?.name?.toLowerCase() == search?.toLowerCase() ||
            response?.length == 1
          ) {
            if (cityObj) {
              form.setFieldsValue({cityId :cityObj?._id})
              form.setFieldsValue({city :cityObj?.name})
  
            }
         
  
          }
        }
        setCityList(response)
      } else {
        setCityList([])
      }
    }

    const getListOfCity = (countyId, stateId, search)  => {
      let county = countyId ? `&countryId=${countyId}` : ''
      let state = stateId ? `&stateId=${stateId}` : ''
      let searchValue = search ? `&search=${search}` : ''

      return new Promise(async resolve => {
        try {
          const data = await reqInstance.get(
             `${REACT_APP_API_URL}/search/cities?pageLimit=50${county}${state}${searchValue}`
           )

          setListOfCity(data?.data?.data?.result)
          if (data?.data?.data?.result?.length === 0) {
            !searchValue && ErrorMessage('No cities available for selected state!')
          }
          resolve(data?.data?.data?.result)
        } catch (error) {
          console.log('Error in while getting city', error)
        }
      })

    }

  const onStateChange = (data, option) => {
    setStateName(data)
    setCollegeID('');
    setCollegeName('');
    setStateId(option?.option?._id)
    setCityName('')
    getListOfState('6364ed8ae0338a14ae729651',"")
    getCityData(option?.option?._id)
    form.setFieldsValue({
        state: data,
        stateId: option?.option?._id,
        city: '',
        cityId: '',
        college: '',
      })
      getInstituteData(data,"")
    }

  const onCityChange = (data, option) => {
      setCityName(data)
      setCollegeID('');
      setCollegeName('');
      form.setFieldsValue({
         city: data, 
         cityId: option?.option?._id,   
         college: '',
      },
      )
      getCityData(stateId)
      getInstituteData(stateName,data)
    }

    const onCollegeNameChange = (value, option) => {
      setCollegeID(option?.id);
      form.setFieldsValue({ college: value });
      setCollegeName(value);
      setClgName(option?.name)
  
      if (option && option.option && option.option.city && option.option.state) {
        const collegeCity = option.option.city;
        const collegeState = option.option.state;
          
        setStateName(collegeState);
        setCityName(collegeCity);
        fetchStateCity(collegeState, collegeCity)
      } 
      else {
        setStateName(null);
        setCityName(null);
        setCollegeID(null);
        form.setFieldsValue({ college: "" });
        form.setFieldsValue({ city: "" });
    }
  
      setCourseId(null);
      setDegreeName("");
      setDepartmentName("");
      setShowSpecialization(false);
      setSpecialisationName("");
  
      form.setFieldsValue({ educationLevel: "" });
      setEducationLevelName(null)
  
      form.setFieldsValue({ degree: "" });
      form.setFieldsValue({ department: "" });
      form.setFieldsValue({ specialisation: "" });
      setSearchCollege("");
    };

    const onDegreeChange = (data, option) => {
      setDegreeSeach("");
      if (data === "Other") {
        setShowOtherInputDegree(true);
        if (data != undefined) {
          setCourseId(option?.option?.degreeId);
          setDepartmentList([]);
          setDegreeName(data);
          setDepartmentName("");
          setSpecialisationName("");
          form.setFieldsValue({ department: "" });
          form.setFieldsValue({ specialisation: "" });
          form.setFieldsValue({ degree: null });
          form.setFieldsValue({ degreeId: option?.option?.id });
          form.setFieldsValue({ domainId: option?.option?.domainId });
          form.setFieldsValue({ domain: option?.option?.domainName });
          form.setFieldsValue({ educationLevel: '' });
          setEducationLevelName(null)
          form.setFieldsValue({ streamId: "" });
          form.setFieldsValue({ specialisationId: "" });
        } else {
          setCourseId(null);
          setDegreeName("");
          setDepartmentName("");
          setSpecialisationName("");
          form.setFieldsValue({ educationLevel: "" });
          setEducationLevelName(null)
          form.setFieldsValue({ degree: "" });
          form.setFieldsValue({ department: "" });
          form.setFieldsValue({ specialisation: "" });
          form.setFieldsValue({ degreeId: "" });
          form.setFieldsValue({ streamId: "" });
          form.setFieldsValue({ specialisationId: "" });
        }
      } else {
        setShowOtherInputDegree(false);
  
        setDegreeSeach("");
        if (data != undefined) {
          setCourseId(option?.option?.degreeId);
          setDepartmentList([]);
          setDegreeName(option?.option?.name);
          setDepartmentName("");
          setSpecialisationName("");
          form.setFieldsValue({ department: "" });
          form.setFieldsValue({ specialisation: "" });
          form.setFieldsValue({ degree: option?.option?.name });
          form.setFieldsValue({ degreeId: option?.option?.id });
          form.setFieldsValue({ domainId: option?.option?.domainId });
          form.setFieldsValue({ domain: option?.option?.domainName });
          form.setFieldsValue({ educationLevel: option?.option?.type });
  
          form.setFieldsValue({ streamId: "" });
          form.setFieldsValue({ specialisationId: "" });
        } else {
          setCourseId(null);
          setDegreeName("");
          setDepartmentName("");
          setSpecialisationName("");
          form.setFieldsValue({ educationLevel: "" });
          setEducationLevelName(null)
          form.setFieldsValue({ degree: "" });
          form.setFieldsValue({ department: "" });
          form.setFieldsValue({ specialisation: "" });
          form.setFieldsValue({ degreeId: "" });
          form.setFieldsValue({ streamId: "" });
          form.setFieldsValue({ specialisationId: "" });
        }
      }
    };

    const onEducationDetailsChange = (data, option) => {
      setEducationLevelName(data);
      if (data === "diploma") {
        form.setFieldsValue({ educationLevel: "diploma" });
      } else if (data === "p_g_diploma") {
        form.setFieldsValue({ educationLevel: "p_g_diploma" });
      } else if (data === "ug") {
        form.setFieldsValue({ educationLevel: "ug" });
      } else if (data === "pg") {
        form.setFieldsValue({ educationLevel: "pg" });
      } else if (data === "pd") {
        form.setFieldsValue({ educationLevel: "pd" });
      }
    };
    
    const onDepartmentChange = (data, option) => {
      if (data === "OTHERS") {
        setShowOtherInputDepartment(true);
        setDepartmentSeach("");
        setDepartmentName(data);
        setSpecialisationName("");
        form.setFieldsValue({ specialisation: "" });
        form.setFieldsValue({ department: null });
        form.setFieldsValue({ streamId: option?.option?.id });
        form.setFieldsValue({ specialisationId: "" });
      } else {
        setShowOtherInputDepartment(false);
        setDepartmentSeach("");
        setDepartmentName(option?.option?.name);
        setSpecialisationName("");
        form.setFieldsValue({ specialisation: "" });
        form.setFieldsValue({ department: option?.option?.name });
        form.setFieldsValue({ streamId: option?.option?.id });
        form.setFieldsValue({ specialisationId: "" });
      }
    };
  
    const onSpecialisationChange = (data, option) => {
      if (data === "OTHERS") {
        setShowOtherInputSpecialisation(true);
        setSpecialisationName(data);
        form.setFieldsValue({ specialisation: null });
        form.setFieldsValue({ specialisationId: option?.option?.id });
      } else {
        setShowOtherInputSpecialisation(false);
        setSpecialisationName(option?.option?.name);
        form.setFieldsValue({ specialisation: option?.option?.name });
        form.setFieldsValue({ specialisationId: option?.option?.id });
            }
    };

    const onStartMonthChange = (data, option) => {
      setStartMonth(data);
      form.setFieldsValue({ startMonth: data });
    };
    const onStartYearChange = (data, option) => {
      setStartYear(data);
      form.setFieldsValue({ startYear: data });
    };
  
    const onEndMonthChange = (data, option) => {
      setEndMonth(data);
      form.setFieldsValue({ endMonth: data });
    };
    const onEndYearChange = (data, option) => {
      setEndYear(data);
      form.setFieldsValue({ endYear: data });
    };
    useEffect(() => {
      if (startMonth && startYear) {
        let date = startMonth + "/" + startYear;
        console.log("Setting start date:", date);
        form.setFieldsValue({ startedOn: date });
        setStartDate(date);
      }
    }, [startMonth, startYear]);
  
    useEffect(() => {
      if (endMonth && endYear) {
        let date = endMonth + "/" + endYear;
        console.log("Setting end date:", date);
        form.setFieldsValue({ endedOn: date });
        setEndDate(date);
      }
    }, [endMonth, endYear]);

    const getInstituteData = (state, city) => {
      const filters = [];
      if (city) {
        filters.push({
          type: "match",
          field: "city",
          value: city
        });
      }
      if (state) {
        filters.push({
          type: "match",
          field: "state",
          value: state
        });
      }
    
      const searchConfigWithFilters = {
        ...searchConfig?.institutesCampuses,
        filters: [...searchConfig?.institutesCampuses?.filters,...filters]
      };
    
      searchAPI(
        searchConfig?.institutesCampuses?.name,
        '',
        filters.length > 0 ? searchConfigWithFilters : searchConfig?.institutesCampuses
      ).then(results => setCollegeSearchList(results))
      .catch(error => console.error("Error searching colleges:", error));
    }

    const delayToFetch = useCallback(
      debounce((data, state, city) => {
        const filters = [];
        if (city) {
          filters.push({
            type: "match",
            field: "city",
            value: city
          });
        }
        if (state) {
          filters.push({
            type: "match",
            field: "state",
            value: state
          });
        }
        
        searchAPI(
          searchConfig?.institutesCampuses?.name,
          data,
          {
            ...searchConfig?.institutesCampuses,
            filters: [...searchConfig?.institutesCampuses?.filters,...filters]
          }
        ).then(results => setCollegeSearchList(results))
        .catch(error => console.error("Error searching colleges:", error));
        form.setFieldsValue({ college: '' });
      }, 500),
      []
    );

    const validator = (rule, value) => {
      const startMonthField = "startMonth";
      const startYearField = "startYear";
      const endMonthField = "endMonth";
      const endYearField = "endYear";
      const startDate = moment(`${startYear}-${startMonth}-01`);
      const endDate = moment(`${endYear}-${endMonth}-01`);
      const now = moment();
  
      if (!startMonth === "") {
        setDateErrorMsg("Please select Start Month");
        form.setFieldsValue({
          [startMonthField]: { error: "Please select Start Month" },
        });
      } else if (startMonth && startYear === "") {
        setDateErrorMsg("Please select Start Year");
        form.setFieldsValue({
          [startYearField]: { error: "Please select Start Year" },
        });
      } else if (startMonth && startDate > now) {
        setDateErrorMsg("Start Date cannot be in the future");
        form.setFieldsValue({
          [startYearField]: { error: "Start Date cannot be in the future" },
        });
      } else if (startMonth && endMonth === "") {
        setDateErrorMsg("Please select End Month");
        form.setFieldsValue({
          [endMonthField]: { error: "Please select End Month" },
        });
      } else if (startMonth && endYear === "") {
        setDateErrorMsg("Please select End Year");
        form.setFieldsValue({
          [endYearField]: { error: "Please select End Year" },
        });
      } else if (startMonth && endDate < startDate) {
        setDateErrorMsg("End date cannot be before start date");
        form.setFieldsValue({
          [endYearField]: { error: "End date cannot be before start date" },
        });
      } else {
        setDateErrorMsg("");
        return Promise.resolve();
      }
    };

    const delayStateToFetch = useCallback(
      debounce(data => {
        getListOfState('6364ed8ae0338a14ae729651',data)
        form.setFieldsValue({
          state: '',
          city: '',
          cityId: '',
          college: '',
        })
      }, 500),
      []
    )

    const delayCityToFetch = useCallback(
      debounce((state, data) => {
        form.setFieldsValue({
          city: '', 
          cityId: '',   
          college: '',
       },
       )
       getCityData(form.getFieldValue(['stateId']), data)
      }, 500),
      []
    )

    const delayToFetchDegree = useCallback(
      debounce((data) => {
        setDegreeSeach(data);
      }, 500),
      []
    );

    const delayToFetchDepartment = useCallback(
      debounce((data) => {
        setDepartmentSeach(data);
      }, 500),
      []
    );
    const delayToFetchSpecialisation = useCallback(
      debounce((data) => {
        setSpecialisationSeach(data);
      }, 500),
      []
    );

    function debounce(fn, delay) {
      let timer;
      return function () {
        let context = this,
          args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      };
    }

  const handleProfileChange = (selectedProfile) => {
    setSelectedProfile(selectedProfile);
    localStorage.setItem('profile', selectedProfile);
  };

  const getHeight = () => {
    const baseHeight = selectedProfile === 'fresher' ? 
      (isMobile ? '2000px' : isTab ? '220px' : '1650px') : '';
  
    if (selectedProfile !== 'fresher') {
      return showOtherInputDegree || showOtherInputDepartment || showOtherInputSpecialisation
        ? showOtherInputDepartment && showOtherInputSpecialisation
          ? '2250px'
          : '2150px'
        : baseHeight;
    }
  
    const extraHeight = showOtherInputDegree ? 180 : 0;
    const additionalHeight = showOtherInputDepartment || showOtherInputSpecialisation ? 250 : 0;
  
    return (parseInt(baseHeight, 10) + extraHeight + additionalHeight) + 'px';
  };

  const getHeightgrey = () => {
    const baseHeight = selectedProfile === 'fresher' ? 
      (isMobile ? '1800px' : isTab ? '2100px' : '1650px') : '';
  
    if (selectedProfile !== 'fresher') {
      return showOtherInputDegree || showOtherInputDepartment || showOtherInputSpecialisation
        ? showOtherInputDepartment && showOtherInputSpecialisation
          ? '2250px'
          : '2150px'
        : baseHeight;
    }
  
    const extraHeight = showOtherInputDegree ? 180 : 0;
    const additionalHeight = showOtherInputDepartment || showOtherInputSpecialisation ? 250 : 0;
  
    return (parseInt(baseHeight, 10) + extraHeight + additionalHeight) + 'px';
  };

  const onFinishHandler = (values) => {
    if (selectedProfile === 'fresher') {
      submitHandler(values);
    } else {
      handleSubmit(values);
    }
  };

  return (
    <Container className='fromsmalap' style={{ marginTop: '300px' }}>
       <div className='regisform'>
        <div className={`bluformbox ${selectedProfile === 'fresher' ? 'fres-blufrmbx' : ''}`} style={{ height: getHeight() }}>
          <div className={`greyformbox ${selectedProfile === 'fresher' ? 'fres-grefrmbx' : ''}`} style={{ height: getHeightgrey() }}>
            {selectedProfile !== 'fresher' && selectedProfile !== 'experienced' && selectedProfile !== 'college' && selectedProfile !== 'training partner' && selectedProfile !== 'corporate' && (
              <>
                <h1 className="bluformboxh1">Let’s get started!</h1>
                <p className="bluformboxp1">Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.</p>
              </>
            )} 
            <Form layout="vertical" onFinish={onFinishHandler} scrollToFirstError form={form} ref={formRef}>
            <div className={`logcontent ${selectedProfile === 'fresher' ? 'fresher-cotent' : ''}`}>
              {(selectedProfile === 'college' || selectedProfile === 'training partner'  || selectedProfile === 'corporate') && (
                <>
                <span className='titfrmcol d-flex justify-content-center align-items-center'><span className='frmcoltxt'>Request a Demo</span></span>
                </>
              )}
              {(selectedProfile === 'fresher' || selectedProfile === 'experienced') && (
                <>
                <span className={`titfrmfre d-flex justify-content-center align-items-center ${selectedProfile === 'fresher' ? 'frestag-style' : ''}`}><span className='frmfretxt'>Registration Details</span></span>
                <Control flexStart top="40px" bottom="20px" column >
                <Button
                  icon={<Avatar src={GoogleIcon} />}
                  onClick={handleGoogleSignInClick}
                  style={{
                    width: '100%',
                    height: '50px',
                    backgroundColor: '#E8EDFC', 
                    color: '#3249D7',  
                    borderRadius: '5px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                  }} 
                  disable={submitLoading}>
                  Sign / Login With Google
                </Button>
                </Control>
                </>
                )}
                <Form.Item  label={<CustomLabel>Pick your profile *</CustomLabel>} name="profile" className="profilefld" style={{ marginBottom: '0px' }}>
                  <Profile value={formData.profile} onChange={(value) => { setSelectedProfile(value); handleChange('profile', value); handleProfileChange(value); }} />
                </Form.Item>
                <Form.Item label={<CustomLabel>Your name *</CustomLabel>} style={{ marginBottom: '-25px' }}>
                <div className="namefld">
                <Form.Item name="firstName" style={{ marginRight: 10 }}>
                  <FirstName value={formData.firstName} onChange={(value) => handleChange('firstName', value)} />
                </Form.Item>
                <Form.Item name="lastName" className="lastnamefld">
                  <LastName value={formData.lastName} onChange={(value) => handleChange('lastName', value)} />
                </Form.Item>
                </div>
                </Form.Item>
                <Form.Item label={<CustomLabel>Contact *</CustomLabel>} style={{ marginBottom: '-25px' }}>
                  <div className="contactfld">
                    <Form.Item name="email" style={{ marginRight: 10 }}>
                      <Email value={formData.email} onChange={(value) => handleChange('email', value)} />
                    </Form.Item>
                    <Form.Item name="phone" className="phonefld">
                      <PhoneNum value={formData.phone} onChange={(value) => handleChange('phone', value)} />
                    </Form.Item>
                  </div>
                </Form.Item>
                {(selectedProfile !== 'fresher' && selectedProfile !== 'experienced') && (
                <>
                <Form.Item label={<CustomLabel>Other details *</CustomLabel>} style={{ marginBottom: '-25px' }}>
                  <div className="otherfld">
                    <Form.Item name="organization" style={{ marginRight: 10 }} className="orgafld">
                      <Organization value={formData.organization} onChange={(value) => handleChange('organization', value)} />
                    </Form.Item>
                    <Form.Item name="location" className="locafld">
                      <Location value={formData.location} onChange={(value) => handleChange('location', value)} />
                    </Form.Item>
                  </div>
                </Form.Item>
                </>
                )}
                { selectedProfile === 'fresher' && (
                  <>
                    <Form.Item  style={{ marginBottom: '0px' }}>
                      <div className="destinfld">
                      <Form.Item label={<CustomLabel>State *</CustomLabel>} style={{ marginRight: 10, }} className="statefld">
                      <FormItem name={"state"} />
                        <AntdSelect
                          allowClear={true}
                          className="statepick"
                          placeholder="Select the state"
                          name={'stateId'}
                          rules={[
                            {
                              validator: async (_, value) => {
                                if (!form.getFieldValue('state') || !form.getFieldValue('stateId')) {
                                  return Promise.reject('Please select state!');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          OptionData={listOfState}
                          valueParam="name"
                          nameParam="name"
                          onChange={onStateChange}
                          value={stateName}
                          showSearch={true}
                          onSearch={data => delayStateToFetch(data)}
                          isKey={true}
                        />
                    </Form.Item>
                    <Form.Item label={<CustomLabel>City *</CustomLabel>} className="cityfld">
                          <FormItem name={"cityId"} />
                            <AntdSelect
                              allowClear={true}
                              className="citypick"
                              placeholder="Select the city"
                              name={'city'}
                              rules={[
                                {
                                  validator: async (_, value) => {
                                    if (!form.getFieldValue('city') || !form.getFieldValue('cityId')) {
                                      return Promise.reject('Please select city!');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              OptionData={cityList}
                              valueParam="name"
                              nameParam="name"
                              onChange={onCityChange}
                              value={cityName}
                              disabled={!stateName}
                              showSearch={true}
                              onSearch={data => delayCityToFetch(selectedStateId, data)}
                              isKey={true}
                            />
                          </Form.Item>
                        </div>
                      </Form.Item>
                    <div className="colgfld">
                    <Form.Item label={<CustomLabel>College *</CustomLabel>} style={{ marginBottom: '0px', marginBottom: '25px' }} className="">
                      <FormItem>
                        <AntdSelect
                          className="collegepick"
                          allowClear={true}
                          width="100%"
                          name={"college"}
                          rules={[
                            {
                              validator: async (_, value) => {
                                if (!collegeName) {
                                  return Promise.reject("Please select College Name!");
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          OptionData={collegeSearchList}
                          nameParam="campusName"
                          valueParam="id"
                          bordered={false}
                          placeholder="Eg. Select College Name"
                          onChange={onCollegeNameChange}
                          showSearch={true}
                          value={collegeName}
                          onSearch={(data) => delayToFetch(data, stateName, cityName)}
                          filterOption={false}
                          isKey={true}
                        />
                      </FormItem>
                    </Form.Item>
                    </div>
                    <div className="degfld">
                    <Form.Item label={<CustomLabel>Degree *</CustomLabel>} style={{ marginBottom: '25px' }} className="">
                      <FormItem name={"domainId"} />
                      <FormItem name={"domain"} />
                      <AntdSelectKey
                        className="degreepick"
                        allowClear={true}
                        rules={[
                          {
                            validator: async (_, value) => {
                              if (!degreeName) {
                                return Promise.reject(new Error("Please Select Degree!"));
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                        placeholder="Eg. Bachelor of Engineering"
                        name={"degreeId"}
                        OptionData={completeCrudDegreeList || []}
                        valueParam="id"
                        nameParam="name"
                        onChange={onDegreeChange}
                        value={form.getFieldValue(['degreeId']) === "OTHERS" ? "Other" : form.getFieldValue(['degree'])}
                        onSearch={(data) => delayToFetchDegree(data)}
                        disabled={!collegeName}
                        showSearch={true}
                        isShortName={false}
                        filterOption={false}
                      />

                    {showOtherInputDegree && (
                      <>
                        <FormItem style={{marginTop : '25px'}}>
                            <CustomLabel>Please select the Education Level *</CustomLabel>
                            <AntdSelect
                              className="edupick"
                              allowClear={true}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a Education Level!",
                                },
                              ]}
                              placeholder="Eg. Undergraduate"
                              name={"educationLevel"}
                              OptionData={educationDetails || []}
                              valueParam="value"
                              nameParam="label"
                              onChange={onEducationDetailsChange}
                              value={educationLevelName}
                              showSearch={true}
                              filterOption={(inputValue, option) =>
                                option?.name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
                              }
                            />
                        </FormItem>

                        <FormItem style={{marginTop : '25px'}}>
                            <CustomLabel>Please enter the Degree name</CustomLabel>
                            <AntdInput
                              className="educpick"
                              placeholder="Enter the Degree name"
                              name={"degree"}
                              value={form.getFieldValue(['degree'])}
                              onChange={(e) => form.setFieldValue(['degree'], e.target.value)}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the degree name.",
                                },
                              ]}
                            />
                        </FormItem>
                      </>
                    )}
                  </Form.Item>
                  </div>
                  <div className="deptfld">
                  <Form.Item label={<CustomLabel>Department *</CustomLabel>} style={{ marginBottom: '0px' }} className="">
                  <AntdSelect
                    className="departpick"
                    allowClear={true}
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (!departmentName) {
                            return Promise.reject(new Error("Please Select Department!"));
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    placeholder="Eg. Computer Science Engineering"
                    name={"streamId"}
                    OptionData={completeCrudDepartmentList || []}
                    valueParam="id"
                    nameParam="name"
                    onChange={onDepartmentChange}
                    value={form.getFieldValue(['streamId']) === "OTHERS" ? "Other" : form.getFieldValue(['department'])}
                    showSearch={true}
                    onSearch={(data) => delayToFetchDepartment(data)}
                    disabled={!degreeName}
                    filterOption={false}
                    isKey={true}
                  />

                {showOtherInputDepartment && (
                  <>
                  <FormItem style={{marginTop : '25px'}} >
                      <CustomLabel>Please enter the Department name</CustomLabel>
                      <AntdInput
                        className="depamtpick"
                        placeholder="Enter the Department name"
                        name={"department"}
                        value={form.getFieldValue(['department'])}
                        onChange={(e) => {
                          form.setFieldValue(['department'], e.target.value);
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the department name.",
                          },
                        ]}
                      />
                  </FormItem>
                  </>
                )}

                <br />
                <Alert style={{ marginBottom: '15px', marginTop: '10px' }}
                  message="Note: If you have specialisation please select"
                  type="info"
                />
              </Form.Item>
              </div>
                <div className="specfld">
                <Form.Item label={<CustomLabel>Specialisation</CustomLabel>} name="specialisation" style={{ marginBottom: '25px' }} className="">
                  <FormItem>
                    <AntdSelect
                      className="specpick"
                      allowClear={true}
                      placeholder="Eg. Artificial Intelligence"
                      name={"specialisationId"}
                      OptionData={completeCrudSpecialisationList || []}
                      valueParam="id"
                      nameParam="name"
                      onChange={onSpecialisationChange}
                      value={
                        form.getFieldValue(['specialisationId']) === "OTHERS"
                          ? "Other"
                          : form.getFieldValue(['specialisation'])
                      }
                      onSearch={(data) => delayToFetchSpecialisation(data)}
                      showSearch={true}
                      disabled={!departmentName}
                      filterOption={false}
                      isKey={true}
                    />
                  </FormItem>

                  {showOtherInputSpecialisation && (
                    <>
                    <FormItem>
                      <Control flexStart top="25px" column>
                        <CustomLabel>Please enter the Specialisation</CustomLabel>
                        <AntdInput
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Specialisation name.",
                            },
                          ]}
                          className="specipick"
                          placeholder="Enter the Specialisation"
                          name={"specialisation"}
                          value={form.getFieldValue(['specialisation'])}
                          onChange={(e) => {
                            form.setFieldValue(['specialisation'], e.target.value);
                          }}
                        />
                      </Control>
                    </FormItem>
                  </>
                  )}
                </Form.Item>
              </div>
  <FormItem name={"degreeId"} />
  <FormItem name={"degree"} />
  <FormItem name={"streamId"} />
  <FormItem name={"department"} />
  <FormItem name={"specialisationId"} />
  <FormItem name={"specialisation"} />
  <FormItem name={"educationLevel"} />
                    <Form.Item  style={{ marginBottom: '0px' }}>
                      <div className="startfld">
                        <Form.Item label={<CustomLabel>Start Month</CustomLabel>}  style={{ marginRight: 10, }} className="srtmthfld">
                        <AntdSelect
                          className="starmothpick"
                          placeholder="Eg. March"
                          name="startMonth"
                          rules={[
                            {
                              validator: validator,
                            },
                          ]}
                          OptionData={MonthList}
                          valueParam="value"
                          nameParam="name"
                          onChange={(value) => {
                            console.log("Selected startmonth value:", value);
                            onStartMonthChange(value);
                            form.validateFields(["startMonth"], (errors, values) => {
                              if (!errors) {
                                setDateErrorMsg("");
                              } else {
                                setDateErrorMsg(errors.startMonth.errors[0].message);
                              }
                            });
                            validator(null, null, () => {});
                          }}
                          value={startMonth}
                          allowClear={true}
                          showSearch={true}
                          filterOption={(inputValue, option) =>
                            option?.name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
                          }
                        />
                        </Form.Item>
                        <Form.Item label={<CustomLabel>Start Year</CustomLabel>}  className="srtyerfld">
                        <AntdSelect
                          className="staryerpick"
                          placeholder="Eg. 2020"
                          name="startYear"
                          rules={[
                            {
                              validator: validator,
                            },
                          ]}
                          OptionData={years}
                          valueParam="value"
                          nameParam="name"
                          onChange={(value) => {
                            console.log("Selected startyear value:", value);
                            onStartYearChange(value);
                            form.validateFields(["startYear"], (errors, values) => {
                              if (!errors) {
                                setDateErrorMsg("");
                              } else {
                                setDateErrorMsg(errors.startYear.errors[0].message);
                              }
                            });
                            validator(null, null, () => {});
                          }}
                          value={startYear}
                          allowClear={true}
                          showSearch={true}
                        />
                        </Form.Item> 
                      </div> 
                    </Form.Item>
                  <FormItem name={"startedOn"} />
                    <Form.Item  style={{ marginBottom: '0px' }}>
                      <div className="endfld">
                        <Form.Item label={<CustomLabel>End Month</CustomLabel>}  style={{ marginRight: 10 }} className="endmthfld">
                        <AntdSelect
                          className="endmthpick"
                          placeholder="Eg. March"
                          name="endMonth"
                          rules={[
                            {
                              validator: validator,
                            },
                          ]}
                          OptionData={MonthList}
                          valueParam="value"
                          nameParam="name"
                          onChange={(value) => {
                            console.log("Selected endmonth value:", value);
                            onEndMonthChange(value);
                            form.validateFields(["endMonth"], (errors, values) => {
                              if (!errors) {
                                setDateErrorMsg("");
                              } else {
                                setDateErrorMsg(errors.endMonth.errors[0].message);
                              }
                            });
                            validator(null, null, () => {});
                          }}
                          value={endMonth}
                          allowClear={true}
                          showSearch={true}
                          filterOption={(inputValue, option) =>
                            option?.name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
                          }
                        />
                        {dateErrorMsg && <p style={{ color: "red" }}>{dateErrorMsg}</p>}
                        </Form.Item>
                        <Form.Item label={<CustomLabel>End Year</CustomLabel>}  className="endyerfld">
                        <AntdSelect
                          className="endyerpick"
                          placeholder="Eg. 2020"
                          name="endYear"
                          rules={[
                            {
                              validator: validator,
                            },
                          ]}
                          OptionData={endYears}
                          valueParam="value"
                          nameParam="name"
                          onChange={(value) => {
                            console.log("Selected endyear value:", value);
                            onEndYearChange(value);
                            form.validateFields(["endYear"], (errors, values) => {
                              if (!errors) {
                                setDateErrorMsg("");
                              } else {
                                setDateErrorMsg(errors.endYear.errors[0].message);
                              }
                            });
                            validator(null, null, () => {});
                          }}
                          value={endYear}
                          allowClear={true}
                          showSearch={true}
                        />
                        </Form.Item> 
                      </div>
                    </Form.Item>
                  <FormItem name={"endedOn"} />
                  </>
                )}
                  <div className={`logsbutton ${selectedProfile === 'fresher' ? 'fres-style' : ''}`}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size='large'
                      htmlType="submit"
                      className="formsig"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {selectedProfile === 'fresher' && <FresherForm />}
      {selectedProfile === 'experienced' && <ExperiencedForm />}
      {selectedProfile === 'college' && <CollegesFrom />}
      {selectedProfile === 'training partner' && <PartnersForm />}
      {selectedProfile === 'corporate' && <CorporateFrom />}
    </Container>
  );  
};

export default StudentForm;