import React from 'react'
import styled from 'styled-components'
import { Select as AntdSelect } from 'antd'
import Label from './Label'
import Flex from '../Flex'
import SelectArrowIcon from '../../icons/SelectArrowIcon'
import CrossIcon from '../../icons/CrossIcon'
import { Form as AntdForm } from 'antd'
import './SelectCss.css'

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${props =>
    props.marginbottom ? props.marginbottom : '0px'} !important;
  width: 100%;
  .ant-form-item-explain-error {
    padding-left: ${props => (props.padding ? '15%' : '0')};
  }
`

const { Option } = AntdSelect

const StyledSelect = styled(AntdSelect)`
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #010e30;
  .ant-select-selector {
    background-color: ${props =>
      !props.disabled
        ? props.backgroundcolor
          ? '#fafafb'
          : '#ffffff'
        : '#f5f5f5'} !important;
    border-radius: 8px !important;
    border-color: #e9e9e9 !important;
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    background-color: ${props =>
      !props.disabled
        ? props?.mode === 'multiple' || props?.mode === 'tags'
          ? '#ebecee'
          : '#ffffff'
        : '#f5f5f5'};
    border-radius: 6px;
    color: ${props => !props.disabled && '#010e30'};
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    background-color: ${props =>
      !props.disabled
        ? props?.mode === 'multiple' || props?.mode === 'tags'
          ? '#ebecee'
          : '#ffffff'
        : '#f5f5f5'};
  }
  :hover {
    border-radius: 6px;
    color: #010e30;
  }
  .ant-select-dropdown-placement-bottomLeft {
    width: 1000px !important;
  }
  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .ant-select-clear{
    font-size:18px;
  }
`
const LabelWrapper = styled.div`
  padding-bottom: 8px;
`

const Select = ({
  OptionData,
  placeholder,
  labelStyle,
  onChange,
  label,
  value,
  position = 'relative',
  width = '100%',
  defaultValue,
  loading,
  bordered = true,
  valueParam = 'id',
  nameParam = 'name',
  showSearch = false,
  backgroundcolor,
  name,
  rules,
  mode,
  disabled = false,
  addOther = false,
  isKey = false,
  onSearch,
  ...rest
}) => {

  const options = Array.isArray(OptionData) ? OptionData : [];

  return (
    <FormItem name={name} rules={rules} {...rest}>
      <Flex
        column
        style={{
          width: width,
          position: position,
        }}
        id="select"
      >
        {label && (
          <LabelWrapper>
            <Label labelStyle={labelStyle}>{label}</Label>
          </LabelWrapper>
        )}

        <StyledSelect
          showSearch={showSearch}
          suffixIcon={<SelectArrowIcon />}
          placeholder={placeholder}
          onChange={onChange}
          size={'large'}
          value={value || undefined}
          loading={loading}
          bordered={bordered}
          defaultValue={defaultValue}
          backgroundcolor={value ? false : true}
          removeIcon={<CrossIcon />}
          mode={mode}
          getPopupContainer={() => document.getElementById('select')}
          disabled={disabled}
          onSearch={onSearch}
          {...rest}
        >
          {options?.map(option => (
            <Option
            className="custom-option" 
              style={{
                padding: '10px 12px',
              }}
              key={isKey ? option?.id : option[nameParam]}
              value={option[valueParam]}
              id={option?.id}
              name={option[nameParam]}
              option={option}
              formName={name}
            >
              {option[nameParam]} {option?.city ? ' , ' + option?.city : ''}
            </Option>
          ))}
          {addOther && (
            <Option
              style={{
                padding: '10px 12px',
              }}
            >
              Others
            </Option>
          )}
        </StyledSelect>
      </Flex>
    </FormItem>
  );
};


export default Select