import styled from 'styled-components'
import Flex from '../JobContainer/Flex';
import { COLORS } from '../theme';
import { FONT } from '../theme/font';
import { Divider, Col,Row, Menu } from 'antd'
// import { StyleLeft } from '../../Drives/Style/style'
export const SectionWrapper = styled(Flex)`
  width: 100%;
`
export const OuterDiv = styled.div`
  border: 1px solid ${COLORS.PAGE_BORDER};
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 6px rgba(123, 97, 255, 0.15);
  margin: 30px 100px 20px 100px;
  border-radius: 10px;
`

export const TopNav = styled(Flex)`
  height: 160px;
  background-color: #fafafb;
  padding: 30px;
  flex-direction: column;
`
export const TopNav1 = styled(Flex)`
  background-color: #fafafb;
  flex-direction: column;
`

export const SectionName = styled.div`
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const TitleMedium = styled.h2`
  font-style: normal;
  font-weight: ${props => (props.fontweight ? props.fontweight : FONT.FW_700)};
  font-size: ${FONT.F_2XL};
  line-height: 30px;
  color: ${COLORS.GREY_T_30};
  margin-bottom: 0;
`
export const SubtitleLarge = styled.h2`
  font-style: normal;
  font-weight: ${FONT.FW_700};
  font-size: ${FONT.F_LG};
  line-height: 24px;
  padding-left: 10px
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
  margin-bottom: 5px;
`
export const GrayTextSmall = styled.p`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_SM};
  line-height: 30px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '0px')};
`

export const InfoText = styled.p`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${props => (props.fontsize ? props.fontsize : FONT.F_BASE)};
  line-height: 30px;
  color: ${COLORS.GREY_T_50_2};
  margin-bottom: 0;
  margin-left: ${props => (props.marginleft ? props.marginleft : '0px')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '')};
  word-wrap:break-word;
`

export const YellowBox = styled(Flex)`
  padding: 10px;
  background: ${COLORS.ORANGE_T_92};
  border: 1px solid ${COLORS.ORANGE_T_60};
  border-radius: 10px;
  gap: 15px;
`

export const FlexWrap = styled(Flex)`
  position: relative;
  gap: ${props => (props.gap ? props.gap : '0px')};
  padding: ${props => (props.padding ? props.padding : '0px')};
  background-color: ${props => (props.bgColor ? props.bgColor : 'transparent')};
`
export const GrayText = styled.p`
  font-style: normal;
  font-weight: ${FONT.FW_500};
  font-size: ${FONT.F_SM};
  line-height: 18px;
  color: ${COLORS.GREY_T_60};
  margin-bottom: 0;
`

export const SubtitleSmall = styled.p`
  font-style: normal;
  font-weight: ${props => (props.fontweight ? props.fontweight : FONT.FW_400)};
  font-size: ${props => (props.fontsize ? props.fontsize : FONT.F_SM)};
  line-height: 20px;
  color: ${props => (props.color ? props.color : COLORS.GREY_T_30)};
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '5px')};
  span {
    font-style: normal;
    font-weight: ${FONT.FW_600};
    line-height: 18px;
    color: ${COLORS.GREY_T_10};
    margin-right: 5px;
  }
`

export const SubtitleMedium = styled.h2`
  font-style: normal;
  font-weight: ${FONT.FW_600};
  font-size: ${FONT.F_BASE};
  line-height: 21px;
  color: ${COLORS.GREY_T_10};
  margin-bottom: 0;
`

export const VerticalDivider = styled(Divider)`
  height: 20px;
`

export const VerticalDividerMax = styled(Divider)`
  height: 40px;
`


export const FlexContain = styled(Flex)`
  padding: 15px 0px;
`

export const LeftCol = styled(Col)`
  padding: 10px;
`
export const Editor = styled.div`
  width: 75%;
  padding: 10px;
`
export const Address = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  opacity: 0.48;
`


export const NameAdd = styled(Flex)`
  gap: 3px;
  padding-left: 15px;
`
export const Container = styled(Flex)`
  padding-right: 20px;
`
export const Name = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #343e59;
`
export const BreadCrumns = styled.div`
  display: flex;
`
export const RowContainer = styled(Row)`
  padding: 20px;
`

export const EditorContent = styled.span`
  padding: 20px 0;
  * {
    line-height: 188%;
    color: #808697 !important;
  }
  p,
  ul,
  ol {
    margin-bottom: 10px;
  }

  li {
    line-height: 25px;
  }

`
export const PopHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding-top: 15px;
  padding-bottom: 5px;
`

export const PopHeading1 = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding-top: 15px;
  padding-bottom:10px;
`

export const PopSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #808697;
  padding-top: 5px;
`
export const Name1 = styled.div`
  font-weight: 600;
  font-size: 16px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  color: #343e59;
`
export const Address1 = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  opacity: 0.48;
`
export const AddressNew = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1b1f22;
  opacity: 0.48;
`
export const Frame = styled.div`
  padding: 20px;
`
export const ShareTitile = styled(Flex)`

font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
color: #343E59;
`
export const TopHeader = styled(Flex)`
  position: relative;
  width: 100%;
`
export const RowListIcon = styled(Flex)`
gap:10px;
`
export const ColListIcon = styled(Flex)`
gap:10x;
cursor:pointer;
`

export const RowListLabel = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
color: #343E59;
`

export const DropRow = styled(Row)`
width: 470px;
padding:20px;
border-radius: 10px;
background-color: #fff;
background-clip: padding-box;
    border-radius: 10px;
    outline: none;
    box-shadow: 0px 2px 10px rgb(99 116 135 / 20%);
}
`

export const CenteredDiv = styled.div`
width: 100%;
padding: 20px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-align: center;
color: #3D5A8080;
`

export const OneLinerDiv = styled.div`
font-weight: 500;
font-size: 16px;
line-height: 30px;
color: #808697;
text-transform: capitalize;

`

export const SocialMeadiaIconsFlex = styled(Flex)`
  gap: 10px;
  border-bottom: 1px solid ${COLORS.GREY_T_90};
  padding-bottom: 18px;
`



export const PopSubHeadingLI = styled.div`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #808697;
padding-top: 5px;
overflow-wrap: break-word;
height: ${({ expanded }) => (expanded ? "auto" : "200px")};
overflow: hidden;
`;

export const Stream = styled.div`

`

export const PopSubHeadingLIMore = styled.div`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #808697;
padding-top: 5px;
overflow-wrap: break-word;

overflow: hidden;
`;


 export const SubBold = styled.span`
  font-weight: bold;
  color: #343e59;

 `;

 export const Container1 = styled.div`
 height: ${props => (props.expanded ? "auto" : "250px")};

 overflow: hidden;
 overflow-wrap: break-word;
`;




export const Content = styled.div`
 font-style: normal;
 font-weight: 400;
 font-size: 14px;
 line-height: 25px;
 color: #808697;

`;

export const ExtraPadding= styled.div`
padding-bottom:8px;
`

export const ShowMoreText = styled.span`
  cursor: pointer;
  padding-top:6px;
  padding-bottom:6px;
  padding-left:10px;
  padding-right:10px;
  color: #000;
  display: ${props => (props.visible ? "inline-block" : "none")};
  border-radius: 10px;
  background-color: #e6e7ea;

`;

export const SkillList = styled.ul`
  padding-inline-start: 20px;
`;