import React from "react";

const DropDown = () => {
 return (
  <svg
   width="8"
   height="6"
   viewBox="0 0 8 6"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M3.3 4.8L0.699997 2.2C0.38333 1.88333 0.312663 1.521 0.487997 1.113C0.662663 0.704333 0.974997 0.5 1.425 0.5H6.575C7.025 0.5 7.33733 0.704333 7.512 1.113C7.68733 1.521 7.61666 1.88333 7.3 2.2L4.7 4.8C4.6 4.9 4.49166 4.975 4.375 5.025C4.25833 5.075 4.13333 5.1 4 5.1C3.86666 5.1 3.74166 5.075 3.625 5.025C3.50833 4.975 3.4 4.9 3.3 4.8Z"
    fill="#CCCFD6"
   />
  </svg>
 );
};

export default DropDown;
