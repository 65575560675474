import React,{ useEffect ,useRef } from "react";
import "./testimonialstyle.css";
import { Testimonialsbox1fun, Testimonialsbox2fun, Testimonialsbox3fun, Testimonialsbox4fun, Testimonialsbox5fun } from './testimonialsboxes';
import Fresherimg1 from "../../Assets/fretestimg/dheeraj.jpg";
import Fresherimg2 from "../../Assets/fretestimg/krishna.jpg";
import Fresherimg3 from "../../Assets/fretestimg/vallabhneni.jpg";
import Fresherimg4 from "../../Assets/fretestimg/nagesh.jpg";


function Testimonials(){
    const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('testimonials-visible');
          } else {
            entry.target.classList.remove('testimonials-visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = containerRef.current.querySelectorAll('.testimonial-container1, .testimonial-container2, .testimonial-container3, .testimonial-container4, .testimonial-container5');
    items.forEach((item) => {
      item.classList.add('testimonials-hidden');
      observer.observe(item);
    });

    return () => {
      items.forEach((item) => observer.unobserve(item));
    };
  }, []);

   

    return(
        <>
        <div className="testmainheaderfre">Real Voices, Real Success</div>
            <h1 className="testmainsubheader1fre">Here's what <br></br> our students say </h1>
        <div className="testionialsContainer" ref={containerRef}>
            <Testimonialsbox1fun 
        image={Fresherimg1} 
        text="PluginLive technologies have been an absolute game-changer for me! Thanks to their exceptional resources, I could seamlessly connect with key individuals for interviews regarding a job I had applied for. The process was incredibly smooth, and I owe it all to PluginLive technologies. They handled everything professionally, contacting me promptly and always being friendly and approachable." 
        name="Dheeraj Reddy"
        designation="Data Intern"
      />
      <Testimonialsbox3fun 
        image={Fresherimg2}
        text="I'm Krishna, currently thriving as an associate engineer at Lloyds Technology Centre in Hyderabad. Proud 2023 MCA graduate from the prestigious RV College of Engineering, Bengaluru. A big shout out to the phenomenal team at PluginLive Technology - their unwavering support in the hiring process has been a game-changer. From expertly shortlisting roles aligning with my skills to keeping me in the loop at every interview stage, I'm truly grateful for this incredible opportunity. Thank you PluginLive." 
        name=" K B Krishna"
        designation="Associate Engineer"
        inline
      />

      {/* <Testimonialsbox5fun 
        // image={Fresherimg1} 
        text="I'm Nagesh, a recent B tech Graduate from PES University Bangalore. I wanted to express my immense gratitude for the invaluable support in snagging that gig at Lloyds Technology Centre (Part of Lloyds Banking Group - UK) as Associate software engineer. PluginLive's assistance through the process made a significant difference, and I am truly thankful. I am thrilled about the job and couldn't have achieved this milestone without the support of the PluginLive team. Thank you so much PluginLive!!" 
        name="Nagesh BS"
        designation="Associate Engineer"
      /> */}

      <Testimonialsbox1fun 
        image={Fresherimg3}
        text="I appreciate PluginLive technologies for providing me with the opportunity to be part of Lloyd Technology Centre, allowing me to contribute my skills and knowledge to advance technology and innovation. PluginLive technologies has created a very supportive environment, enabling me to grow both personally and professionally. I am grateful for the chance to be a vital part of this transformative journey." 
        name="Vallabhneni Geethika"
        designation="Associate Engineer"
        inline
      />

        {/* <Testimonialsbox5fun 
        image={Fresherimg3}
        text="I appreciate PluginLive technologies for providing me with the opportunity to be part of Lloyd Technology Centre, allowing me to contribute my skills and knowledge to advance technology and innovation. PluginLive technologies has created a very supportive environment, enabling me to grow both personally and professionally. I am grateful for the chance to be a vital part of this transformative journey." 
        name="Manisha Amuda"
        designation="Associate Engineer"
      /> */}


        </div>
        </>
    );
}





export default Testimonials;