import React from 'react';
import { Container, Row, Col,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylepp.css';
import policy from '../../Assets/images/policybg.png';
import Policymob from '../../Assets/images/policybgmob.png';
import Signupfooter from '../SignupFooter/signupfooter';
import './Headerimgstyle.css';

function HeaderImg(){
  return(
    <>
      <div className="auscon">
                <img src={policy} alt="terms" className="auimg" />
                <img src={Policymob} alt='terms' className='auimgmob'/>
                <span className='auconimg'></span>
              </div>
    </>
  );
}

const PrivacyPolicy = () =>{
    return(
        <>  
        <Container className='privacypagemain'>
      <Row>
        <Col xs={12} sm={12} md={12}>
        <div className="privacycon">
                <img src={policy} alt="terms" className="auimg" />
                <img src={Policymob} alt='terms' className='auimgmob'/>
                <span className='auconimg'></span>
              </div>
        </Col>
        {/* <HeaderImg/> */}

        <Col xs={12} sm={12} md={12}>
            <p className='termstexts'>Privacy Policy</p>

            <p className='termstexts2 '>We protect your personal information</p>
        </Col>

        <Col xs={12} sm={12} md={12}>

        <p className='pppara'>We collect personal and professional information about you to make your job search as easy and safe as possible. This data is very important; we understand that. This is why we’re committed to protecting your privacy online.</p>


        <p className='pppara'>We have updated our privacy policy to explain exactly how we manage and use your information. Pluginlive Technologies Pvt Ltd and its offices, branches and related companies is part of the PluginLive group of companies.</p>


        <p className='pppara'>We are committed to ensuring that we, when collect and use information about Applicants, visitors to our websites, we do so in accordance with The Information Technology Act, 2000 (“IT Act”); The Information Technology (Reasonable security practices and procedures and sensitive personal information or information) Rules, 2011 (“Privacy Rules”); and Information Technology (The Indian Computer Emergency Response Team and Manner of Performing Functions and Duties) Rules, 2013 (“CERT-In Rules”).</p>


        <p className='pppara'>“Personal information” means any information that relates to you, which, either directly or indirectly, in combination with other information available or likely to be available to PluginLive is capable of establishing your identification.</p>

        </Col>

        <Col xs={12} sm={12} md={12}>

            <h1 className='datatitlepp'>1. Collection of Personal Information</h1>

            <p className='pppara'>We collect personal information of our employees, potential employees, clients, suppliers, business contacts, shareholders, Job Applicants, Corporate users, Institute users, and website.<br></br><br></br>

PluginLive may collect your name, contact details, skills, qualifications, accreditations and your employment history. We may also collect other types of your personal information such as Aaddhar information, PAN card information, address and id proofs, references and employment objectives during the course of dealing with you, for example if you are considered for a particular employment position.<br></br><br></br>

We may collect your personal information when you:<br></br><br></br>

<p class='ppsubpara'>
  <ul>
    <li>Register or subscribe to our services or request further services on any of our websites;</li>
    <li>Contact us to report a problem with our websites or make any enquiry or query or comment; and</li>
    <li>You apply online for a job or work with PluginLive, you may need to provide (without limitation) information about your education, employment history, and accreditations. Your application will constitute your express consent to our use of this information to access your application and to allow us to carry out any reference checking and other related activities as may be required of us under applicable law as an employer.</li>
  </ul>
  
</p>
PluginLive may also collect information from public record and third parties.
</p>
        
        </Col>

        <Col xs={12} sm={12} md={12}>

            <h1 className='datatitlepp'>2. Use and Disclosure of Personal Information</h1>

            <p className='pppara'>Generally, we will only hold, use, disclose and otherwise process your personal information for the following purposes:<br></br>

            <p class='ppsubpara'>
  <ul>
    <li>To provide our services to you;</li>
    <li>To maintain our business relationship, where you are a user of our website, a client or candidate;</li>
    <li>To enable you to submit your CV generally, to apply for specific jobs or to subscribe to our services at <a href="https://www.pluginlive.com" target="_blank" rel="noopener noreferrer">https://www.pluginlive.com</a></li>
    <li>To match your coded details with job vacancies, to assist us in finding a position that is most suitable for you and to send your personal information to clients in order to apply for jobs;</li>
    <li>To provide clients with information about you during an assignment or placement, including qualifications, licenses, checks (including sensitive information checks, where you have consented), migration status and other personal information you have provided to us;</li>
    <li>To contact any reference from details provided by you for the purpose of PluginLive placing you with a client;</li>
    <li>To answer your inquiry;</li>
    <li>To direct-market products and services, advise you of news and industry updates, events, promotions and competitions, reports and other information;</li>
    <li>To different parts of the PluginLive worldwide to enable the development and marketing of other products and services and to improve our customer service and to make our services more valuable to you, including tailoring our website when you log on to make it relevant to you personally;</li>
    <li>To trusted third parties where we have retained them to provide services that you have requested, such as psychometric evaluations or skills tests, and who also provide services to us, such as professional advisers, IT consultants, mailing houses and function co-ordinators. These third parties must comply with similar undertakings of privacy and confidentiality as PluginLive;</li>
    <li>If PluginLive merges with or is acquired by another business, we may share personal information with the new owners of the business and their advisers and if this happens, you will be sent notice of such an event;</li>
    <li>We may also release personal information to regulatory or law enforcement authorities, if they require us to do so;</li>
    <li>To meet or comply with any legal, regulatory or statutory requirements relating to our provision of products and services and to make disclosure under the requirements of any applicable law, legislation, rule, ruling, regulation, direction, court order, by-law, guideline, circular or code (collectively “laws”) applicable to us;</li>
    <li>For research, benchmarking and statistical analysis;</li>
    <li>For sharing with our sister concerns/related parties or third-party vendors like financial institutions, service providers etc., to provide advisory or products for your consumption for a revenue/fee-sharing arrangement with PluginLive;</li>
    <li>We may also seek your consent to collect, hold, use and disclose your personal information for any other purpose not listed above or permitted under the privacy laws or other applicable laws.</li>
  </ul>
</p>
</p>
        
        </Col>

        <Col xs={12} sm={12} md={12}>

            <h1 className='datatitlepp'>3. Sharing of Personal Information</h1>

            <p className='pppara'>While your personal information will be kept confidential, in certain circumstances, it may be necessary for us to provide or disclose your personal information if required for any of the foregoing purposes.<br></br><br></br>
            We may share your personal information with the following categories of persons (who may be located within or outside of India), such as entities within the PluginLive including all related companies, subsidiaries, holding companies and associated companies, our affiliates, licensees, joint venture partners; our sub-contractors and service or product providers in connection with our products or services; and anybody or person to whom we are compelled or required to do so under any laws or in response to any competent or government, state, provincial, local government, statutory or municipal authority, industry regulators, law enforcement authority, agency or body.


</p>
        
        </Col>

        <Col xs={12} sm={12} md={12}>

            <h1 className='datatitlepp'>4. Sensitive Information</h1>

            <p className='pppara'>We do not generally seek to collect sensitive information (also known as special categories) through this site or otherwise. In the limited cases where we do seek to collect such information, we will do this in accordance with information privacy law requirements and by ticking off the consent button, you agree that you give positive consent to share such information as per all above mentioned conditions

</p>
        
        </Col>

        <Col xs={12} sm={12} md={12}>

<h1 className='datatitlepp'>5. Privacy on Our Websites</h1>

<h1 className='datasubtitlepp'>(a) Submitting Your Curriculum Vitae ("CV") and Applying for Jobs</h1>


<p className='pppara'>You may submit your CV to PluginLive via this website, either for general consideration by our recruitment consultants for positions as they arise or to apply for a specific advertised job. Once submitted, your CV will be sent directly to the relevant PluginLive recruitment consultant who will review your details and advise you whether you are to be accepted by us for registration on our central information base. This information base, including your personal information, can be accessed by any of our recruitment consultants working in other offices of the PluginLive, both inside and outside India. If you are accepted onto our information base, our recruitment consultants should then contact you to set up an interview in person. You can update your CV at any time, simply by following the same procedure to submit a new CV via our websites.
</p>


<h1 className='datasubtitlepp'>(b) Job Alerts</h1>


<p className='pppara'>You may sign up to receive emails that alert you to new jobs on our website (Job Alerts). To subscribe to Job Alerts, you need to provide your email address, or other relevant information, which will be used for the purpose of keeping you informed by email of the latest jobs and/or news in your nominated industry. If you no longer wish to receive Job Alerts, unsubscribe links are provided in every Job Alert email that you receive.

</p>

<h1 className='datasubtitlepp'>(c) Aggregate Information About Website Visitors</h1>


<p className='pppara'>We gather information and statistics about all visitors to this and all of our websites worldwide, including the most frequently accessed pages and most frequently used services. We only use such information in aggregate form (that is, the information does not identify any one individual). This information helps us determine the most beneficial parts of our websites and ways in which we can continually improve our online services to create a better overall experience for our users. We also publish some of this aggregate (non-identifiable) information on the PluginLive websites worldwide.

</p>
</Col>


<Col xs={12} sm={12} md={12}>

            <h1 className='datatitlepp'>6. Security</h1>

            <p className='pppara'>PluginLive maintains organizational, physical and technical security arrangements for all the personal information we hold. We have protocols, controls and relevant policies, procedures and guidance to maintain these arrangements taking into account the risks associated with the categories of personal information and the processing we undertake.<br></br><br></br>

            <p class='ppsubpara'>
  <ul>
    <li>Limiting physical access to our premises;</li>
    <li>Limiting access to information we collect about you (for instance, only those of our personnel who need your information to carry out our business activities are allowed access and committee members only have access to the contact details of members of their committee and not other committees);</li>
    <li>Requiring any third-party providers to have acceptable security measures to keep personal information secure; and</li>
    <li>Putting in place physical, electronic and procedural safeguards in line with industry standards.</li>
  </ul>
</p>
</p>
        
        </Col>



        <Col xs={12} sm={12} md={12}>

            <h1 className='datatitlepp'>7. Retention of Personal Information</h1>

            <p className='pppara'>We will retain your personal information only for as long as is necessary. We maintain specific records management and retention policies and procedures, so that personal information is deleted after a reasonable time according to the following retention criteria:<br></br><br></br>

            <p className='ppsubpara'>

            <ul>
    <li>We retain your information as long as we have an ongoing relationship with you (in particular, if you have an account with us);</li>
    <li>We will only keep the information while your account is active or for as long as needed to provide services to you;</li>
    <li>We retain your information for as long as needed in order to comply with our global legal and contractual obligations.</li>
  </ul>
  </p>

            If we no longer require your personal information and are not legally required to retain it, PluginLive will take reasonable steps to destroy or permanently de-identify the personal information.

</p>
        
        </Col>

        {/* <Col xs={12} sm={12} md={12}>

            <h1 className='datatitleppaddr'>Head Office:</h1>

            <p className='ppparaaddr'>2 Kismet, 4th Pasta Lane, Colaba, Mumbai 400005.</p>

<h1 className='datatitleppaddr'>Chennai Development Center:</h1>

<p className='ppparaaddr'>

Indiqube Vantage, OMR Service Rd, Perungudi, Chennai, Tamil Nadu 600096.




</p>
        
        </Col> */}

        <Signupfooter/>

        {/* <Col xs={12} sm={12} md={10} className='termstarted1 d-none d-lg-block'>
          <div className='termstartedbox1 d-flex'>
          <h1 className='termstarthead1'>Ready to join the success stories?</h1><br></br></div>
          
          <button className='termsubbutton1 d-flex'><p className='termregis1'>Sign up now</p></button>
            <p className='termstartpara1 d-none d-lg-block'>Start your journey with us today and unlock endless opportunities for your career growth</p><br></br><br></br><br></br><br></br><br></br>
        </Col>

        <Col xs={12} sm={12} md={10} className='termstartedm  d-sm-none'>
            <div className='termstartedboxm d-flex'>
              <h1 className='termstartheadm'>Ready to join the success stories?</h1><br></br></div>
            <button className='termsubbuttonm d-flex'><p className='termregism'>Sign up now</p></button>
            <p className='termstartparam'>Start your journey with us today and unlock endless opportunities for your career growth</p>
          </Col>  */}


      </Row><br></br><br></br><br></br><br></br>
    </Container>
        </>
    );
}

export default PrivacyPolicy