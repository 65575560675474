import React from 'react'

const CrossIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
        <rect y="0.5" width="20" height="20" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_1495_28384)">
        <path
          d="M10.0002 11.729L5.97915 15.75C5.81248 15.9167 5.61115 15.9967 5.37515 15.99C5.13915 15.9827 4.93782 15.8957 4.77115 15.729C4.60448 15.5623 4.52115 15.3577 4.52115 15.115C4.52115 14.8717 4.60448 14.6667 4.77115 14.5L8.77115 10.5L4.75015 6.479C4.58348 6.31233 4.50348 6.10767 4.51015 5.865C4.51748 5.62167 4.60448 5.41667 4.77115 5.25C4.93782 5.08333 5.14248 5 5.38515 5C5.62848 5 5.83348 5.08333 6.00015 5.25L10.0002 9.271L14.0211 5.25C14.1878 5.08333 14.3925 5 14.6351 5C14.8785 5 15.0835 5.08333 15.2501 5.25C15.4168 5.41667 15.5001 5.62167 15.5001 5.865C15.5001 6.10767 15.4168 6.31233 15.2501 6.479L11.2292 10.5L15.2501 14.521C15.4168 14.6877 15.5001 14.889 15.5001 15.125C15.5001 15.361 15.4168 15.5623 15.2501 15.729C15.0835 15.8957 14.8785 15.979 14.6351 15.979C14.3925 15.979 14.1878 15.8957 14.0211 15.729L10.0002 11.729Z"
          fill="#999FAC"
        />
      </g>
    </svg>
  )
}

export default CrossIcon
