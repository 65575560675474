import React from 'react';
import { Col, Divider, Row } from 'antd';
import {
  TitleMedium,
  SubtitleLarge,
  GrayTextSmall,
  InfoText,
  FlexWrap,
  GrayText,
  SubtitleSmall,
  YellowBox,
  SubtitleMedium,
  VerticalDivider,
  FlexContain,
  LeftCol,
  Editor,
  OneLinerDiv,
  SocialMeadiaIconsFlex,
} from './styles';
import Flex from '../JobContainer/Flex';

import { Avatar } from 'antd';
import { FONT } from '../theme/font';
import Location from '../icons/LocationIcon';
import AlarmOn from '../icons/Alarm_On';
import styled from 'styled-components'
import PaymentIcon from '../icons/PaymentIcon';
import { COLORS } from '../theme';
import CalendarYellow from '../icons/CalendarYellow';

import ConstructionIcon from '../icons/ConstructionIcon';

import ReactPlayer from 'react-player'
import DomainDisabled from '../icons/DomainDisabled';
import ContactIcon from '../icons/ContactIcon';
import DateIcon from '../icons/DateIcon';
import BrowseDefaultIcon from '../icons/Academic_cap';
import FaceBookIcon from '../icons/Facebook';
import Twitter from '../icons/Twitter'
import LinkedInIcon from '../icons/Linkedin';
import Instagram from '../icons/Twitter';

const EditorContent = styled.span`
  padding: 20px 0;
  * {
    line-height: 188%;
    color: #808697 !important;
  }
  p,
  ul,
  ol {
    margin-bottom: 10px;
  }

  li {
    line-height: 25px;
  }
`

const PopHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #343e59;
  padding-top: 15px;
  padding-bottom: 5px;
`
const PopSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808697;
  padding-top: 5px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
`
const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  height: 24px;
  line-height: 22px;
  text-align: center;
  color: #343e59;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
`
const Address = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1b1f22;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
  opacity: 0.48;
`
const Frame = styled.div`
  padding: 20px;
`
const AboutCompany = ({ viewRoleData }) => {
  // console.log(viewRoleData,'roleData')
  const getSocilaMediaIcon = media => {
    if (media?.media == 'Facebook') {
      return   <Avatar size={24} src={<FaceBookIcon/>} />
    } else if (media?.media == 'Linkedin') {
      return <Avatar size={24} src={<LinkedInIcon/>} /> 
    } else if (media?.media == 'Instagram') {
      return <Avatar size={24} src={<Instagram/>} /> 
    }

    return <Avatar size={24} src={<Twitter/>} />
  }
  return (
    <>
     <Row>
        <LeftCol span={16}>
          <TitleMedium>About</TitleMedium>

          <FlexContain centerVertically>
          <DomainDisabled/>
            &nbsp;
            <Address>{viewRoleData?.corporate?.corporateType}</Address>
            <VerticalDivider type="vertical" />
            <ContactIcon/>
            &nbsp;
            <Address>{viewRoleData?.corporate?.employeesNumber}</Address>
            <VerticalDivider type="vertical" />
            <DateIcon/>
            &nbsp;
            <Address>
              Since <b>{viewRoleData?.corporate?.establishedYear}</b>
            </Address>
          </FlexContain>
          <SubtitleLarge color={COLORS.GREY_T_30}>Description</SubtitleLarge>
          <OneLinerDiv>
            {viewRoleData?.corporate?.shortDescription}
            </OneLinerDiv>
          <Editor>
            
            <EditorContent
              dangerouslySetInnerHTML={{
                __html: viewRoleData?.corporate?.longDescription || '',
              }}
            />
          </Editor>
      
          {viewRoleData?.corporate?.corporateVideoLink && (
          <>
            <ReactPlayer
              controls
              width="100%"
              height="450px"
              url={viewRoleData?.corporate?.corporateVideoLink}
            />
          </>
        )}
        </LeftCol>
        <Col span={8}>
          <br></br>
          <Flex>
            <ConstructionIcon />
            &nbsp;&nbsp;
            <Name>Other Details</Name>
          </Flex>
          <PopHeading>Company Type</PopHeading>
          <PopSubHeading>{viewRoleData?.corporate?.corporateType}</PopSubHeading>
          <PopHeading>Established in</PopHeading>
          <PopSubHeading>{viewRoleData?.corporate?.establishedYear}</PopSubHeading>

      
          <Divider />
          <div>
            <FlexWrap gap="10px" centerVertically>
              <Location />
              <SubtitleMedium>Location</SubtitleMedium>
            </FlexWrap>
            <br></br>
            <ul>
              <li>
              {viewRoleData?.corporate?.city}
              </li>
              
            </ul>
          </div>

          <Divider />
          <FlexWrap gap="10px" centerVertically>
            <BrowseDefaultIcon />
            <SubtitleMedium>Connect</SubtitleMedium>
          </FlexWrap>
        
          <InfoText>{viewRoleData?.corporate?.website}</InfoText>
        
         
          <br></br>
          <SocialMeadiaIconsFlex>
          {viewRoleData?.corporate?.socialMedia?.length
            ? viewRoleData?.corporate?.socialMedia?.map((media, index) => (
                <a href={media?.link} target="_blank" key={index}>
                  <Avatar
                    background
                    src={getSocilaMediaIcon(media)}
                    // size={25}
                    IconName={'I'}
                    font={18}
                  />
                </a>
              ))
            : null}
        </SocialMeadiaIconsFlex>
        </Col>
      </Row>
    </>
  )
}

export default AboutCompany;