import React from "react";
import "./logocompstyle.css";

import Client from '../../Assets/company/client.png';
import Cuemath from '../../Assets/company/cuemath.png';
import Rbl from '../../Assets/company/rblbank.png';
import Rb from '../../Assets/company/relevance_broking_services_logo.png';
import Trigyn from '../../Assets/company/trigyn.png';
import Delhivery from '../../Assets/company/delhivery.png';
import Lic from '../../Assets/company/lic.png';
import Kotak from '../../Assets/company/kotak.png';
import Lloyds from '../../Assets/company/lloyds.png';
import Corpcare from '../../Assets/company/corpcare.png';
import Ak from '../../Assets/company/ak.png';
import Dabur from '../../Assets/company/Dabur-Logo.png';
import Coreintegra from '../../Assets/company/coreintegra.png';
import Lighthouse from '../../Assets/company/lighthouse.png';
import Austin from '../../Assets/company/Austin-Logo-scaled.png';
import Hdfc from '../../Assets/company/hdfc.png';
import Nse from '../../Assets/company/nse.png';
import Indusland from '../../Assets/company/indusland.png';


import Pes from '../../Assets/institution/pesuniversity.jpg';
import Ifim from '../../Assets/institution/ifiminstitution.jpeg';
import Holymary from '../../Assets/institution/holy-mary-.png';
import Ramanujam from '../../Assets/institution/ramanu-logo.96e81bbdf814c655f1ab.png';
import Motilal from '../../Assets/institution/Logo_of_Motilal_Nehru_College.05914d823d3c4c9a1992.png';
import Avcol from '../../Assets/institution/avcol.jpg';
import Joseph from '../../Assets/institution/stjoseph.jpg';
import Vishwa from '../../Assets/institution/vishwa.png';
import Sardar from '../../Assets/institution/sardaepatel.png';
import Guru from '../../Assets/institution/gurunanak.png';
import Vijaybhoomi from '../../Assets/institution/vijaybhoomi.jpg';

function LogoComp(){
    return(
        <>
            <div className="LogoContainer">
                <div className="LogoMainheader">Vetted by Industry Leaders</div>
                <h1 className="LogoSubheader">Our Partnerships with Seasoned Veterans</h1>
                <h1 className="Logoheader">Corporate</h1>
            <div className='ScrollLogoContainer'>
                <div className='ScrollLogo'>
                    <img className='client' src={Client} alt='client'/>
                    <img className='cuemath' src={Cuemath} alt='Cuemath'/>
                    <img className='Rbl' src={Rbl} alt='Rbl'/>
                    <img className='Rb' src={Rb} alt='Rb'/>
                    <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
                    <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
                    <img className='Lic' src={Lic} alt='Lic'/>
                    <img className='Kotak' src={Kotak} alt='Kotak'/>
                    <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
                    <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
                    <img className='Ak' src={Ak} alt='Ak'/>
                    <img className='Dabur' src={Dabur} alt='Dabur'/>
                    <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
                    <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
                    <img className='Austin' src={Austin} alt='Austin'/>
                    <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
                    <img className='Nse' src={Nse} alt='Nse'/>
                    <img className='Indusland' src={Indusland} alt='Indusland'/>
                </div>

                <div className='ScrollLogo'>
                    <img className='client' src={Client} alt='client'/>
                    <img className='cuemath' src={Cuemath} alt='Cuemath'/>
                    <img className='Rbl' src={Rbl} alt='Rbl'/>
                    <img className='Rb' src={Rb} alt='Rb'/>
                    <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
                    <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
                    <img className='Lic' src={Lic} alt='Lic'/>
                    <img className='Kotak' src={Kotak} alt='Kotak'/>
                    <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
                    <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
                    <img className='Ak' src={Ak} alt='Ak'/>
                    <img className='Dabur' src={Dabur} alt='Dabur'/>
                    <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
                    <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
                    <img className='Austin' src={Austin} alt='Austin'/>
                    <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
                    <img className='Nse' src={Nse} alt='Nse'/>
                    <img className='Indusland' src={Indusland} alt='Indusland'/>
                </div>
            </div>


                <h1 className="Logoheader">Institutes</h1>

                <div className="ScrollLogoContainer2">
                    <div className='ScrollLogo2'>
                        <img className='logoimgs' src={Pes} alt='pes'/>
                        <img className='logoimgs' src={Ifim} alt='Ifim'/>
                        <img className='logoimgs' src={Holymary} alt='Holymary'/>
                        <img className='logoimgs' src={Ramanujam} alt='Ramanujam'/>
                        <img className='logoimgs' src={Motilal} alt='Motilal'/>
                        <img className='logoimgs' src={Avcol} alt='Avcol'/>
                        <img className='logoimgs' src={Joseph} alt='Joseph'/>
                        <img className='logoimgs' src={Vishwa} alt='Vishwa'/>
                        <img className='logoimgs' src={Sardar} alt='Sardar'/>
                        <img className='logoimgs' src={Guru} alt='Guru'/>
                        <img className='logoimgs' src={Vijaybhoomi} alt='Vijaybhoomi'/>
                    </div>

                    <div className='ScrollLogo2'>
                        <img className='logoimgs' src={Pes} alt='pes'/>
                        <img className='logoimgs' src={Ifim} alt='Ifim'/>
                        <img className='logoimgs' src={Holymary} alt='Holymary'/>
                        <img className='logoimgs' src={Ramanujam} alt='Ramanujam'/>
                        <img className='logoimgs' src={Motilal} alt='Motilal'/>
                        <img className='logoimgs' src={Avcol} alt='Avcol'/>
                        <img className='logoimgs' src={Joseph} alt='Joseph'/>
                        <img className='logoimgs' src={Vishwa} alt='Vishwa'/>
                        <img className='logoimgs' src={Sardar} alt='Sardar'/>
                        <img className='logoimgs' src={Guru} alt='Guru'/>
                        <img className='logoimgs' src={Vijaybhoomi} alt='Vijaybhoomi'/>
                    </div>
                </div>


            </div>
        </>
    );
}

export default LogoComp;