import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./style.css";

import Fresher from "../LandingPage/images/fresher.png";
import Experienced from "../LandingPage/images/experienced.png";
import College from "../LandingPage/images/college.png";
import Partner from "../LandingPage/images/partner.png";
import Corporate from "../LandingPage/images/corporate.png";

import PluginLivelogo from "../Header/images/image1.png";
import Pickarrow from "../../Assets/images/pickarrow.png";

const Landingpg = () => {


    const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(true);



  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    if (window.innerWidth >= 1200) {
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };


  const handleTextClick = (path) => {
    window.location.pathname = path;
  };

  return (
    <div className="LandingpgContainer">
      <div className="LandingpgHeader">
        <img src={PluginLivelogo} alt="PluginLive" className="Pluginlivelogo" />
        <hr className="LandingpgHeaderline"></hr>
        <div className="LandingpgHeaders">
          <h1 className="LandingpgHeadersmain">
            Welcome to PluginLive: Your Virtual Campus Placement Partner
          </h1>
          <p className="LandingpgHeaderspara">
            Ignite your career journey by automating end-to-end campus placement
            solutions colleges, facilitating seamless talent acquisition for
            corporates, and democratizing access to opportunities for freshers
            and experienced.
          </p>
        </div>
        <button className="PickProfile" onClick={handleClick}>
          Pick your profile &nbsp; <img src={Pickarrow} alt="" />
        </button>
      </div>

      <div className="Landingpgboxes">
        <div className="Landingpgbox">
          <div className="FresherLanding">
            <div className="FresherBgSection">
              <span className="FLandingBg">
                <div className="FLandingBgcontent">
                  <h1 className="FLandingBgcontentheader">Freshers</h1>
                  <p className="FLandingBgcontentpara">
                    Start your professional journey with tailored opportunities
                    tailored, paving the way for future success.
                  </p>
                </div>
              </span>
              <img src={Fresher} alt="Fresher" className="FLandingimg" onClick={() => handleTextClick("/fresher")}/>
            </div>
            <h1 className="FresherLandingheader" onClick={() => handleTextClick("/fresher")}>Freshers</h1>
          </div>
          <p className="FresherLandingpara">
            Start your professional journey with tailored opportunities
            tailored, paving the way for future success.
          </p>
        </div>
        <div className="Landingpgbox">
          <div className="ExperiencedLanding">
            <div className="ExperiencedBgSection">
              <span className="ELandingBg">
                <p className="ELandingBgcontent">
                  <h1 className="ELandingBgcontentheader">Experienced</h1>
                  <p className="ELandingBgcontentpara">
                    Elevate your career trajectory with solutions designed to
                    match your expertise and goals effectively, fostering
                    continued growth.
                  </p>
                </p>
              </span>
              <img
                src={Experienced}
                alt="Experienced"
                className="ELandingimg"
                onClick={() => handleTextClick("/experienced")}
              />
            </div>
            <h1 className="ExperiencedLandingheader" onClick={() => handleTextClick("/experienced")}>Experienced</h1>
          </div>
          <p className="FresherLandingpara">
            Elevate your career trajectory with solutions designed to match your
            expertise and goals effectively, fostering continued growth.
          </p>
        </div>
        <div className="Landingpgbox">
          <div className="CollegeLanding">
            <div className="CollegeBgSection">
              <span className="CLandingBg">
                <p className="CLandingBgcontent">
                  <h1 className="CLandingBgcontentheader">Colleges</h1>
                  <p className="CLandingBgcontentpara">
                    Simplify the campus placement process for your students,
                    providing them with comprehensive support and resources.
                  </p>
                </p>
              </span>
              <img src={College} alt="College" className="CLandingimg" onClick={() => handleTextClick("/college")}/>
            </div>
            <h1 className="CollegeLandingheader" onClick={() => handleTextClick("/college")}>Colleges</h1>
          </div>
          <p className="FresherLandingpara">
            Simplify the campus placement process for your students, providing
            them with comprehensive support and resources.
          </p>
        </div>
        <div className="Landingpgbox">
          <div className="PartnerLanding">
            <div
              className="PartnerBgSection"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span className="PLandingBg">
                {isWideScreen && (
                  <div
                    className={`PLandingBgcontent ${
                      isHovered ? "visible" : "hidden"
                    }`}
                  >
                    <h1 className="PLandingBgcontentheader">
                      Training Partners
                    </h1>
                    <p className="PLandingBgcontentpara">
                      Together, let's boost skill development and employability.
                      We offer students and professionals the chance to train
                      with the world's top training partners.
                    </p>
                  </div>
                )}
              </span>
              <img src={Partner} alt="Partner" className="PLandingimg" onClick={() => handleTextClick("/partner")}/>
            </div>
            <h1 className="PartnerLandingheader" onClick={() => handleTextClick("/partner")}>Training Partners</h1>
          </div>
          <p className="FresherLandingpara">
            Together, let's boost skill development and employability. We offer
            students and professionals the chance to train with the world's top
            training partners.
          </p>
        </div>
        <div className="Landingpgbox">
          <div className="CorporateLanding">
            <div className="CorporateBgSection">
              <span className="CoLandingBg">
                <p className="CoLandingBgcontent">
                  <h1 className="CoLandingBgcontentheader">Corporate</h1>
                  <p className="CoLandingBgcontentpara">
                    Discover and recruit top-tier young talent efficiently,
                    leveraging our streamlined solutions to drive growth and
                    innovation.
                  </p>
                </p>
              </span>
              <img src={Corporate} alt="Corporate" className="CoLandingimg" onClick={() => handleTextClick("/corporate")}/>
            </div>
            <h1 className="CorporateLandingheader" onClick={() => handleTextClick("/corporate")}>Corporate</h1>
          </div>
          <p className="FresherLandingpara">
            Discover and recruit top-tier young talent efficiently, leveraging
            our streamlined solutions to drive growth and innovation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landingpg;
