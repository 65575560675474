import React from 'react';
import { Form, Select } from 'antd';
import './styleprofiles.css';

const Profile = ({ value, onChange }) => {
  const handleProfileChange = (selectedProfile) => {
    onChange(selectedProfile);
  };

  return (
    <Form.Item
      name="profile"
      rules={[
        {
          required: true,
          message: 'Please pick your profile',
        },
      ]}
    >
      <Select
        id="profile"
        placeholder="Pick your profile"
        className="profilepick"
        value={value}
        onChange={handleProfileChange}
      >
        {/* <Select.Option value="fresher">Fresher (0-6 months)</Select.Option> */}
        <Select.Option value="experienced">Experienced (&gt;6 months)</Select.Option>
        <Select.Option value="college">College</Select.Option>
        <Select.Option value="training partner">Training partner</Select.Option>
        <Select.Option value="corporate">Corporate</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default Profile;
