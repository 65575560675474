// import 'antd/dist/antd.css';
import React, { useState } from 'react';
import { Input } from 'antd';
import { Collapse, Space, Card } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { Container, Row, Col,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylefq.css';
import faqs from '../../Assets/images/faqbg.png';
import Faqmob from '../../Assets/images/faqbgmob.png';
import search from './images/search.png'
import fresh from './images/faqsfresher.png'
import exper from './images/faqsexperienced.png'
import colleg from './images/faqscollege.png'
import traipart from './images/faqstraining.png'
import corpo from './images/faqscorporate.png'
import  imageblank from './images/blank.png'
import imageblacktab from '../AboutUs/images/tabimg.png';
import Signupfooter from '../SignupFooter/signupfooter';
import CustomCollapse from './CustomCollapse';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

const text = `
Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success. Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.
`;

const Faqs = () =>{

  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleCollapseChange = (keys) => {
    setExpandedPanels(keys);
  };

  const isPanelExpanded = (key) => {
    return expandedPanels.includes(key);
  };

  const items = [
    {
      key: '1',
      label: (
        <div className="aboutuscollapseheader">
          Building your career entails strategically developing personal and professional?
        </div>
      ),
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: (
        <div className="aboutuscollapseheader">
          Building your career entails strategically developing personal and professional?
        </div>
      ),
      children: <p>{text}</p>,
    },
  ];
  

  const data = [
    {
      // title: 'Card 1',
      content: 'Building your career entails strategically developing.',
      content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
    },
    {
      content: 'Building your career entails strategically developing.',
      content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
    },
    {
      content: 'Building your career entails strategically developing.',
      content2: 'Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.',
    },
  ];

    // const [collapsed, setCollapsed] = useState(false);

  // const handleCollapseChange = (key) => {
  //   setCollapsed(key.length === 0 ? false : true);
  // };
  
    return(
        <>  
        <Container className='faqpagemain'>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <div className="faqscon d-flex">
            <img src={faqs} alt="Faq" className="faqsimg"/>
            <img src={Faqmob} alt='faq' className='faqsimgmob'/>
            <span className='faqsconimg d-flex justify-content-center align-items-center'></span>
          </div>
        </Col>

        <Col xs={12} sm={12} md={12}>
            <p className='faqstext'>FAQs</p>
            <p className='faqstext2  d-none d-lg-block'>Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.</p>
            <p className='faqstext2mob d-sm-none'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>
        </Col>

        <Col xs={12} sm={12} md={12}>
        <div className='faqsrch'>
          <Input className='faqssrch' placeholder="Search" />
          {/* <FontAwesomeIcon icon={faSearch} className='faqsic' /> */}
        </div>
        </Col>
      </Row><br></br>
        
      {/* <Row>  
        <Col xs={12} sm={12} md={12}>
          <h1 className='faqsord d-flex justify-content-center align-items-center mt-5'>Browse by profile</h1><br></br>
        </Col>
      </Row>
      

      <Row>
        <Col xs={12} sm={4} md={4} className='faqsfr1 d-flex align-items-center'>
          <input type="radio" id="freshers" name="profile" className="faqsfr1-input"/>
          <label htmlFor="freshers" className='faqsfre1-label'>
            <span className='faqsfre1 d-flex justify-content-center align-items-center'></span>   
            <img className='faqsfres1' src={fresh} alt='Freshers'/>
            <p className='faqsfresh1'>Freshers</p>
          </label>
        </Col>

        <Col xs={12} sm={4} md={4} className='faqsfr2 d-flex align-items-center'>
          <input type="radio" id="experienced" name="profile" className="faqsfr2-input"/>
          <label htmlFor="experienced" className='faqsfre2-label'>
            <span className='faqsfre2 d-flex justify-content-center align-items-center'></span>   
            <img className='faqsfres2' src={exper} alt='Experienced'/>
            <p className='faqsfresh2'>Experienced</p>
          </label>
        </Col>

        <Col xs={12} sm={4} md={4} className='faqsfr3 d-flex align-items-center'>
          <input type="radio" id="college" name="profile" className="faqsfr3-input"/>
          <label htmlFor="college" className='faqsfre3-label'>
            <span className='faqsfre3 d-flex justify-content-center align-items-center'></span>   
            <img className='faqsfres3' src={colleg} alt='Colleges'/>
            <p className='faqsfresh3'>Colleges</p>
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={6} md={6} className='faqsfr4 d-flex align-items-center'>
          <input type="radio" id="tariningpartner" name="profile" className="faqsfr4-input"/>
          <label htmlFor="tariningpartner" className='faqsfre4-label'>
            <span className='faqsfre4 d-flex justify-content-center align-items-center'></span>   
            <img className='faqsfres4' src={traipart} alt='Training Partners'/>
            <p className='faqsfresh4'>Training Partners</p>
          </label>
        </Col>

        <Col xs={6} sm={6} md={6} className='faqsfr5 d-flex align-items-center'>
          <input type="radio" id="corporate" name="profile" className="faqsfr5-input"/>
          <label htmlFor="corporate" className='faqsfre5-label'>
            <span className='faqsfre5 d-flex justify-content-center align-items-center'></span>   
            <img className='faqsfres5' src={corpo} alt='Corporate'/>
            <p className='faqsfresh5'>Corporate</p>
          </label>
        </Col>
      </Row> */}
      
      <br></br><br></br><br></br>
      
    {/* <Row>
        <Col xs={12} sm={12} md={12}>
        <div className="custom-collapse ">
    <Space direction="vertical" size="large">
      <Collapse
        onChange={handleCollapseChange}
        collapsible="header"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : ""}}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right" 
        size="large"
        items={[
          {
            key: "1",
     
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),


              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
      onChange={handleCollapseChange}
        collapsible="header"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right" 
        size="large"
        items={[
          {
            key: "1",
    
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),

              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
        collapsible="header"
        onChange={handleCollapseChange}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right"
        size="large"
        items={[
          {
            key: "1",
           
          
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),
           
              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
        collapsible="header"
        onChange={handleCollapseChange}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right"
        size="large"
        items={[
          {
            key: "1",   
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),
              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
        collapsible="header"
        onChange={handleCollapseChange}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right" 
        size="large"
        items={[
          {
            key: "1",
           
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),
              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
        collapsible="header"
        onChange={handleCollapseChange}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right"
        size="large"
        items={[
          {
            key: "1",

           
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),
            
              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
        onChange={handleCollapseChange}
        collapsible="header"
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right" 
        size="large"
        items={[
          {
            key: "1",
            
              
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),

              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <Collapse
        onChange={handleCollapseChange}
        collapsible="header"
        expandIcon={({ isActive }) => (
          <CaretDownOutlined
            style={{ transform: isActive ? "rotate(180deg)" : "" }}
            className='cardarrowfaq'
          />
        )}
        expandIconPosition="right" 
        size="large"
        items={[
          {
            key: "1",

           
            label: (
              <div className="custom-collapse-header">
                Building your career entails strategically developing personal and professional?
              </div>
            ),
              children: <p className='collapsecontentfaq'>{text}</p>,
          },
        ]}
        className='collapsefullcardfaq'
      />
      <hr className="horizontal-line" />
    </Space>
  </div>
  </Col>

  </Row> */}



<Row>

<Col xs={12} sm={12} md={12}>
  <div>

  {/* <CustomCollapse
      size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined style={{ transform: isActive ? "rotate(180deg)" : "" }} className='arrowheadaboutus' />
      )}
      items={items}
      className='aboutuscollapsecontainer'
    />


    <h1>Split</h1> */}



  <Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

<Collapse size="large"
      expandIcon={({ isActive }) => (
        <CaretDownOutlined
          style={{ transform: isActive ? "rotate(180deg)" : ""}}
          className='arrowheadaboutus'
        />)}
      expandIconPosition="right"
      items={[
        {
          key: '1',

          // label: 'This is large size panel header',

          label: (
            <div className="aboutuscollapseheader">
              Building your career entails strategically developing personal and professional?
            </div>
            
          ),

          children: <p>{text}</p>,
        },
        
      ]}
      className='aboutuscollapsecontainer'
    />

      </div>
  
</Col>

<hr className='horizontal-line'></hr>

</Row>


  
{/* style={{ marginTop: expandedPanels.length > 0 ? '700px' : '450px' }} */}

  {/* <Row className='blogpartfaq' >
    <Col xs={12} sm={12} md={12} className='Faqblogcardlap'>
  <div className="about-card-container d-flex justify-content-between align-items-center">
    <div className="about-card-content">
      <span className='aboutstite1 d-flex justify-content-center align-items-center'>
        <span className='abouttite'>Title Header</span>
      </span>
      <h1 className='aboutparas1 d-flex justify-content-center align-items-center'>Our blog</h1>
      <Card
        style={{
          width: 536,
          height: 371,
          borderRadius: 35,
          marginBottom: 40,
          marginLeft: 150,
          position: 'relative',
        }}
        cover={<img alt="example" src={imageblank} className="aboutingblog"/>}
      >
        <span className='aboutlat d-flex justify-content-center align-items-center'><span className='aboutlate'>Latest</span></span>
        <div className='aboutlcblog'></div>
        <p className='aboutauthblog'>Blog author</p>
      </Card>
    </div>
    <div className="aboutmaiblog">
      <p className='aboutbuilblog'>Building your career entails strategically developing.</p>
      <p className='aboutbuildblog'>Building your career entails strategically developing personal and professional skills through intentional actions and continuous learning for growth and success.</p>
      <button className='aboutreadmore'>Read more</button> 
    </div>
  </div>
</Col>
  </Row>

  <Row className='faqheaderforphone'>
        <span className='faqblogheader d-flex justify-content-center align-items-center'>Title Header</span>
        <h1 className='faqblogheader2 '>Our Blog</h1>
        <p className='faqblogheader3'>Empower your career journey with PluginLive—a premier platform that identifies skill gaps, strategically upskills, and refines expertise for a seamless transition into a successful corporate career.</p>

  </Row>

  <Row >

    <Col>
    
    </Col>


    <Col className='hideintabfaq'>
    <div style={{ display: 'flex' }}>

      {data.map((item, index) => (
        <div key={index} style={{ marginRight: '42px',marginLeft:'40px' }}>
          <Card
            title={item.title}
            cover={<img alt={item.image} src={imageblank} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />}
            style={{ width: 343, borderRadius: 8 }}
            className='abtcardbldimensions'
          >
            <p className='abtbltitle'>{item.content}</p>
            <p className='abtbltitlepara'>{item.content2}</p>
            <hr className='abthr2blogl'></hr> <br></br>
            <div className='abtbloglc'></div>
            <h1 className='abtbloglctext'>Blog Author</h1>
          </Card>
        </div>
      ))}
    </div>
    </Col>



    <div className='hideinlapfaq'>

      {data.map((item, index) => (
        <div key={index} style={{ marginRight: '20px',marginLeft:'40px' }}>
          <Card
            title={item.title}
            cover={<img alt={item.image} src={imageblacktab}  style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} className='imagebacktabdimen'/>}
            // style={{ width: 343, borderRadius: 8 }}
            className='abtcardbldimensions'
          >
            <p className='faqbltitle'>{item.content}</p>
            <p className='faqbltitlepara'>{item.content2}</p>
            <hr className='faqhr2blogl'></hr> <br></br>
            <div className='faqbloglc'></div>
            <h1 className='faqbloglctext'>Blog Author</h1>
          </Card>
        </div>
      ))}
    </div>

    <div className='thisisformob'>

{data.map((item, index) => (
  <div key={index} style={{ marginRight: '20px',marginLeft:'40px' }}>
    <Card
      title={item.title}
      cover={<img alt={item.image} src={imageblank}  style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }} className='imagebacktabdimen'/>}
      // style={{ width: 343, borderRadius: 8 }}
      className='faqcardbldimensions'
    >
      <p className='faqbltitle'>{item.content}</p>
      <p className='faqbltitlepara'>{item.content2}</p>
      <hr className='faqhr2blogl'></hr> <br></br>
      <div className='faqbloglc'></div>
      <h1 className='faqbloglctext'>Blog Author</h1>
    </Card>
  </div>
))}
</div>

  </Row> */}

<Signupfooter/>

      {/* <Row>
        <Col xs={12} sm={12} md={10} className='faqstarted4'>
          <div className='faqstartedbox4 d-flex'>
          <h1 className='faqstarthead4'>Ready to join the success stories?</h1><br></br></div>
          
          <button className='faqsubbutton4 d-flex'><p className='faqregis4'>Sign up now</p></button>
            <p className='faqstartpara4'>Start your journey with us today and unlock endless opportunities for your career growth</p>
        </Col>
      </Row> */}
      
      
      <br></br><br></br><br></br><br className='lapbreak'></br>
    </Container>
        </>
    );
}

export default Faqs;