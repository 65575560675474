import React from "react";

const LocationIcon = ({ color = "#CCCFD6" }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.95 10.925C7.08333 10.925 7.20833 10.904 7.325 10.862C7.44167 10.8207 7.55 10.75 7.65 10.65L11.2 7.1C11.3833 6.91667 11.475 6.68333 11.475 6.4C11.475 6.11667 11.3833 5.875 11.2 5.675C11 5.49167 10.7583 5.4 10.475 5.4C10.1917 5.4 9.95833 5.49167 9.775 5.675L6.95 8.5L6.25 7.8C6.06667 7.61667 5.83333 7.525 5.55 7.525C5.26667 7.525 5.03333 7.61667 4.85 7.8C4.65 8 4.55 8.24167 4.55 8.525C4.55 8.80833 4.65 9.04167 4.85 9.225L6.25 10.65C6.35 10.75 6.45833 10.8207 6.575 10.862C6.69167 10.904 6.81667 10.925 6.95 10.925ZM8 19.625C7.86667 19.625 7.73333 19.6 7.6 19.55C7.46667 19.5 7.35 19.4333 7.25 19.35C4.81667 17.2 3 15.2043 1.8 13.363C0.6 11.521 0 9.8 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.021 0.741667 5.88333 0 8 0C10.1167 0 11.979 0.741667 13.587 2.225C15.1957 3.70833 16 5.7 16 8.2C16 9.8 15.4 11.521 14.2 13.363C13 15.2043 11.1833 17.2 8.75 19.35C8.65 19.4333 8.53333 19.5 8.4 19.55C8.26667 19.6 8.13333 19.625 8 19.625Z"
        fill={color}
      />
    </svg>
  );
};

export default LocationIcon;
