
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styletac.css';
import terms from '../../Assets/images/Terms.png';
import Termsmob from '../../Assets/images/Termsmob.png';
import Signupfooter from '../SignupFooter/signupfooter';

const TermsandCondition = () => {
  return (
    <>
      <Container className='termspagemain'>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="termscon d-flex">
              <img src={terms} alt="terms" className="termsimg" />
              <img src={Termsmob} alt='Terms' className='termsimgmob'/>
              <span className='termsconimg d-flex justify-content-center align-items-center'></span>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12}>
            <p className='termstext'>Terms & Conditions</p>

            <p className='termssubtext'>Agreement to Terms of Service</p>

            <p className='termstext2'>By accessing or using any services provided by PluginLive Technologies Pvt Ltd or its affiliates, you agree to be bound by these Terms and Conditions</p>

          </Col>

          <Col xs={12} sm={12} md={12}>
              <p className='termspara'>
              Your use of our services is voluntary, and you have the option to discontinue at any time. Any personal information you provide to us is voluntary, and you will not be subject to adverse consequences if you choose not to provide it. However, certain services may require the use of personal information for functionality.
              </p>

              <p className='termspara'>
              We may seek your consent to collect, hold, use, and disclose your personal information for purposes outlined in our Privacy Policy or any other lawful purposes. You agree to provide accurate and up-to-date information when using our services and to maintain the confidentiality of any account credentials or passwords associated with our services. 
              </p>

              <p className='termspara'>
              You agree not to engage in any unlawful or unauthorized activities when using our services, and we reserve the right to modify, suspend, or terminate any part of our services at any time without prior notice. We are not liable for any damages or losses resulting from your use of our services, including but not limited to, direct, indirect, incidental, or consequential damages. 
              </p>

              <p className='termspara'>
              These Terms and Conditions constitute the entire agreement between you and PluginLive Technologies Pvt Ltd regarding your use of our services and supersede any prior agreements or understandings. 
              </p>

              <p className='termspara'>
              If you have any questions or concerns regarding these Terms and Conditions, please contact us at 
              <a class="termsemail" href=''>contact@pluginlive.com</a>
              </p>

          </Col>

          <Signupfooter/>

          {/* <Col xs={12} sm={12} md={12} className='termstarted2 d-none d-lg-block'>
            <div className='termstartedbox2 d-flex'>
              <h1 className='termstarthead2'>Ready to join the success stories?</h1><br></br></div>
              
            <button className='termsubbutton2 d-flex'><p className='termregis2'>Sign up now</p></button>
            <p className='termstartpara2'>Start your journey with us today and unlock endless opportunities for your career growth</p>
          </Col>

          <Col xs={12} sm={12} md={10} className='termstartedm  d-sm-none'>
            <div className='termstartedboxm d-flex'>
              <h1 className='termstartheadm'>Ready to join the success stories?</h1><br></br></div>
            <button className='termsubbuttonm d-flex'><p className='termregism'>Sign up now</p></button>
            <p className='termstartparam'>Start your journey with us today and unlock endless opportunities for your career growth</p>
          </Col> */}

        </Row>
        
        
        <br></br><br></br><br></br><br className='lapbreak'></br>
      </Container>
    </>
  );
}

export default TermsandCondition;
