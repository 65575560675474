import React,{useState,useEffect,useRef} from 'react';
import { Container, Row, Col,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylee.css';
import experience from './images/experience.png';
import play from './images/playbutton.png';
import brand1 from './images/brand.png';
import access1 from './images/access.png';
import automation1 from './images/automation.png';
import hire1 from './images/hire.png';


import phone from './images/phone.png';
import arrow from './images/arrow.png';
import pp1 from './images/ppl1.png';
import pp2 from './images/ppl2.png';
import pp3 from './images/ppl4.png';
import whitea from './images/white circle arrow.png';



import Hover1 from '../../Assets/images/hover1.png';
import Hover2 from '../../Assets/images/hover2.png';
import Hover3 from '../../Assets/images/hover3.png';
import Clickcore from '../../Assets/images/clickincore.png';

import Gif from '../../Assets/images/loadinggif.gif';

import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useNavigate } from 'react-router-dom';
// import Aos from 'aos';
// import 'aos/dist/aos.css';
import { duration } from 'moment';
import Testimonialsexp from '../testimonials/testimonialexp';

import Signupfooter from '../SignupFooter/signupfooter';
import CircleComp from '../CircleComp/circlecomp';

import LogoComp from '../LogoComponent/logocomp';

import Section from '../Mainsection/index';
import Unlockindex from "../UnlockComponent/unlockindex";


const trainContent = `Equip yourself for success with tailored skill development programs. 
Enhance your expertise and prepare to excel in the professional arena with our support. `;

const assessContent = `Ready to demonstrate your proficiency like a seasoned pro? 
Access premium skill assessments to showcase your capabilities 
and distinguish yourself in the field. 
Let's excel at these assessments and unlock opportunities together.`;

const experiencedContent = `Ready to demonstrate your proficiency like a seasoned pro? 
Access premium skill assessments to showcase your capabilities and distinguish yourself. 
Let's excel at these assessments and unlock opportunities together.`;



const  Experienced = () => {

  // chat animation start 

// const rowRef = useRef(null);

// useEffect(() => {
//   const revealRow = () => {
//     const row = rowRef.current;
//     if (!row) return;

//     const windowHeight = window.innerHeight;
//     const rowTop = row.getBoundingClientRect().top;
//     const revealPoint = 150;

//     if (rowTop < windowHeight - revealPoint) {
//       row.classList.add('active');
//     } else {
//       row.classList.remove('active');
//     }
//   };

//   window.addEventListener('scroll', revealRow);

//   return () => {
//     window.removeEventListener('scroll', revealRow);
//   };
// }, []);


// chat animation end 

  const navigate = useNavigate();

    const Luck = {
        color: 'linear-gradient(90deg, #66BCFF -4.59%, #3442D9 107.81%)',
    };

     /* box click start */
  const [activeFeature, setActiveFeature] = useState(null);
  const containerRefs = [useRef(), useRef(), useRef(), useRef()];
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (activeFeature !== null) {
        const activeContainerRef = containerRefs[activeFeature];
        if (activeContainerRef.current && !activeContainerRef.current.contains(event.target)) {
          setActiveFeature(null);
        }
      }
    };
  
    document.addEventListener('mousedown', handleOutsideClick);
  
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [activeFeature, containerRefs]);
  
  const toggleDiv = (index) => {
    setActiveFeature((prevActiveFeature) => (index === prevActiveFeature ? null : index));
  };
  
  /* box click end */


  // Animation boxes start

  
  const [isDancingexp, setIsDancingexp] = useState(false);

  useEffect(() => {
    const handleScrollexp = () => {
      // Check the scroll position
      const scrollPosition = window.scrollY;
      
      // You can adjust the threshold as needed
      if (scrollPosition > 200) {
        setIsDancingexp(true);
      } else {
        setIsDancingexp(false);
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScrollexp);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScrollexp);
    };
  }, []);


  const [isVisibleexp, setIsVisibleexp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisibleexp(true);
    }, 500); 

    return () => clearTimeout(timer);
  }, []);

  const handleAnimationEnd = () => {
    // Perform shake animation when the ease-in animation ends
    document.querySelector('.dancing-divvexp').classList.add('shakeexp');
  };

    // animation boxes end 


  // const [isScrollingexp, setIsScrollingexp] = useState(false);
  // const containerRefexp = useRef(null);

  // useEffect(() => {
  //     const handleScrollexp = () => {
  //         const containerexp = containerRefexp.current;
  //         const rectexp = containerexp.getBoundingClientRect();
  //         if (rectexp.top >= 0 && rectexp.bottom <= window.innerHeight) {
  //             setIsScrollingexp(true);
  //         } else {
  //             setIsScrollingexp(false);
  //         }
  //     };

  //     window.addEventListener('scroll', handleScrollexp);

  //     return () => {
  //         window.removeEventListener('scroll', handleScrollexp);
  //     };
  // }, []);

    // luck animation start 

    const [text] = useTypewriter({
      words: ['renown', 'refinement', 'reputation'],
      loop: {},
      typeSpeed: 150,
      deleteSpeed: 150,
      delaySpeed: 800,
  });


  // luck animation end 

  // useEffect(()=> {
  //   Aos.init({duration:800})
  // },[]);


  const handleClicksignup = () => {
    window.location.href = '/Student_Registration';
    window.scrollTo(0, 0);
  };
  
  const handleClick = () => {
    window.location.href = '/joblisting';
    window.scrollTo(0, 0);
  };
  
  
  
  
    const [isInView, setIsInView] = useState(false);

    const paragraphContent = `Foster your future.
Elevate your expertise.
Beyond mere`;
  
    const words = ['renown', 'refinement', 'reputation'];
  
    const headers = ['Advanced Matching Algorithms', 'Instant Alert Mechanism ', 'Customized Career Recommendations', 'Intuitive Career Pathway'];

    const subheaders=['Utilize cutting-edge algorithms for precise job matching, ensuring tailored opportunities that align perfectly with your experience and career objectives.','Stay updated and ahead with reliable notifications, apply on-the-fly, and effortlessly manage interview schedules.','Say farewell to generic searches! Our advanced lateral applicant tracking system aligns your unique proficiencies with tailored job prospects, providing exclusive skill-matched suggestions.','Navigate your journey seamlessly. Monitor progress, access expert advice, and seize upskilling opportunities for your dream career.'];
    
    return(
        <>  
        <Container className='experiencedpagemain'>
      {/* <Row>
        <Col xs={12} sm={12} md={6}>
          <div className="content11">
            <p>Foster your future.<br></br>
Elevate your expertise.<br></br>
Beyond mere<br></br>

<span className='luck'  style={{ 
                fontWeight: 'bold', 
                background: 'linear-gradient(270.17deg, #073CA5 -18.76%, #63B5FD 111.97%)', 
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: "'Plus Jakarta Sans', sans-serif",
                fontWeight: 900, 
              }}>
                {text}
              </span>
              <span style={{ 
                color: '#073CA5',
              }}>
                <Cursor CursorStyle='|' />
              </span>
            </p>
          </div>
          
        </Col><br></br>
        <Col xs={12} sm={12} md={6}>
            <div className="imag-container1 d-flex">
                <div className="background-container1"></div> 
                <span className='orgvector1 d-flex justify-content-center align-items-center'>
                </span>
                <img src={experience} alt="fresher" className="img-fluid" />
            </div>

            <div className={`dancing-divvexp ${isVisibleexp ? 'visible' : ''}`} >

          <div className={`dancing-divexp ${isDancingexp ? 'dancing' : ''}`}>

          <div className='imag-container2'> 
          <span className='violetbox1 d-flex '><p className='vtexp1 mt-4 ms-3'>100+<br/></p><p className='vtexp2'>Corporates</p></span>
          </div>  

            <div className='imag-container3'>
            <div className='linebox1 d-flex'>

              <div className='hlinebox1fre d-flex'>
                <div className='hlinebox2fre d-flex'>
                </div>
              </div>

              <div className='rlinebox1fre d-flex'>
                <div className='rlinebox2fre d-flex'>
                </div>
              </div>


              <p className='lineboxtextexp'>34k<br/><p className='lineboxtext2exp'>Colleges</p></p>
            </div>         
            
            </div>
          

          <div className='imag-container5 d-flex'>
          <span className='yellowbox1'><p className='ytexp1'>100k<br/>
              <p className='ytexp2 '>Students</p></p></span>
          </div>

          </div>

          </div>


          <div className='imag-container4 d-flex'>
            <span className='blueca1'><img className='d-flex' src={whitea} alt='white arrow'/></span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={6} md={6} className='firstsignup1'>
        <button className='signinexp' onClick={handleClicksignup}><p className='siginsexp mt-2'>Sign up now!</p></button>
         </Col>

        <Col xs={6} sm={6} md={6}className='firstsignup1'>
        <button className='watchexp' onClick={handleClick}><p className='watchs1'>Job Listing</p></button>
        </Col>

        <Col xs={12} sm={12} md={12} className='firstsignup11'>
        <p className='linetextexp'>Ready to advance your career with PluginLive?<br></br> Receive instant updates, apply seamlessly, and turn your career aspirations into achievements! Experience streamlined lateral placement process – stay ahead with real-time notifications and unlock goal-aligned opportunities.<br></br><br></br>Join PluginLive today and take the next step toward your dreams.</p><br></br>
        </Col>

        </Row> */}


        <Section paragraph={paragraphContent} words={words} imageSrc={experience} bgpartClassName='bgpart2' profileType='experienced'/>


        <div className="Fmaincontent">
        Ready to advance your career with PluginLive?
Receive instant updates, apply seamlessly, and turn your career aspirations into achievements! Experience streamlined lateral placement process - stay ahead with real-time notifications and unlock goal-aligned opportunities.
<br></br><br></br>
Join PluginLive today and take the next step toward your dreams.


        </div> 

       

        <Row>
          <Col xs={12} sm={12} md={12} className='sechead1'>
          {/* <span className='title1tagexp d-flex justify-content-center align-items-center'><span className='ttextexp'>Explore</span></span> */}
          <h1 className='title2tagexp d-flex justify-content-center align-items-center'>Unlock Your Potential </h1>
          <h1 className='title2tagexp2 d-flex justify-content-center align-items-center'>Our lateral hiring solutions for your enhanced job search experience</h1><br></br>
          </Col>


          <Unlockindex headers={headers} subheaders={subheaders}/>
          
      
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} className='corevaluestab'>
                <div className='brandexp'>
                <p className='brandhexp' style={{color:"rgba(255, 255, 255, 1)"}}>Advanced Matching Algorithms</p>
                        {/* <!-- <input type="text" class="form-control form-control-lg" placeholder="Text Box 1"> --> */}
                        <p className='brandppexp'>Utilize cutting-edge algorithms for precise job matching, ensuring tailored opportunities that align perfectly with your experience and career objectives.</p> 
                </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevaluestab'>
            <div className='hireexp'>
            <p className='hirehexp' style={{color:"rgba(255, 255, 255, 1)"}}>Intuitive Career Pathway</p>
                        <p className=' hireppexp'>Navigate your journey seamlessly. Monitor progress, access expert advice, and seize upskilling opportunities for your dream career.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevaluestab'>
            <div className='digitalexp'>
            <p className='digitalhexp' style={{color:"rgba(255, 255, 255, 1)"}}>Customized Career Recommendations</p>
                        <p className='digitalppexp'>Say farewell to generic searches! Our advanced lateral applicant tracking system aligns your unique proficiencies with tailored job prospects, providing exclusive skill-matched suggestions.
</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevaluestab'>
            <div className='trainexp'>
            <p className='trainhexp' style={{color:"rgba(255, 255, 255, 1)"}}>Instant Alert Mechanism</p>
                        <p className='trainppexp'>Stay updated and ahead with reliable notifications, apply on-the-fly, and effortlessly manage interview schedules.</p>      
            </div>
          </Col>
        </Row>

        {/* <Row className='d-flex justify-content-center'>
        <Col xs={12} sm={12} md={12}>
          <span className='title1tagexp d-flex justify-content-center align-items-center'><span className='ttextexp'>Vetted by Industry Leaders</span></span><br></br>
          <p className='title2tagexp2g d-flex justify-content-center align-items-center'>Our Partnerships with Seasoned Veterans</p><br/>
          <h1 className='title2tagexp d-flex justify-content-center align-items-center'>Corporate</h1><br></br>
          </Col>

          <div className='logos'>
          <div className='logo-slide'>
            <img className='client' src={Client} alt='client'/>
            <img className='cuemath' src={Cuemath} alt='Cuemath'/>
            <img className='Rbl' src={Rbl} alt='Rbl'/>
            <img className='Rb' src={Rb} alt='Rb'/>
            <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
            <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
            <img className='Lic' src={Lic} alt='Lic'/>
            <img className='Kotak' src={Kotak} alt='Kotak'/>
            <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
            <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
            <img className='Ak' src={Ak} alt='Ak'/>
            <img className='Dabur' src={Dabur} alt='Dabur'/>
            <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
            <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
            <img className='Austin' src={Austin} alt='Austin'/>
            <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
            <img className='Nse' src={Nse} alt='Nse'/>
            <img className='Indusland' src={Indusland} alt='Indusland'/>
          </div>

          <div className='logo-slide'>
            <img className='client' src={Client} alt='client'/>
            <img className='cuemath' src={Cuemath} alt='Cuemath'/>
            <img className='Rbl' src={Rbl} alt='Rbl'/>
            <img className='Rb' src={Rb} alt='Rb'/>
            <img className='Trigyn' src={Trigyn} alt='Trigyn'/>
            <img className='Delhivery' src={Delhivery} alt='Delhivery'/>
            <img className='Lic' src={Lic} alt='Lic'/>
            <img className='Kotak' src={Kotak} alt='Kotak'/>
            <img className='Lloyds' src={Lloyds} alt='Lloyds'/>
            <img className='Corpcare' src={Corpcare} alt='Corpcare'/>
            <img className='Ak' src={Ak} alt='Ak'/>
            <img className='Dabur' src={Dabur} alt='Dabur'/>
            <img className='Coreintegra' src={Coreintegra} alt='Coreintegra'/>
            <img className='Lighthouse' src={Lighthouse} alt='Lighthouse'/>
            <img className='Austin' src={Austin} alt='Austin'/>
            <img className='Hdfc' src={Hdfc} alt='Hdfc'/>
            <img className='Nse' src={Nse} alt='Nse'/>
            <img className='Indusland' src={Indusland} alt='Indusland'/>
          </div>
        </div>

        <Col xs={12} sm={12} md={12}>
          <h1 className='title2tagexp d-flex justify-content-center align-items-center mt-5'>Institutes</h1><br></br>
        </Col>

        <div className='logoss'>
          <div className='logos-slide'>
            <img className='instlogoexp' src={Pes} alt='pes'/>
            <img className='instlogoexp' src={Ifim} alt='Ifim'/>
            <img className='instlogoexp' src={Holymary} alt='Holymary'/>
            <img className='instlogoexp' src={Ramanujam} alt='Ramanujam'/>
            <img className='instlogoexp' src={Motilal} alt='Motilal'/>
            <img className='instlogoexp' src={Avcol} alt='Avcol'/>
            <img className='instlogoexp' src={Joseph} alt='Joseph'/>
            <img className='instlogoexp' src={Vishwa} alt='Vishwa'/>
            <img className='instlogoexp' src={Sardar} alt='Sardar'/>
            <img className='instlogoexp' src={Guru} alt='Guru'/>
            <img className='instlogoexp' src={Vijaybhoomi} alt='Vijaybhoomi'/>
          </div>

          <div className='logos-slide'>
          <img className='instlogoexp' src={Pes} alt='pes'/>
            <img className='instlogoexp' src={Ifim} alt='Ifim'/>
            <img className='instlogoexp' src={Holymary} alt='Holymary'/>
            <img className='instlogoexp' src={Ramanujam} alt='Ramanujam'/>
            <img className='instlogoexp' src={Motilal} alt='Motilal'/>
            <img className='instlogoexp' src={Avcol} alt='Avcol'/>
            <img className='instlogoexp' src={Joseph} alt='Joseph'/>
            <img className='instlogoexp' src={Vishwa} alt='Vishwa'/>
            <img className='instlogoexp' src={Sardar} alt='Sardar'/>
            <img className='instlogoexp' src={Guru} alt='Guru'/>
            <img className='instlogoexp' src={Vijaybhoomi} alt='Vijaybhoomi'/>
          </div>
        </div>
        </Row> */}
        <br></br>

        <LogoComp/>

              <br></br><br></br><br></br><br></br>

        {/* <Row className='phonebox2'>
        <Col xs={12} sm={12} md={7}>
        <div className='phonetitles2'>
        <span className='phonetitle1tag2exp d-flex justify-content-center align-items-center'><span className='ttext'>Take you career to new heights</span></span><br></br>  
        <h1 className='phoneheadtitle2'>Start Your Journey to Success</h1>
        <p className='phonebody2lap'>Transform your aspirations into achievements. Join us and set forth your professional goals with the best of the best lateral placement agencies!</p>
          </div>  
        </Col><br></br>
       
        <Col xs={12} sm={12} md={6}>   



        </Col>

        <Col xs={12} sm={12} md={5}>
          <img className='arrow2' src={arrow} alt='arrow'/>
        </Col>

        <Col xs={12} sm={12} md={6}>
          <div className="image-phone-container2">
            <div ref={containerRefexp} className={`container ${isScrollingexp ? 'scrolling' : ''}`}>
              <div className="orgcircle2"></div>
              <div className="viocircle2"></div>
              <div className="bluecircle2"></div>
            </div>
          
          <div style={{ position: 'relative' }}>

          <img className='phone2' src={phone} alt='phone'>
            </img>

            <img src={Gif} alt='' className='gifexp'/>
            </div>  



          </div>
        </Col>




        <Col xs={12} sm={12} md={6} >
        <p className='phonebody2mob'>Transform your aspirations into achievements. Join us and set forth your professional goals with the best of the best lateral placement agencies!</p>
        </Col>
      </Row> */}


      {/* <Row>
        <Col xs={12} sm={12} md={6}>
          <div className='traincircleep'></div>
          <div className='traincircle1exp'></div>
          <div className='trainingtitles'>
          <h1 className='trainheadtitleexp'>Training Partners</h1>
            </div>
            
            <p class="head12exp">Equip yourself for success with tailored skill development programs.<br ></br> Enhance your expertise and prepare to excel in the professional arena with our support. </p>
            <p class="head121">Equip yourself for success with tailored skill development programs.<br className='lapbreak'></br>Enhance your expertise and prepare to excel in the professional arena with our support. </p>

            
        </Col>

        <Col xs={12} sm={12} md={7}>
        <div className='assesscircleep'></div>
        <div className='assesscircletabep'></div>
        <div className='assesstitles'>
     
        <h1 className='assesheadtitleexp'>Assessment Partners</h1>
        <h1 className='assesheadtitletab'>Assessment Partners</h1>
          </div>
          
          <p class="assesshead1exp">Ready to demonstrate your proficiency like a seasoned pro?<br></br> Access premium skill assessments and resources to showcase your capabilities and distinguish yourself in the field.<br></br>Let's excel in these assessments and unlock numerous opportunities together.</p>
          <p class="assesshead1tab">Ready to demonstrate your proficiency like a seasoned pro? Access premium skill assessments and resources to showcase your capabilities and distinguish yourself in the field. Let's excel in these assessments and unlock numerous opportunities together.</p>

        
        </Col>
      </Row> */}

<CircleComp trainContent={trainContent} assessContent={assessContent} experiencedContent={experiencedContent} page='Experienced' /> 

      <br></br><br></br><br></br><br></br><br className='breakhideinmob'></br><br className='breakhideinmob'></br>

      
          <Testimonialsexp/>

          <Signupfooter profile="experienced"/>
        
      

      {/* <Row>
        <Col xs={12} sm={12} md={10} className='startedexp'>
          <div className='startedboxexp d-flex'>
          <h1 className='startheadexp'>Ready to join the success stories?</h1><br></br></div>
          <p className='startparaexp d-sm-none'>Start your journey with us today and unlock endless opportunities for your career growth. </p>
          
          <button className='subbuttonexp d-flex' onClick={()=>navigate("/Student_Registration")}><p className='regisexp'>Sign up now</p></button>
            <p className='startparatab'>Start your journey with us today and unlock endless opportunities for your career growth.</p>
        </Col>
        
      </Row> */}
      
      <br/><br/><br/>

    </Container>
        </>
    );
}

export default Experienced;


function expchat(){
  return(
    <Row className='chatexp' >

      <Col xs={12} sm={12} md={12}>
        <div className='chathead'>
        <span className='chatitle1tag d-flex justify-content-start'><span className='ctextexp d-flex justify-content-start'>Real Voices, Real Success</span></span><br></br>  
        <h1 className='chatheadtitleexp d-flex justify-content-start'>Here’s what our clients have to say</h1><br></br><br></br>
        </div>
        
        
        </Col>

        <Row 
        // ref={rowRef} 
        className='testimonialsexp row-reveal'>

        <Col 
//         xs={isInView ? 24 : 12} 
// sm={isInView ? 24 : 12}
// md={isInView ? 24 : 6}
// className={`chatbox1exp ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

xs={12} sm={12} md={6}
className="chatbox1exp"

         
         >
          <img className='chatimg1exp' src={pp3} alt='ppl1'/>
          <span className='chatbox11exp d-flex'>
            <p className='chatboxtext1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br></br><p className='namesm mt-3'>Name,<p className='desgsm'> Designation</p></p></p>
            
            <p className='chatboxtext1tab'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>

            <span className='chatbox111exp d-flex justify-content-center align-items-center'></span>   
            <span className='chatbox111tabexp d-flex justify-content-center align-items-center'></span>  

            </span>
            {/* <span className='chatbox111'></span>   
            <span className='chatbox111tab'></span>        */}
        </Col>

        <Col 
        
//         xs={isInView ? 24 : 12} 
// sm={isInView ? 24 : 12}
// md={isInView ? 24 : 6}
// className={`chatbox2 ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"


xs={12} sm={12} md={6}
className="chatbox2exp"

>
          <span className='chatbox21exp d-flex'>
          <p className='chatboxtext21'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br></br><p className='namesm mt-2'>Name,<p className='desgsm'> Designation</p></p></p>
          <p className='chatboxtext21tab'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>

          </span>
          <span className='chatbox121exp d-flex justify-content-center align-items-center'></span>
          <span className='chatbox121tab d-flex justify-content-center align-items-center'></span>

          <img className='chatimg2tab' src={pp1} alt='burger'/>
        <img className='chatimg2exp' src={pp1} alt='burger'/>
        </Col>

        <Col 
//         xs={isInView ? 24 : 12} 
// sm={isInView ? 24 : 12}
// md={isInView ? 24 : 6}
// className={`extratestimonial ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"


xs={12} sm={12} md={6}
className="extratestimonial"
>
          
        <span className='chatbox331 d-flex'>
          <p className='chatboxtext31'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm mt-1'>Name,<p className='desgsm'> Designation</p></p></p>
          </span>
          <span className='chatbox1331exp d-flex justify-content-center align-items-center'></span>
          <img className='chatimg33exp' src={pp2} alt='burger'/>
        </Col>

        <Col 
        
//       xs={isInView ? 24 : 12}
// sm={isInView ? 24 : 12}
// md={isInView ? 24 : 6}
// className={`chatbox4 ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

xs={12} sm={12} md={6}
className="chatbox4exp"

>
        <img className='chatimg4exp' src={pp2} alt='burger'/>
        <img className='chatimg4tab' src={pp2} alt='burger'/>
        <span className='chatbox41exp d-flex'>
            <p className='chatboxtext41'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm mt-2'>Name,<p className='desgsm'> Designation</p></p></p>
            <p className='chatboxtext41tab'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
        
            <span className='chatbox141tab d-flex justify-content-center align-items-center'></span>
        <span className='chatbox141exp d-flex justify-content-center align-items-center'></span>
        
        
        </span>
        {/* <span className='chatbox141tab'></span>
        <span className='chatbox141'></span> */}
        </Col>

        <Col xs={12} sm={12} md={6} className='chatboxnil'>
          {/* <h1>Hellooo</h1> */}
        </Col>

        <Col 
        
//         xs={isInView ? 24 : 12}
// sm={isInView ? 24 : 12}
// md={isInView ? 24 : 6}
// className={`chatbox5 ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"
xs={12} sm={12} md={6}
className='chatbox5exp'

>
        <img className='chatimg5exp' src={pp3} alt='burger'/>
        <img className='chatimg5tab' src={pp3} alt='burger'/>
        <span className='chatbox51exp d-flex'>
        <p className='chatboxtext51'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesm mt-1'>Name,<p className='desgsm'> Designation</p></p></p>
            <p className='chatboxtext51tab'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.<br></br><p className='namesm'>Name,<p className='desgsm'> Designation</p></p></p>
            <span className='chatbox151exp d-flex justify-content-center align-items-center'></span>

        </span>
        <span className='chatbox151mobexp d-flex justify-content-center align-items-center'></span>
        <span className='chatbox151tab d-flex justify-content-center align-items-center'></span>
        </Col>

        </Row>
      </Row>
  );
}