import React from 'react'

const ContactIcon = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 22C2.71667 22 2.479 21.904 2.287 21.712C2.09567 21.5207 2 21.2833 2 21C2 20.7167 2.09567 20.4793 2.287 20.288C2.479 20.096 2.71667 20 3 20H17C17.2833 20 17.5207 20.096 17.712 20.288C17.904 20.4793 18 20.7167 18 21C18 21.2833 17.904 21.5207 17.712 21.712C17.5207 21.904 17.2833 22 17 22H3ZM3 2C2.71667 2 2.479 1.904 2.287 1.712C2.09567 1.52067 2 1.28333 2 1C2 0.716667 2.09567 0.479 2.287 0.287C2.479 0.0956666 2.71667 0 3 0H17C17.2833 0 17.5207 0.0956666 17.712 0.287C17.904 0.479 18 0.716667 18 1C18 1.28333 17.904 1.52067 17.712 1.712C17.5207 1.904 17.2833 2 17 2H3ZM10 12C10.8333 12 11.5417 11.7083 12.125 11.125C12.7083 10.5417 13 9.83333 13 9C13 8.16667 12.7083 7.45833 12.125 6.875C11.5417 6.29167 10.8333 6 10 6C9.16667 6 8.45833 6.29167 7.875 6.875C7.29167 7.45833 7 8.16667 7 9C7 9.83333 7.29167 10.5417 7.875 11.125C8.45833 11.7083 9.16667 12 10 12ZM2 19C1.45 19 0.979333 18.8043 0.588 18.413C0.196 18.021 0 17.55 0 17V5C0 4.45 0.196 3.97933 0.588 3.588C0.979333 3.196 1.45 3 2 3H18C18.55 3 19.021 3.196 19.413 3.588C19.8043 3.97933 20 4.45 20 5V17C20 17.55 19.8043 18.021 19.413 18.413C19.021 18.8043 18.55 19 18 19H2ZM3.75 17H16.25C15.5 16.0667 14.5917 15.3333 13.525 14.8C12.4583 14.2667 11.2833 14 10 14C8.71667 14 7.54167 14.2667 6.475 14.8C5.40833 15.3333 4.5 16.0667 3.75 17Z" fill="#999FAC"/>
</svg>

  )
}

export default ContactIcon;