import React, { useState } from "react";
import Flex from "../JobContainer/Flex";
import styled from "styled-components";
import Avatar from "../JobContainer/Avatar";

import Button from "../JobContainer/Button";
import AlarmOn from "../icons/Alarm_On";
import AcademicCap from "../icons/Academic_cap";
import LocationIcon from "../icons/LocationIcon";
import PaymentIcon from "../icons/PaymentIcon";

import { Pagination } from "antd";

import CheckTick from "../image/check_circle.png";

import { message, Empty } from "antd";
import { Link } from "react-router-dom";

import {
  VerticalDivider,
  OutlineDiv,
  RightDiv,
  RightDivv,
  ContentCenterDiv,
  PaddedLeftFlex,
  StyledFlex,
  Name,
  Address,
 
  Company,
  BoldText,
  NameAdd,
  ViewContent,
  Rows,
  Apply,
} from "./style";

import ApartmentIcon from "../icons/ApartmentIcon";
import Moment from "moment";
import { useNavigate } from "react-router-dom";

const SingleListed = ({ DataList, onPageChange, page }) => {
  const { REACT_APP_STUDENT_APPLICATION_URL } = process.env
  const navigate = useNavigate();

  const [selected, setSelected] = useState({});

  const onChange = (key) => {
    onPageChange(key);
  };
  const CheckIcon = (logo) => {
    if (logo) {
      return (

        <Avatar size={42} src={logo} boxShadow={true} />
      );  
    } else {
      return (


      <Avatar size={42} src={<ApartmentIcon />} boxShadow={true} padding={"3px"} />

      )
      
    
    }
  }

  const checkDate = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  const checkCity = (cityList) => {
    if (cityList !== undefined) {
      if (cityList?.length > 1) {
        return (
          <div>
            {cityList[0]?.name}, + {cityList?.length - 1}
          </div>
        );
      } else if (cityList?.length === 1) {
        return cityList[0]?.name;
      } else {
        return "-";
      }
    }
  }
  const onApplyClick = param => {
    localStorage.setItem('profile', "");
    window.scrollTo(0, 0);
    navigate(`/Student_Registration`)
  }

  const checkJobTypeLevel = (data,YOE) =>{
    const transformedData = data?.map(item => {
      switch (item) {
        case 'ENTRY':
          return ' Entry Level';
        case 'INTERN':
          return ' Internship';
        case 'EXPERIENCED':
          return YOE?.length > 0 ?` ${YOE[0]} - ${YOE[1]} Yrs Experience ` :` 0 - 1 Yrs Experience `
        default:
          return item;
      }
    })
  
    return transformedData?.toString();
  }
  
  // const viewRoles = (event, param) => {
  //   navigate(`/Job/${param.corporate.id}/${param.id}`);
    
  // };

  const viewRoles = (event, param) => {
    event.preventDefault(); // Prevent the default anchor tag behavior
    window.location.href = `/Job/${param.corporate.id}/${param.id}`;
    window.scrollTo(0, 0); // Scroll to the top after navigation
  };

  
  const formatNumber = (number = 0) => {
    return parseInt(number).toLocaleString('en-US')
}
  return (
    <>
    <div className="joblistpagemain">
      {DataList?.result?.map((item, index) => (
        <>
          <StyledFlex spaceBetween centerVertically isDisplay={true} className="joblistingbox">
            <OutlineDiv className="joblistingbox">
              <Flex centerVertically>
                {CheckIcon(item?.corporate?.logo)}

                <NameAdd column flexStart>
                  <Company>{item?.corporate?.name}</Company>
                  <Name>{item.tittle}</Name>
                </NameAdd>
                <RightDiv>
                  <Flex>
                    <Apply>Apply before </Apply>
                    &nbsp; &nbsp; <BoldText>{checkDate(item.endDate)}</BoldText>
                  </Flex>
                </RightDiv>
              </Flex>
            </OutlineDiv>
            <OutlineDiv>
              <ViewContent centerVertically>
                <PaddedLeftFlex>
                <Rows>
                    <Avatar src={<AlarmOn />} />
                  <Address>
                    {checkJobTypeLevel(item?.jobTypeLevels, item?.yearOfExperience)} ({item?.jobType === 'FULL_TIME' ? 'Full Time' : 'Part Time'})
                  </Address>
                </Rows>
                  <VerticalDivider type="vertical" />
                <Rows>
                <Avatar src={<LocationIcon />} />
                <Address>{item?.isAnyWhere ? 'AnyWhere' : checkCity(item.cities)}</Address>
                </Rows>
                  


                  <VerticalDivider type="vertical" />
                  <Rows>
                  <Avatar src={<PaymentIcon />} padding={"2px"} />
                  <Address>₹ {formatNumber(item.maxCompensation)}  {item?.CTCPeriod == 'ANNUALLY'
              ? 'Per annum'
              : item?.CTCPeriod == 'MONTHLY'
              ? 'Per month'
              : item?.CTCPeriod == 'WEEKLY'
              ? 'Per week'
              : item?.CTCPeriod == 'DAILY'
              ? 'Per day'
              : item?.CTCPeriod == 'HOURLY'
              ? 'Per hour'
              : '' }</Address>
                  </Rows>
                </PaddedLeftFlex>

                <RightDiv>
                  <Flex>
                      <Button.Default
                        onClick={() => onApplyClick(item)}
                        color={'#3249D7'}
                        background={'#EAEDFB'}
                        text={'Register to Apply'}
                        width={'auto'}
                      />
                  
                    &nbsp; &nbsp;&nbsp;
                    <Button.Secondary
                      onClick={(event) => viewRoles(event, item)}
                      text={"View"}
                      width={"80px"}
                    />
                  </Flex>
                </RightDiv>
              </ViewContent>
          
            </OutlineDiv>
          </StyledFlex>
        </>
      ))}

      {DataList?.result?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <ContentCenterDiv>
          <br></br>
          <Pagination
          showSizeChanger={false}
          current={page}
            defaultCurrent={1}
            total={DataList?.count}
            onChange={onChange}
          />
          <br></br>
        </ContentCenterDiv>
      )}
      </div>
    </>
  );
};
export default SingleListed;
