import React, { useState, useEffect,useRef } from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import "./style.css";
import Arrow from "../../Assets/images/white circle arrow.png";
import Fresherimg from "../Fresher/images/fresher.png";
import Experiencedimg from "../Experienced/images/experience.png";
import Collegeimg from "../Colleges/images/colleges.png";
import Partnerimg from "../Partner/images/partners.png";
import Corporateimg from "../Corporate/images/corporates.png";
import { useNavigate } from 'react-router-dom';
// import { setScrollToLogo, getScrollToLogo } from './ScrollManager';  

const Index = ({
  paragraph,
  words,
  imageSrc,
  bgpartClassName,
  profileType,
}) => {
  const { REACT_APP_AUTH_URL } = process.env;

  const [text] = useTypewriter({
    words,
    loop: 0, // 0 means infinite loop
    typeSpeed: 150,
    deleteSpeed: 150,
    delaySpeed: 800,
  });

  const [isDancing, setIsDancing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 200) {
        setIsDancing(true);
      } else {
        setIsDancing(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const getClassName = () => {
    if (imageSrc === Fresherimg) {
      return "bgpartimgfre";
    } else if (imageSrc === Experiencedimg) {
      return "bgpartimgexp";
    } else if (imageSrc === Collegeimg) {
      return "bgpartimgcol";
    } else if (imageSrc === Partnerimg) {
      return "bgpartimgpat";
    } else if (imageSrc === Corporateimg) {
      return "bgpartimgcorp";
    } else {
      return "default_classname";
    }
  };

  const isExperienced = imageSrc === Experiencedimg;
  const isSmallFontSize = ["college", "partner", "corporate"].includes(
    profileType
  );
  const hideSpan = isSmallFontSize;

  const handleClicksignupcorporate = () => {
    localStorage.setItem("profile", profileType);
    window.location.href = "/Student_Registration";
    window.scrollTo(0, 0);
  };

  const handleClicksignup = () => {
    if(profileType === "fresher")
    {
      window.open(`${REACT_APP_AUTH_URL}`, "_blank", "noreferrer");
    }
    else
    {
    const profileToStore =
      profileType === "partner" ? "training partner" : profileType;
    localStorage.setItem("profile", profileToStore);
    window.location.href = "/Student_Registration";
    window.scrollTo(0, 0);
    }
  };

  const handleClick = () => {
    window.location.href = "/joblisting";
    window.scrollTo(0, 0);
  };

  const handleClickKnow = () => {
    if (profileType === 'college') {
      navigate('/college', { state: { scrollToLogo: true } });
    } else if (profileType === 'partner') {
      navigate('/partner', { state: { scrollToLogo: true } });
    }
  };  

  return (
    <div className="containerm">
      <div className="Section1">
        <div
          className={`Sec1para ${isSmallFontSize ? "small-font-size" : ""} ${
            profileType === "fresher" ? "fresprolef" : ""
          } ${profileType === "experienced" ? "expprolef" : ""} ${
            profileType === "college" ? "colprolef" : ""
          } ${profileType === "partner" ? "partprolef" : ""} ${
            profileType === "corporate" ? "corprolef" : ""
          }`}
        >
          {paragraph}
          {!hideSpan && (
            <>
              <br />
              <span
                className="luck"
                style={{
                  fontWeight: "bold",
                  background:
                    "linear-gradient(270.17deg, #073CA5 -18.76%, #63B5FD 111.97%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontWeight: 900,
                  height: "70px",
                }}
              >
                {text}
              </span>
              <span
                style={{
                  color: "#073CA5",
                }}
              >
                <Cursor CursorStyle="|" />
              </span>
            </>
          )}
        </div>
      </div>

      <div
        className={`Section2 ${isExperienced ? "experienced-margin-top" : ""}${
          profileType === "partner" ? "partner-margin-top" : ""
        } ${profileType === "college" ? "college-margin-top" : ""} ${
          profileType === "corporate" ? "collgmaincnt" : ""
        }`}
      >
        <div className="Sec2fullpart">
          <div className={`dancing-divv ${isVisible ? "visible" : ""}`}>
            <div className={`dancing-div ${isDancing ? "dancing" : ""}`}>
              <div className="Sec2boxes">
                <div
                  className={`Sec2box1 ${
                    profileType === "college" ? "college-bg1" : ""
                  }`}
                >
                  <div className="Sec2universities">
                    <span className="Sec2Count">34k</span>
                    <br /> <span className="Sec2text">Colleges</span>
                  </div>
                  {[...Array(25)].map((_, index) => (
                    <div className="Sline" key={index}></div>
                  ))}
                </div>
                <div
                  className={`Sec2box2 ${
                    isExperienced ? "experienced-bg" : ""
                  }${profileType === "college" ? "college-bg2" : ""}`}
                >
                  <div className="Sec3box2text">
                    <span className="Sec2Count2">100k</span>
                    <br />
                    <span className="Sec2text"> Students</span>
                  </div>
                </div>
                <div
                  className={`Sec2box3 ${
                    profileType === "college" ? "college-bg" : ""
                  }`}
                >
                  <div className="Sec3box3text">
                    <span className="Sec2Count">100+</span>
                    <br /> <span className="Sec2text">Corporates</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="background">
            <span className={bgpartClassName}></span>
            <img
              src={imageSrc}
              alt="Fresher"
              className={`bgpartimg ${getClassName()}`}
            />
          </div>
          <div
            className={`arrowdiv ${
              profileType === "college" ? "college-arrow" : ""
            }`}
          >
            <span className="coverdiv">
              <img src={Arrow} alt="Arrow" className="arrowimg" />
            </span>
          </div>
        </div>
      </div>

      <div className={`Section3 ${profileType}`}>
        <div className="Sec1button">
          {profileType === "corporate" ? (
            <button
              className="Crequestbutton"
              onClick={handleClicksignupcorporate}
            >
              Request a demo
            </button>
          ) : (
            <>
              <button className="Csignupbutton" onClick={handleClicksignup}>
              {profileType === 'fresher' ? 'Login': 'Sign up now!' }
              </button>
              {(profileType === 'college' || profileType === 'partner') && (
                <button className='Cjoblistingbutton' onClick={handleClickKnow}>
                  Know more
                </button>
              )}

            {(profileType === 'fresher' || profileType === 'experienced') && (
                    <button className='Cjoblistingbutton' onClick={handleClick}>
                      Job Listing
                    </button>
                  )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;