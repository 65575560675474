import React from 'react'

const Twitter = ({ color = '#CCCFD6' }) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.53321 22C4.76035 22 2.17558 21.1818 0 19.7701C1.84714 19.8914 5.10692 19.601 7.1345 17.6389C4.08435 17.497 2.7088 15.1237 2.52938 14.1095C2.78854 14.211 4.02454 14.3327 4.72229 14.0487C1.21363 13.1562 0.675367 10.0325 0.79498 9.07911C1.45285 9.54564 2.56925 9.70791 3.00783 9.66734C-0.261606 7.29412 0.914594 3.72414 1.49273 2.95335C3.83899 6.25101 7.35532 8.1031 11.7055 8.20612C11.6234 7.84118 11.5801 7.46124 11.5801 7.07099C11.5801 4.27036 13.8115 2 16.564 2C18.0022 2 19.2981 2.61979 20.2077 3.61116C21.1688 3.3827 22.6151 2.84789 23.3222 2.3854C22.9658 3.68357 21.8562 4.76651 21.1851 5.16789C21.1906 5.18155 21.1796 5.15418 21.1851 5.16789C21.7746 5.07742 23.3698 4.76641 24 4.33266C23.6884 5.06196 22.512 6.27455 21.5466 6.95341C21.7262 14.9896 15.6656 22 7.53321 22Z" fill="#47ACDF"/>
</svg>

  )
}

export default Twitter
