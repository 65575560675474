export const MonthList = [
    { value: '01', name: "January" },
    { value: '02', name: "February" },
    { value: '03', name: "March" },
    { value: '04', name: "April" },
    { value: '05', name: "May" },
    { value: '06', name: "June" },
    { value: '07', name: "July" },
    { value: '08', name: "August" },
    { value: '09', name: "September" },
    { value: '10', name: "October" },
    { value: '11', name: "November" },
    { value: '12', name: "December" },
  ];
  
  export const YearList = 
    [
      {
          "value": "2030",
          "name": "2030"
      },
      {
          "value": "2029",
          "name": "2029"
      },
      {
          "value": "2028",
          "name": "2028"
      },
      {
          "value": "2027",
          "name": "2027"
      },
      {
          "value": "2026",
          "name": "2026"
      },
      {
          "value": "2025",
          "name": "2025"
      },
      {
          "value": "2024",
          "name": "2024"
      },
      {
          "value": "2023",
          "name": "2023"
      },
      {
          "value": "2022",
          "name": "2022"
      },
      {
          "value": "2021",
          "name": "2021"
      },
      {
          "value": "2020",
          "name": "2020"
      },
      {
          "value": "2019",
          "name": "2019"
      },
      {
          "value": "2018",
          "name": "2018"
      },
      {
          "value": "2017",
          "name": "2017"
      },
      {
          "value": "2016",
          "name": "2016"
      },
      {
          "value": "2015",
          "name": "2015"
      },
      {
          "value": "2014",
          "name": "2014"
      },
      {
          "value": "2013",
          "name": "2013"
      },
      {
          "value": "2012",
          "name": "2012"
      },
      {
          "value": "2011",
          "name": "2011"
      },
      {
          "value": "2010",
          "name": "2010"
      },
      {
          "value": "2009",
          "name": "2009"
      },
      {
          "value": "2008",
          "name": "2008"
      },
      {
          "value": "2007",
          "name": "2007"
      },
      {
          "value": "2006",
          "name": "2006"
      },
      {
          "value": "2005",
          "name": "2005"
      },
      {
          "value": "2004",
          "name": "2004"
      },
      {
          "value": "2003",
          "name": "2003"
      },
      {
          "value": "2002",
          "name": "2002"
      },
      {
          "value": "2001",
          "name": "2001"
      },
      {
          "value": "2000",
          "name": "2000"
      },
      {
          "value": "1999",
          "name": "1999"
      },
      {
          "value": "1998",
          "name": "1998"
      },
      {
          "value": "1997",
          "name": "1997"
      },
      {
          "value": "1996",
          "name": "1996"
      },
      {
          "value": "1995",
          "name": "1995"
      },
      {
          "value": "1994",
          "name": "1994"
      },
      {
          "value": "1993",
          "name": "1993"
      },
      {
          "value": "1992",
          "name": "1992"
      },
      {
          "value": "1991",
          "name": "1991"
      },
      {
          "value": "1990",
          "name": "1990"
      },
      {
          "value": "1989",
          "name": "1989"
      },
      {
          "value": "1988",
          "name": "1988"
      },
      {
          "value": "1987",
          "name": "1987"
      },
      {
          "value": "1986",
          "name": "1986"
      },
      {
          "value": "1985",
          "name": "1985"
      },
      {
          "value": "1984",
          "name": "1984"
      },
      {
          "value": "1983",
          "name": "1983"
      },
      {
          "value": "1982",
          "name": "1982"
      },
      {
          "value": "1981",
          "name": "1981"
      },
      {
          "value": "1980",
          "name": "1980"
      },
      {
          "value": "1979",
          "name": "1979"
      },
      {
          "value": "1978",
          "name": "1978"
      },
      {
          "value": "1977",
          "name": "1977"
      },
      {
          "value": "1976",
          "name": "1976"
      },
      {
          "value": "1975",
          "name": "1975"
      },
      {
          "value": "1974",
          "name": "1974"
      },
      {
          "value": "1973",
          "name": "1973"
      },
      {
          "value": "1972",
          "name": "1972"
      },
      {
          "value": "1971",
          "name": "1971"
      },
      {
          "value": "1970",
          "name": "1970"
      }
  ]

  export const educationDetails = [
    
    {
      label: 'UG Diploma',
      value: 'diploma',
    },
    {
      label: 'PG Diploma',
      value: 'p_g_diploma',
    },
    {
      label: 'Undergraduate',
      value: 'ug',
    },
    {
      label: 'Postgraduate',
      value: 'pg',
    },
    {
      label: 'Professional Degree',
      value: 'pd',
    },
  ]
  
  