/* eslint-disable */
import React from 'react'
import { Slider as AntdSlider } from 'antd'
import styled from 'styled-components'
import Flex from '../../JobContainer/Flex'

const SliderStyle = styled(AntdSlider)`
  .ant-slider-rail {
    height: 2px;
  }
  .ant-slider-track {
    height: 2px;
  }
`

const Slider = ({
  value,
  onChange,
  onAfterChange,
  marks,
  defaultValue,
  min,
  max,
  step,
  label,
  range
}) => {
  return (
    <Flex column>
      <SliderStyle
      range={range}
        value={value}
        onChange={onChange}
        onAfterChange={onAfterChange}
        marks={marks}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
      />
      <Flex spaceBetween>
        <div>₹{min + ' ' + label} </div>
        <div>₹{max + ' ' + label}</div>
      </Flex>
    </Flex>
  )
}
export default Slider
