import React,{ useRef, useState, useEffect } from 'react';
import { Container, Row, Col,Button,Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylec.css';
import colleges from './images/colleges.png';
import play from './images/playbutton.png';
import brand1 from './images/brand.png';
import access1 from './images/access.png';
import automation1 from './images/automation.png';
import hire1 from './images/hire.png';
import tablet from './images/tablet.png';
import arrow from './images/arrow.png';
import pp1 from './images/ppl1.png';
import pp2 from './images/ppl2.png';
import pp3 from './images/ppl4.png';
import whitea from './images/white circle arrow.png';
import { useNavigate } from 'react-router-dom';
import Client from '../../Assets/company/client.png';
import Cuemath from '../../Assets/company/cuemath.png';
import Rbl from '../../Assets/company/rblbank.png';
import Rb from '../../Assets/company/relevance_broking_services_logo.png';
import Trigyn from '../../Assets/company/trigyn.png';
import Delhivery from '../../Assets/company/delhivery.png';
import Lic from '../../Assets/company/lic.png';
import Kotak from '../../Assets/company/kotak.png';
import Lloyds from '../../Assets/company/lloyds.png';
import Corpcare from '../../Assets/company/corpcare.png';
import Ak from '../../Assets/company/ak.png';
import Dabur from '../../Assets/company/Dabur-Logo.png';
import Coreintegra from '../../Assets/company/coreintegra.png';
import Lighthouse from '../../Assets/company/lighthouse.png';
import Austin from '../../Assets/company/Austin-Logo-scaled.png';
import Hdfc from '../../Assets/company/hdfc.png';
import Nse from '../../Assets/company/nse.png';
import Indusland from '../../Assets/company/indusland.png';

import Pes from '../../Assets/institution/pesuniversity.jpg';
import Ifim from '../../Assets/institution/ifiminstitution.jpeg';
import Holymary from '../../Assets/institution/holy-mary-.png';
import Ramanujam from '../../Assets/institution/ramanu-logo.96e81bbdf814c655f1ab.png';
import Motilal from '../../Assets/institution/Logo_of_Motilal_Nehru_College.05914d823d3c4c9a1992.png';
import Avcol from '../../Assets/institution/avcol.jpg';
import Joseph from '../../Assets/institution/stjoseph.jpg';
import Vishwa from '../../Assets/institution/vishwa.png';
import Sardar from '../../Assets/institution/sardaepatel.png';
import Guru from '../../Assets/institution/gurunanak.png';
import Vijaybhoomi from '../../Assets/institution/vijaybhoomi.jpg';
import Jeppiar from '../../Assets/institution/jeppiaar.png';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useLocation } from 'react-router-dom';
import Hover1 from '../../Assets/images/hover1.png';
import Hover2 from '../../Assets/images/hover2.png';
import Hover3 from '../../Assets/images/hover3.png';
import Clickcore from '../../Assets/images/clickincore.png';
import Aos from 'aos';
import 'aos/dist/aos.css';

import Gif from '../../Assets/images/loadinggif.gif';
import Signupfooter from '../SignupFooter/signupfooter';

import LogoComp from '../LogoComponent/logocomp';
import CircleComp from '../CircleComp/circlecomp';
import Section from '../Mainsection/index';
import Unlockindex from "../UnlockComponent/unlockindex";



const trainContent = `Gear up for success with exclusive skill development programs.
Elevate your students' expertise and prepare them to conquer the professional world alongside us! `;

const assessContent = `Ready to showcase your students' skills like pros? 
Access top-notch evaluation tools to help them shine bright. 
Let's ace those assessments and open doors to endless opportunities together!`;

export default function College(){

  const logoCompRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const { state } = location;
    const scrollToLogo = state && state.scrollToLogo;
    console.log("useEffect triggered with scrollToLogo:", scrollToLogo);

    if (scrollToLogo) {
      console.log('Scrolling to logoComp');
      if (logoCompRef.current) {
        logoCompRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  // chat animation start 

  const rowRef = useRef(null);

  useEffect(() => {
    const revealRow = () => {
      const row = rowRef.current;
      if (!row) return;

      const windowHeight = window.innerHeight;
      const rowTop = row.getBoundingClientRect().top;
      const revealPoint = 150;

      if (rowTop < windowHeight - revealPoint) {
        row.classList.add('active');
      } else {
        row.classList.remove('active');
      }
    };

    window.addEventListener('scroll', revealRow);

    return () => {
      window.removeEventListener('scroll', revealRow);
    };
  }, []);


  // chat animation end  

  const navigate = useNavigate();

  const [text] = useTypewriter({
    words: ['Embrace our proven digitisation', 'Automation of campus placements', 'Trusted by campuses nationwide'],
    loop: {},
    typeSpeed: 150,
    deleteSpeed: 150,
    delaySpeed: 800,
});

const [isDancing, setIsDancing] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    // Check the scroll position
    const scrollPosition = window.scrollY;
    
    // You can adjust the threshold as needed
    if (scrollPosition > 200) {
      setIsDancing(true);
    } else {
      setIsDancing(false);
    }
  };

  // Add event listener for scroll
  window.addEventListener('scroll', handleScroll);

  // Remove event listener on component unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Show the div with delay when the component mounts
  const timer = setTimeout(() => {
    setIsVisible(true);
  }, 500); // Adjust the delay time as needed

  // Clear the timer on component unmount
  return () => clearTimeout(timer);
}, []);

const handleAnimationEnd = () => {
  // Perform shake animation when the ease-in animation ends
  document.querySelector('.dancecol-divv').classList.add('shake');
};

/* box click start */
  const [activeFeature, setActiveFeature] = useState(null);
const containerRefs = [useRef(), useRef(), useRef(), useRef()];

useEffect(() => {
  const handleOutsideClick = (event) => {
    if (activeFeature !== null) {
      const activeContainerRef = containerRefs[activeFeature];
      if (activeContainerRef.current && !activeContainerRef.current.contains(event.target)) {
        setActiveFeature(null);
      }
    }
  };

  document.addEventListener('mousedown', handleOutsideClick);

  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };
}, [activeFeature, containerRefs]);

const toggleDiv = (index) => {
  setActiveFeature((prevActiveFeature) => (index === prevActiveFeature ? null : index));
};

/* box click end */

  //   const [isScrolling, setIsScrolling] = useState(false);
  // const containerRef = useRef(null);

  // useEffect(() => {
  //     const handleScroll = () => {
  //         const container = containerRef.current;
  //         const rect = container.getBoundingClientRect();
  //         // Check if container is in viewport
  //         if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
  //             setIsScrolling(true);
  //         } else {
  //             setIsScrolling(false);
  //         }
  //     };

  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  /* chat animation */
  useEffect(()=> {
    Aos.init({duration:800})
  },[]);

  const handleClick = () => {
    window.location.href = '/Student_Registration';
    window.scrollTo(0, 0);
  };
  
  
    const [isInView, setIsInView] = useState(false);

    const paragraphContent = `
    <p class="mobcolwidthc">Take your placement cell online.Embrace our proven digitisation & automation of campus placements.Trusted by campuses nationwide.</p>
  <div class="colwidth1">Take your placement cell online.</div>
  <div class="colwidth2">Embrace our proven digitisation & automation of campus placements</div>
  <div class="colwidth3">Trusted by campuses nationwide.</div>
`;
  
    const words = ["region", "resources"];

    const headers = ['Industry Connections', 'Cutting-edge Resources ', 'Knowledge based workshops or competitive events for students', 'Opportunities for assessments'];

    const subheaders=['Access to a vast network of industry partners and alumni, providing valuable insights and opportunities for career advancement.','Utilize state-of-the-art tools and resources, including mock interview platforms, resume builders, and skill assessment tests, to enhance your employability.',' Expand student horizons with workshops and competitive events, fostering skill development and innovation for career readiness.','Unlock your student`s potential with tailored assessment opportunities, guiding them towards career success.'];

    return(
        <>  
        <Container className='collegepagemain'>
      {/* <Row>
        <Col xs={12} sm={12} md={6}>
          <div className="contentt2">
           

              <p>Take your placement cell online. <br className='lapbreak'></br>Embrace our proven digitisation & automation of campus placements<br className='lapbreak'></br> Trusted by campuses nationwide</p>

          </div>
          
        </Col><br></br>
        <Col xs={12} sm={12} md={6} className='colimgmovup'>
            <div className="im-container1 d-flex">
                <div className="background-container2"></div> 
                <span className='orgvector2 d-flex justify-content-center align-items-center'>
                 <img src={colleges} alt="fresher" className="img-fluid" />
                </span>
            </div>

            <div className={`dancecol-divv ${isVisible ? 'visible' : ''}`} >

            <div className={`dancecol-div ${isDancing ? 'dancing' : ''}`}>

          <div className='im-container2'> 
          <span className='violetbox2 d-flex '><p className='vt1col'>100+<br/></p><p className='vt2col'>Corporates</p></span>
          </div>  

            <div className='im-container3'>
            <div className='linebox2 d-flex'>
              <div className='hlinebox1col d-flex'>
                <div className='hlinebox2col d-flex'>
                </div>
              </div>

              <div className='rlinebox1col d-flex'>
                <div className='rlinebox2col d-flex'>
                </div>
              </div>
              <p className='lineboxtextcol'>34k<br/><p className='lineboxtext2col'>Colleges</p></p>
            </div>         
            
            </div>
          

          <div className='im-container5 d-flex'>
          <span className='yellowbox2 '><p className='yt1col'>100k<br/>
              <p className='yt2 mt-0'>Students</p></p></span>
          </div>

          </div>
          </div>

          <div className='im-container4 d-flex'>
            <span className='bluec2'><img className='whiteacol' src={whitea} alt='white arrow' /></span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={6} md={6} className='firstsignup2'>
        <button className='signincol' onClick={handleClick}><p className='siginscol mt-2'>Sign up now!</p></button>
         </Col>

        <Col xs={6} sm={6} md={6}className='firstsignup2'>
        <Button variant="outline-primary" className='watchcol' ><p className='watchs2'>Know more</p></Button>
        </Col>

        <Col xs={12} sm={12} md={12} className='firstsignup12'>
        <p className='linetextcol'>Ready to propel your students' careers forward?<br/> PluginLive facilitates competitive campus recruitment events, campus ATS, and support on campus recruitment drives.</p>
        <p className='linetxtcol mt-5'>Join us in equipping your students for success!</p>
        </Col>

        </Row> */}

      <Section paragraph={<div dangerouslySetInnerHTML={{ __html: paragraphContent }} />} words={words} imageSrc={colleges} bgpartClassName='bgpart3' profileType='college'/>

      <div className="Fmaincontent">
      Ready to propel your students' careers forward? PluginLive facilitates competitive campus recruitment events, campus ATS, and support on campus recruitment drives.<br></br><br></br>Join us in equipping your students for success!
        </div>


        {/* <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br> */}
        
        
        
        <Row>
          <Col xs={12} sm={12} md={12} className='sechead2'>

          {/* <span className='title1tagcol d-flex justify-content-center align-items-center'><span className='ttextcol'>Explore</span></span><br></br> */}

          <h1 className='title2tagcol d-flex justify-content-center align-items-center'>Unlock Your Student's Potential</h1>
          <p className='title2tagcol2 d-flex justify-content-center align-items-center'>Campus talent acquisition solutions for enhancing your student's job search experience.</p><br/>
          </Col>
          
          <Unlockindex headers={headers} subheaders={subheaders}/>

        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} className='corevaluescon'>
                <div className='brandcol'>
                <p className='brandhcol' style={{color:"rgba(255, 255, 255, 1)"}}>Industry Connections</p>
                        <p className='brandppcol'>Access to a vast network of industry partners and alumni, providing valuable insights and opportunities for career advancement.
</p> 
                </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevaluescon'>
            <div className='hirecol'>
            <p className='hirehcol' style={{color:"rgba(255, 255, 255, 1)"}}>Opportunities for assessments</p>
                        <p className='hireppcol'>Unlock your students' potential with tailored assessment opportunities, guiding them towards career success.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevaluescon'>
            <div className='digitalcol'>
            <p className='digitalhcol' style={{color:"rgba(255, 255, 255, 1)"}}>Knowledge based workshops or competitive events for students</p>
                        <p className='digitalppcol'>Expand student horizons with workshops and competitive events, fostering skill development and innovation for career readiness.</p>
                    
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className='corevaluescon'>
            <div className='traincol'>
            <p className='trainhcol' style={{color:"rgba(255, 255, 255, 1)"}}>Cutting-edge Resources</p>
                        <p className='trainppcol'>Utilize state-of-the-art tools and resources, including mock interview platforms, resume builders, and skill assessment tests, to enhance your employability.</p>      
            </div>
          </Col>
        </Row>

       

        <div ref={logoCompRef}>
        <LogoComp />
        </div>


        {/* <Row className='phonebox3'>
        <Col xs={12} sm={12} md={7}>
        <div className='phonetitles3'>
        <span className='phonetitle1tag3 d-flex justify-content-center align-items-center'><span className='ttext'>Make Your Student's Career Dreams a Reality</span></span><br></br>  
        <h1 className='phoneheadtitle3'>Step into the future of career exploration!</h1>
          </div>  
        </Col><br></br>

        <Col xs={12} sm={12} md={6}>
        <p className='phonebody3lap'>Transform your student's career aspirations into concrete realities by partnering with us. Take a leap into the future of seamless campus placement processes and empower your students to unlock their full potential. </p>
        </Col>

        <Col xs={12} sm={12} md={5} className=' d-none d-lg-block'>
          <img className='arrow3' src={arrow} alt='arrow'/>
        </Col>


        <Col xs={12} sm={12} md={6}>
          <div className="image-phone-container3">
            
          <div ref={containerRef} className={`container ${isScrolling ? 'scrolling' : ''}`}>
            <div className="orgcircle3"></div>
            <div className="viocircle3"></div>
            <div className="bluecircle3"></div>
            <div className='yellowcircle3'></div>
          </div>
          
          
          <div style={{ position: 'relative' }}>

          <img className='phone3' src={tablet} alt='phone'>
            </img>

            <img src={Gif} alt='' className='gifcol'/>
            </div> 
          
          
          </div>
        </Col>

        <Col xs={12} sm={12} md={6}>
        <p className='phonebody3mob'>Transform your student's career aspirations into concrete realities by partnering with us. Take a leap into the future of seamless campus placement processes and empower your students to unlock their full potential. </p>
        </Col>
      </Row> */}

      {/* <Row className='colgciradful'>
        <Col xs={12} sm={12} md={6}>
        <div className='traincirclecol'></div>
        <div className='traincircletab'></div>
        <div className='trainingtitles'>

        
        <br></br>  
        <h1 className='trainheadtitlecol'>Training Partners</h1>
        <h1 className='trainheadtitletab'>Training Partners</h1>

          </div>
          
          <p class="head12col">Gear up for success with exclusive skill development programs.<br className='lapbreak'></br> Elevate your student's expertise and prepare them to conquer the professional world alongside us! </p>

          

          <p class="head12tabcol">Gear up for success with exclusive skill development programs.<br className='lapbreak'></br>Elevate your student's expertise and prepare them to conquer the professional world alongside us!  </p>

        </Col>

        <Col xs={12} sm={12} md={7} className='asescirful'>
        <div className='assesscirclecol'></div>
        <div className='assesstitles'>

     
        <h1 className='assesheadtitlecol'>Assessment Partners</h1>
          </div>
          
          <p class="assesshead1col">Ready to showcase your student's skills like pros?<br className='lapbreak'></br> Access top-notch evaluation tools and resources to help them shine bright and stand out.<br className='lapbreak'></br> Let's ace those assessments and open doors to endless opportunities together!</p>

        

          
                
        </Col>
      </Row> */}

      <br></br><br></br><br></br><br></br>

      <CircleComp trainContent={trainContent} assessContent={assessContent} />
      

      <Signupfooter profile="college"/>
      <br></br><br></br><br></br>


      
      
      

    </Container>
        </>
    );
}


function Collegechat(){
  return(
    <>
      <Row className='chatcol'>

{/* <div>
        <Col xs={12} sm={12} md={4}>

        <div className="testimonial-box">
      <div className="curved-side curved-side-1"></div>
      <div className="curved-side curved-side-2"></div>
      <div className="curved-side curved-side-3"></div>
      <div className="pointed-side"><p className="testimonial-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.
        </p></div>
    </div>
        </Col>

        <Col xs={12} sm={12} md={4}>

<div className="testimonial-box">
<div className="curved-side curved-side-1"></div>
<div className="curved-side curved-side-2"></div>
<div className="curved-side curved-side-3"></div>
<div className="pointed-side"><p className="testimonial-text">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.
</p></div>
</div>
</Col>

</div> */}

{/* <Row ref={rowRef} className="row-reveal">


  
        <Col md={6} sm={12}>
          <Card className="testimonial-card ">
          <div className="testimonial-box">
      <div className="curved-side curved-side-1"></div>
      <div className="curved-side curved-side-2"></div>
      <div className="curved-side curved-side-3"></div>
      <div className="pointed-side"><p className="testimonial-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.<br></br>
          
          <p style={{ }} className="namecol">Name,</p>
  <p style={{ display: 'inline', marginLeft: '80px' }} >Designation</p>
        </p>
        
        </div>
    </div>
          </Card>
        </Col>
        <Col md={6} sm={12}>
          <Card className="testimonial-card animated">
          <div className="testimonial-box">
      <div className="curved-side curved-side-1"></div>
      <div className="curved-side curved-side-2"></div>
      <div className="curved-side curved-side-3"></div>
      <div className="pointed-side"><p className="testimonial-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.
        </p></div>
    </div>
          </Card>
        </Col>
        <Col md={6} sm={12}>
          <Card className="testimonial-card animated">
          <div className="testimonial-box">
      <div className="curved-side curved-side-1"></div>
      <div className="curved-side curved-side-2"></div>
      <div className="curved-side curved-side-3"></div>
      <div className="pointed-side"><p className="testimonial-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.
        </p></div>
    </div>
          </Card>
        </Col>
        <Col md={6} sm={12}>
          <Card className="testimonial-card animated">
          <div className="testimonial-box">
      <div className="curved-side curved-side-1"></div>
      <div className="curved-side curved-side-2"></div>
      <div className="curved-side curved-side-3"></div>
      <div className="pointed-side"><p className="testimonial-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.
        </p></div>
    </div>
          </Card>
        </Col>
        <Col md={6} sm={12}>
          <Card className="testimonial-card animated">
          <div className="testimonial-box">
      <div className="curved-side curved-side-1"></div>
      <div className="curved-side curved-side-2"></div>
      <div className="curved-side curved-side-3"></div>
      <div className="pointed-side"><p className="testimonial-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget libero vel libero dignissim malesuada. In hac habitasse platea dictumst. Integer vitae felis lorem.
        </p></div>
    </div>
          </Card>
        </Col>
      </Row> */}

      <Col xs={12} sm={12} md={12}>
        <div className='chathead'>
        <span className='chatitle1tagcol d-flex justify-content-start'><span className='ctextcol d-flex justify-content-center'>Real Voices, Real Success</span></span><br></br>  
        <h1 className='chatheadtitlecol d-flex justify-content-start'>Here's what our clients have to say</h1><br></br><br></br>
        </div>
        </Col>

        <Row 
        // ref={rowRef} 
        className='testimonialscol row-reveal'>

        <Col 
        
        // xs={isInView ? 24 : 12} 
        //   sm={isInView ? 24 : 12}
        //   md={isInView ? 24 : 6}
        //   className={`chatbox1col1 ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

          xs={12} sm={12} md={6}
          className='chatbox1col1 '

        >
          <img className='chatimg1col' src={pp3} alt='ppl1'/>
          <span className='chatbox11col d-flex'>
            <p className='chatboxtext1col'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br></br><p className='namesmcol'>Name,<p className='desgsmcol'> Designation</p></p></p>
          </span>  
          <span className='chatbox111col d-flex justify-content-center align-items-center'></span>        
        </Col>

        <Col 
        // xs={isInView ? 24 : 12} 
        //   sm={isInView ? 24 : 12}
        //   md={isInView ? 24 : 6}
        //   className={`chatbox2col ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"


          xs={12} sm={12} md={6}
          className='chatbox2col '
        >
          <span className='chatbox21col d-flex'>
          <p className='chatboxtext21col'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br></br><p className='namesmcol'>Name,<p className='desgsmcol'> Designation</p></p></p>
          </span>
          <span className='chatbox121col d-flex justify-content-center align-items-center'></span>
        <img className='chatimg2col' src={pp1} alt='burger'/>
        </Col>

        <Col 
        
        
        // xs={isInView ? 24 : 12} 
        //   sm={isInView ? 24 : 12}
        //   md={isInView ? 24 : 6}
        //   className={`chatbox3col d-none d-lg-block ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

          xs={12} sm={12} md={6}
          className='chatbox3col'
          
        >
        <span className='chatbox31col d-flex'>
          <p className='chatboxtext31col'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesmcol'>Name,<p className='desgsmcol'> Designation</p></p></p>
          </span>
          <span className='chatbox131col d-flex justify-content-center align-items-center'></span>
          <img className='chatimg3col' src={pp2} alt='burger'/>
        </Col>

        <Col 
        // xs={isInView ? 24 : 12} 
        //   sm={isInView ? 24 : 12}
        //   md={isInView ? 24 : 6}
        //   className={`chatbox4col ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

          xs={12} sm={12} md={6}
          className='chatbox4col'
        >
        <img className='chatimg4col' src={pp2} alt='burger'/>
        <span className='chatbox41col d-flex'>
            <p className='chatboxtext41col'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesmcol'>Name,<p className='desgsmcol'> Designation</p></p></p>
        </span>
        <span className='chatbox141col d-flex justify-content-center align-items-center'></span>
        </Col>

        <Col xs={12} sm={12} md={6} className='chatboxnil'> {/* don't delete this */}
          {/* <h1>Hellooo</h1> */}
        </Col>

        <Col 
        
        // xs={isInView ? 24 : 12} 
        //   sm={isInView ? 24 : 12}
        //   md={isInView ? 24 : 6}
        //   className={`chatbox5col ${isInView ? 'animate-expand' : ''}`} data-aos="zoom-in"

          xs={12} sm={12} md={6}
          className='chatbox5col'
        >
        <img className='chatimg5col' src={pp3} alt='burger'/>
        <span className='chatbox51col d-flex'>
        <p className='chatboxtext51col'>Lorem ipsum dolor sit amet, consectetur adipiscing 
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna<br></br><p className='namesmcol'>Name,<p className='desgsmcol'> Designation</p></p></p>
        </span>
        <span className='chatbox151col d-flex justify-content-center align-items-center'></span>
        </Col>

        </Row>


      </Row>

    </>
  );
}