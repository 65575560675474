import React, { useState, useEffect } from 'react';
import './Cstyle.css';

const CircleComp = ({ trainContent, assessContent, experiencedContent, page }) => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderContent = (content) => {
    if (isWideScreen) {
      return content.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    }
    return content;
  };

  return (
    <div className='Ciclecompf'>
      <div className='Trainbox'>
        <span className='trainc'></span>
        <div className='Trainboxcontent'>
          <p className='trainboxheader'>Training Partner</p>
          <p className='trainboxsubheader'>{renderContent(trainContent)}</p>
        </div>
      </div>
      <div className='Assessbox'>
        <span className='assessc'></span>
        <div className='Assessboxcontent'>
          <p className='assessboxheader'>Assessment Partner</p>
          {page === 'Experienced' ? (
            <>
              <div className='asesboxexp'>
                <p className='asesboxexp1'>{renderContent(experiencedContent.split('\n')[0])}</p>
                <p className='asesboxexp2'>{renderContent(experiencedContent.split('\n')[1])}</p>
                <p className='asesboxexp3'>{renderContent(experiencedContent.split('\n')[2])}</p>
              </div>
            <p className='Assessmentpartnercontent'>{renderContent(assessContent)}</p>
            </>
            
          ) : (
            <p className='assessboxsubheader'>{renderContent(assessContent)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CircleComp;
