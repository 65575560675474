import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stylefr.css";
import fresher from "./images/fresher.png";
// import play from './images/playbutton.png';
import brand1 from "./images/brand.png";
import access1 from "./images/access.png";
import automation1 from "./images/automation.png";
import hire1 from "./images/hire.png";

// import phone from './images/phone.png';
// import arrow from './images/arrow.png';
import pp1 from "./images/ppl1.png";
import pp2 from "./images/ppl2.png";
import pp3 from "./images/ppl4.png";
import whitea from "./images/white circle arrow.png";

// import Gif from '../../Assets/images/loadinggif.gif';
import { useNavigate } from "react-router-dom";

import Hover1 from "../../Assets/images/hover1.png";
import Hover2 from "../../Assets/images/hover2.png";
import Hover3 from "../../Assets/images/hover3.png";

import Clickcore from "../../Assets/images/clickincore.png";

import { useTypewriter, Cursor } from "react-simple-typewriter";

import Aos from "aos";
import "aos/dist/aos.css";
// import { duration } from 'moment';

import Testimonials from "../testimonials/testimonials";
import SignupFooter from "../SignupFooter/signupfooter";
import CircleComp from "../CircleComp/circlecomp";

import LogoComponent from "../LogoComponent/logocomp";
import Section from '../Mainsection/index';

import Unlockindex from "../UnlockComponent/unlockindex";


// import GifComp from "../Mobiletabgifcontent/Gifcomp";

// const content = {
//   card1: ['Make your dream career a reality', 'Step into the future of career exploration!', 'Turn your aspirations into achievements with our support. Embrace the future of career exploration with our fresher hiring platform as we help you land your ideal job by connecting you with top-tier training and assessment partners.'],

//   card2: {
//     image: phone,
//     anotherImage:Gif,
//     circles: [1, 2, 3, 4]
//   },

//   card3: ['Turn your aspirations into achievements with our support. Embrace the future of career exploration with our fresher hiring platform as we help you land your ideal job by connecting you with top-tier training and assessment partners.'],
// };

const trainContent = `Gear up for success with exclusive skill development programs.
Elevate your expertise and get ready to conquer the professional world with us by your side!`;

const assessContent = `Ready to showcase your skills like a pro?
Access top-notch evaluation tools to shine bright and stand out from the crowd.
Let's ace those assessments and open doors to endless opportunities together!`;

export default function Fresher() {
  // chat animation start

  // const rowRef = useRef(null);

  // useEffect(() => {
  //   const revealRow = () => {
  //     const row = rowRef.current;
  //     if (!row) return;

  //     const windowHeight = window.innerHeight;
  //     const rowTop = row.getBoundingClientRect().top;
  //     const revealPoint = 150;

  //     if (rowTop < windowHeight - revealPoint) {
  //       row.classList.add('active');
  //     } else {
  //       row.classList.remove('active');
  //     }
  //   };

  //   window.addEventListener('scroll', revealRow);

  //   return () => {
  //     window.removeEventListener('scroll', revealRow);
  //   };
  // }, []);

  // chat animation end

  /* box click start */
  const [activeFeature, setActiveFeature] = useState(null);
  const containerRefs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (activeFeature !== null) {
        const activeContainerRef = containerRefs[activeFeature];
        if (
          activeContainerRef.current &&
          !activeContainerRef.current.contains(event.target)
        ) {
          setActiveFeature(null);
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [activeFeature, containerRefs]);

  const toggleDiv = (index) => {
    setActiveFeature((prevActiveFeature) =>
      index === prevActiveFeature ? null : index
    );
  };

  /* box click end */

  const [isDancing, setIsDancing] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 200) {
        setIsDancing(true);
      } else {
        setIsDancing(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // const handleAnimationEnd = () => {
  //   document.querySelector('.dancing-divv').classList.add('shake');
  // };

  // const [isScrolling, setIsScrolling] = useState(false);
  // const containerRef = useRef(null);

  // useEffect(() => {
  //     const handleScroll = () => {
  //         const container = containerRef.current;
  //         const rect = container.getBoundingClientRect();
  //         if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
  //             setIsScrolling(true);
  //         } else {
  //             setIsScrolling(false);
  //         }
  //     };

  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  const [text] = useTypewriter({
    words: ["region", "resources"],
    loop: {},
    typeSpeed: 150,
    deleteSpeed: 150,
    delaySpeed: 800,
  });

  const handleClicksignup = () => {
    window.location.href = "/Student_Registration";
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    window.location.href = "/joblisting";
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const navigate = useNavigate();

  const [isInView, setIsInView] = useState(false);

  const paragraphContent = `Start your path. 
  Discover your potential 
  more than by`;

  const words = ["region", "resources"];

  const headers = ['Tailored Placement Process', 'Instant Notification System', 'Skill-Matched Job Recommendations', 'Seamless Career Navigation'];

  const subheaders=['Enjoy a stress-free fresher hiring journey with opportunities personalized to your skills and aspirations, ensuring a smoother transition into your dream career.','Stay informed and ahead with reliable notifications, apply on-the-go, and manage interview schedules effortlessly.','Bid farewell to generic searches! Our advanced algorithms match your unique skills with perfect job opportunities, providing exclusive skill-fit recommendations.','Navigate your path with ease using our intuitive tools. Track progress, receive guidance, and make informed decisions for your dream job.'];

  return (
    <>
      <Container className="fresherspagemain">
        {/* <Row>
          <Col xs={12} sm={12} md={6}>
            <span className="fresher1tag justify-content-start align-items-start">
              <span className="ftext">Fresher</span>
            </span>
            <br></br>
            <div className="contentparafre">
              <p>
                Start your path.<br></br>
                Discover your potential<br></br>
                more than by<br></br>
                <span
                  className="luck"
                  style={{
                    fontWeight: "bold",
                    background:
                      "linear-gradient(270.17deg, #073CA5 -18.76%, #63B5FD 111.97%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontWeight: 900,
                  }}
                >
                  {text}
                </span>
                <span
                  style={{
                    color: "#073CA5",
                  }}
                >
                  <Cursor CursorStyle="|" />
                </span>
              </p>
            </div>
          </Col>
          <br></br>

          <Col xs={12} sm={12} md={6} className="freimgpart">
            <div className="image-container d-flex">
              <span className="orgvector d-flex justify-content-center align-items-center">
                {" "}
              </span>
              <img src={fresher} alt="fresher" className="img-fluid1 d-flex" />
            </div>

            <div className={`dancing-divv ${isVisible ? "visible" : ""}`}>
              <div className={`dancing-div ${isDancing ? "dancing" : ""}`}>
                <div className="image-container2">
                  <span className="violetbox d-flex ">
                    <p className="vt1fre">
                      100+
                      <br />
                    </p>
                    <p className="vt2fre">Corporates</p>
                  </span>
                </div>

                <div className="image-container3">
                  <div className="lineboxfre d-flex">
                    <div className="hlinebox1fre d-flex">
                      <div className="hlinebox2fre d-flex"></div>
                    </div>

                    <div className="rlinebox1fre d-flex">
                      <div className="rlinebox2fre d-flex"></div>
                    </div>

                    <p className="lineboxtextfre">
                      34k
                      <br />
                      <p className="lineboxtext2fre">Colleges</p>
                    </p>
                  </div>
                </div>

                <div className="image-container5 d-flex">
                  <span className="yellowbox ">
                    <p className="yt1fre">
                      100k
                      <br />
                      <p className="yt2 mt-0">Students</p>
                    </p>
                  </span>
                </div>

              </div>
            </div>

            <div className="image-container4 d-flex">
              <span className="bluec">
                <img className="whitearrowfre" src={whitea} alt="white arrow" />
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={6} sm={6} md={6} className="firstsignup">
            <button className="signinss" onClick={handleClicksignup}>
              <p className="siginslet">Sign up now!</p>
            </button>
          </Col>

          <Col xs={6} sm={6} md={6} className="firstsignup">
            <button className="watchsss" onClick={handleClick}>
              <p className="watchs">Job Listing</p>
            </button>
          </Col>

          <Col xs={12} sm={12} md={12} className="firstsignup1">
            <p className="linetextttfre">
              Ready to start your journey with PluginLive?<br></br>Say goodbye
              to job search stress and hello to personalized opportunities. Get
              instant updates, apply on-the-go, and find your dream job
              hassle-free.
            </p>
            <p className="linetextttfre2">
              Let's dive in and make your career dreams a reality!
            </p>
          </Col>
        </Row> */}

        <Section paragraph={paragraphContent} words={words} imageSrc={fresher} bgpartClassName='bgpart1' profileType='fresher'/>

        <div className="Fmaincontent">
        Ready to start your journey with PluginLive? Say goodbye to job search stress and hello to personalized opportunities. Get instant updates, apply on-the-go, and find your dream job hassle-free. <br></br><br></br>Let's dive in and make your career dreams a reality!
        </div>  


        

        <Row>
          <Col xs={12} sm={12} md={12} className="sechead">
            {/* <span className='title1tagfre d-flex justify-content-center align-items-center'><span className='ttext'>Explore</span></span> */}
            <h1 className="title2tagfre d-flex justify-content-center align-items-center">
              Unlock Your Potential
            </h1>
            <h1 className="title2tagfre2 d-flex justify-content-center align-items-center">
              PluginLive campus recruitment solutions for your enhanced job
              search experience
            </h1>
            <br></br>
          </Col>

          <Unlockindex headers={headers} subheaders={subheaders}/>
          
          
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} className="strengthvalues4m">
            <div className="brandfre">
              <p
                className="brandhfre"
                style={{ color: "rgba(255, 255, 255, 1)" }}
              >
                Tailored Placement Process
              </p>
              <p className="brandppfre">
                Enjoy a stress-free fresher hiring journey with opportunities
                personalized to your skills and aspirations, ensuring a smoother
                transition into your dream career.
              </p>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} className="strengthvalues4m">
            <div className="hirefre">
              <p
                className="hirehfre"
                style={{ color: "rgba(255, 255, 255, 1)" }}
              >
                Seamless Career Navigation
              </p>
              <p className=" hireppfre">
                Navigate your path with ease using our intuitive tools. Track
                progress, receive guidance, and make informed decisions for your
                dream job.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className="strengthvalues4m">
            <div className="digitalfre">
              <p
                className="digitalhfre"
                style={{ color: "rgba(255, 255, 255, 1)" }}
              >
                Skill-Matched Job Recommendations
              </p>
              <p className="digitalppfre">
                Bid farewell to generic searches! Our advanced algorithms match
                your unique skills with perfect job opportunities, providing
                exclusive skill-fit recommendations.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} className="strengthvalues4m">
            <div className="trainfre">
              <p
                className="trainhfre"
                style={{ color: "rgba(255, 255, 255, 1)" }}
              >
                Instant Notification System
              </p>
              <p className="trainppfre">
                Stay informed and ahead with reliable notifications, apply
                on-the-go, and manage interview schedules effortlessly.
              </p>
            </div>
          </Col>
        </Row>

        <br></br>

        <LogoComponent />

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        {/* <GifComp content={content} /> */}

        {/* <Row className='phonebox'>
        <Col xs={12} sm={12} md={7}>
        <div className='phonetitles'>

          <span className='phonetitle1tagfre d-flex justify-content-center align-items-center'><span className='ttext'>Make your dream career a reality</span>
          </span>
          
          <br></br>  
          <h1 className='phoneheadtitlefre d-flex'>Step into the future of career exploration!</h1>
        </div>  
        </Col><br></br>

        <Col xs={12} sm={12} md={6} className='phonebodylapscreen'>
        <p className='phonebodyfre'>Turn your aspirations into achievements with our support. Embrace the future of career exploration with our fresher hiring platform as we help you land your ideal job by connecting you with top-tier training and assessment partners. </p>
        </Col>

        <Col xs={12} sm={12} md={5} className='phonebodylapscreen'>
          <img className='arrowfre' src={arrow} alt='arrow'/>
        </Col>


        <Col xs={12} sm={12} md={6}>
          <div className="image-phone-container">
<div ref={containerRef} className={`container ${isScrolling ? 'scrolling' : ''}`}>

            <div className="org1circle"></div>
            <div className="vio1circle"></div>
            <div className="blue1circle"></div>


        </div>
            
            <div style={{ position: 'relative' }}>

          <img className='phones' src={phone} alt='phone'>
            </img>

            <img src={Gif} alt='' className='giffre'/>
            </div>

          </div>
          
        </Col>

        <Col xs={12} sm={12} md={6} className='phonebodymobscreen'>
        <p className='phonebodymob'>Turn your aspirations into achievements with our support. Embrace the future of career exploration with our fresher hiring platform as we help you land your ideal job by connecting you with top-tier training and assessment partners. </p>
        </Col>
      </Row> */}

        {/* <Row>
        <Col xs={12} sm={12} md={6}>
        <div className='traincirclefr'></div>
        <div className='traincirclepad'></div>
        <div className='trainingtitles'>

        <h1 className='trainheadtitletabfre'>Training Partners</h1>
          </div>
          
          <p class="head12fre">Gear up for success with exclusive skill development programs.<br className='lapbreak'></br>Elevate your expertise and get ready to conquer the professional world with us by your side!</p>
          <p class="head12tabfre">Gear up for success with exclusive skill development programs.Elevate your expertise and get ready to conquer the professional world with us by your side!</p>


        </Col>

        <Col xs={12} sm={12} md={7} className=''>
        <div className='assesscirclefre'></div>
        <div className='assesstitles'>
        <h1 className='assesheadtitlefre'>Assessment Partners</h1>
        <h1 className='assesheadtitfretab'>Assessment Partners</h1>
          </div>
          
          <p class="assesshead1fre">Ready to showcase your skills like a pro?<br></br>Access top-notch evaluation tools to shine bright and stand out from the crowd. <br className='lapbreak'></br>Let's ace those assessments and open doors to endless opportunities together!</p>

          <p class="assesshead1tabfre">Ready to showcase your skills like a pro? Access top-notch evaluation tools to shine bright and stand out from the crowd. Let's ace those assessments and open doors to endless opportunities together!</p>

         
       
        </Col>
      </Row> */}

        <CircleComp trainContent={trainContent} assessContent={assessContent} />

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Testimonials />

        {/* <Row>
        <Col xs={12} sm={12} md={10} className='startedfre'>
          <div className='startedboxfre d-flex'>
          <h1 className='startheadfre'>Ready to join the success stories? </h1><br></br></div>
          
          <button className='subbuttonfre d-flex' onClick={()=>navigate("/Student_Registration")}><p className='regisfre'>Sign up now</p></button>
            <p className='startparalapscreen '>Start your journey with us today and unlock endless opportunities for your career growth.</p>
        </Col>

        <Col xs={12} sm={12} md={10} className='startedtab'>
          <div className='startedboxtab d-flex'>
          <h1 className='startheadtab'>Ready to join the success stories? </h1><br></br></div>
          <p className='startparamobtab'>Start your journey with us today and unlock endless opportunities for your career growth.</p>
          
          <button className='subbuttontab' onClick={()=>navigate("/Student_Registration")}><p className='registab'>Sign up now</p></button>
        </Col>
        
      </Row> */}

        <SignupFooter profile="fresher" />

        <br></br>
        <br></br>
        <br></br>
      </Container>
    </>
  );
}


