import React from 'react';
import { Form, Input } from 'antd';
import './styleorganization.css'

const Organization = ({ value, onChange }) => {
  const handleOrganizationChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  return (
      <Form.Item
        name={["organizationName"]}
        rules={[
          {
            required: true,
            message: 'Please enter the organization name',
          },
        ]}
      >
        <Input
          value={value}
          placeholder="Enter organization name"
          className="orgsize"
          onChange={handleOrganizationChange}
        />
      </Form.Item>
  );
};

export default Organization;
