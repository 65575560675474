import React from "react";
import {
 StyledDropdown,
 StyledText,
} from "./style";
import { Button, Menu, message, Space } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import DropDown from "./DropDown";

const PaddedDropdown = (props) => {
 return (
  <StyledDropdown overlay={props.menu} trigger={['click']}>
   <Button onClick={(e) => e.preventDefault()}>
    <Space>
     <StyledText> {props.text}</StyledText>
     <DropDown />
    </Space>
   </Button>
  </StyledDropdown>
 );
};

export default PaddedDropdown;
